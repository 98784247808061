import React from 'react';
import {Props} from './Props';
import {LevelDash} from '../LevelDash';

export const OtmConstraintGeneric: React.FunctionComponent<Props> = ({constraint, level}) => {

    return <>
        <p style={{fontWeight: 'bold'}}>
            <LevelDash level={level}/>

            Generic Constraint:
        </p>

        <p>Omschrijving: {constraint.description}</p>

        <br/>
    </>;
};
