import {combineEpics, Epic} from 'redux-observable';
import userSettingsPanelEpics from './userSettingsPanelEpics';
import trafficInformationPaneEpics from './trafficInformationPaneEpics';
import {filter, map, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {storeLegendData, updateSidebarStatus} from '../../scenes/MapScene/actions';
import {loadLayerConfiguration, loadLegendData} from '../../services/ViewerApiService';
import {viewerApplicationSceneChanged} from '../../actions';
import {MAP_SCENE} from '../../scenes';
import summedTrajectoriesPane from './summedTrajectoriesPane';
import {activatedTrafficMessagesPane, deactivatedTrafficMessagesPane} from '../../scenes/MapScene/actions/trafficMessagesPane';
import {viewerApiServiceReceivedLegendDate} from '../../services/ViewerApiService/actions/legendData';
import searchPaneEpics from './searchPaneEpics';
import notificationPaneEpics from './notificationPaneEpics';

const activatedTrafficMessagesPaneOnUpdateSidebarStatus: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(updateSidebarStatus)),
        filter((action) => action.payload.newVisibleTab === 'TRAFFIC_INFORMATION'),
        map(() => activatedTrafficMessagesPane())
    );

const deactivatedTrafficMessagesPaneOnUpdateSidebarStatus: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(updateSidebarStatus)),
        filter((action) => action.payload.newVisibleTab !== 'TRAFFIC_INFORMATION'),
        map(() => deactivatedTrafficMessagesPane())
    );

const loadLayerConfigurationOnSceneChanged: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApplicationSceneChanged)),
        filter((action) => action.payload.scene === MAP_SCENE),
        switchMap(() => loadLayerConfiguration())
    );

const loadLegendDataOnSceneChanged: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApplicationSceneChanged)),
        filter((action) => action.payload.scene === MAP_SCENE),
        switchMap(() => loadLegendData())
    );

const setNewLegendDataOnReceivedLegendData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedLegendDate)),
        map(({payload: {data}}) => storeLegendData(data))
    );

const mapSceneEpics: Epic = combineEpics(
    activatedTrafficMessagesPaneOnUpdateSidebarStatus,
    deactivatedTrafficMessagesPaneOnUpdateSidebarStatus,
    loadLayerConfigurationOnSceneChanged,
    loadLegendDataOnSceneChanged,
    setNewLegendDataOnReceivedLegendData,

    notificationPaneEpics,
    searchPaneEpics,
    summedTrajectoriesPane,
    trafficInformationPaneEpics,
    userSettingsPanelEpics
);

export default mapSceneEpics;
