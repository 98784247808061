import {ActionType, createAction} from 'typesafe-actions';

export const changeCurrentRoute = createAction(
    'CHANGE_CURRENT_ROUTE',
    (action) => (route: string) => action({route})
);

export const layoutComponentRequestedLogoutCurrentUser = createAction(
    'LAYOUT_COMPONENT_REQUESTED_LOGOUT_CURRENT_USER',
    (action) => () => action({})
);

export type LayoutActionTypes = ActionType<typeof changeCurrentRoute> |
    ActionType<typeof layoutComponentRequestedLogoutCurrentUser>;
