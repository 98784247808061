import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    categoryTitle: {
        fontWeight: 'bold',
        marginBottom: '6px'
    },
    paneDescription: {
        marginBottom: '1rem'
    }
});

export default styles;
