import {formatDateTime, formatTime} from '../../../../utils/DateUtil';
import {ICellRendererParams} from '@ag-grid-community/core';

export const DateCellRenderer = (data: ICellRendererParams): string => {
    if (!data.value || !data.value.length) {
        return '-';
    }

    return formatDateTime(new Date(data.value), false);
};

export const TimeCellRenderer = (data: ICellRendererParams): string => {
    if (!data.value || !data.value.length) {
        return '-';
    }

    return formatTime(new Date(data.value),false);
};
