import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    navigationBar: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        height: '100%',
        listStyle: 'none',
        paddingLeft: '1.3rem'
    },
    navigationBarRight: {
        justifyContent: 'flex-end',
        marginLeft: 'auto'
    }
});

export default styles;
