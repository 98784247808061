import {NotificationDTO} from '../../generated/ViewerApiClient';
import {ActionTypes, notificationModuleSaveNewNotification} from './actions';
import {getType} from 'typesafe-actions';
import {Reducer} from 'redux';

export interface NotificationReducerState {
    currentNotification: NotificationDTO | null;
}

const initialState: NotificationReducerState = {
    currentNotification: null
};

const notificationModuleReducer: Reducer<NotificationReducerState, ActionTypes> = (state: NotificationReducerState = initialState, action: ActionTypes) => {
    switch (action.type) {
        case getType(notificationModuleSaveNewNotification):
            return {
                ...state,
                currentNotification: action.payload.notification
            };
        default:
            return state;
    }
};

export default notificationModuleReducer;
