import {createAction} from 'typesafe-actions';
import {DIALOG_GRAPHS} from '../index';

export const closeGraphDialog = createAction(
    'CLOSE_GRAPH_DIALOG',
    (action) => (mapSourceID: string, featureID: number) => action({mapSourceID, featureID})
);

export const openGraphDialog = createAction(
    'OPEN_GRAPH_DIALOG',
    (action) => (mapSourceID: string, featureID: number | string, locationID: string, dialog: DIALOG_GRAPHS, timestamp: number) => action({
        dialog,
        featureID,
        locationID,
        mapSourceID,
        timestamp
    })
);

export const mapSceneOpenHeatmapTravelTimeFcd = createAction(
    'MAP_SCENE_OPEN_HEAT_MAP_TRAVEL_TIME_FCD',
    (action) => (featureId: string, featureKey: number) => action({featureId, featureKey})
);
