import React, {ReactElement} from 'react';
import {Props} from './Props';
import MapboxGL from '../../../../components/MapboxGl';
import {MapView} from '../../../../interfaces/Mapbox';
import {MapFeature} from '../../../../interfaces/MapFeature';

class Mapbox extends React.Component<Props> {

    onMapPositionUpdated = (viewport: MapView): void => {
        const props: Readonly<Props> = this.props;
        props.handleViewportChange(viewport);
    };

    onUserMapClick = (foundFeatures: MapFeature[], pointX: number, pointY: number): void => {
        const props: Readonly<Props> = this.props;
        props.onUserMapClick(foundFeatures, pointX, pointY);
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {
            mapFlyToLocation,
            mapboxStyleUrl,
            mapLayers,
            mapSettings,
            dataSources,
            mapFilter,
            viewport,
            searchResults,
            showTrafficJamIcons,
            highlightFeatures
        } = props;

        return <React.Fragment>
            <MapboxGL
                mapFlyToLocation={mapFlyToLocation}
                styleUrl={mapboxStyleUrl}
                mapLayers={mapLayers}
                mapSettings={mapSettings}
                dataSources={dataSources}
                showTrafficJamIcons={showTrafficJamIcons}
                mapFilter={mapFilter}
                searchResults={searchResults}
                highlightFeatures={highlightFeatures}
                viewport={viewport}
                mapHasFinishedLoading={props.mapHasFinishedLoading}
                updateMapPosition={this.onMapPositionUpdated}
                onUserMapClick={this.onUserMapClick}
                onUserHoverOverFeature={props.onUserHoverOverFeature}
                onUserExistsHoverOverFeature={props.onUserExistsHoverOverFeature}
                onUserMapRightClick={props.onUserMapRightClick}
                numberOfFeaturesVisibleOnMap={props.numberOfFeaturesVisibleOnMap}
            />
        </React.Fragment>;
    }
}

export default Mapbox;
