import React from 'react';
import {Props} from './Props';
import {LevelDash} from '../LevelDash';

export const OtmConstraintValueBoundConstraint: React.FunctionComponent<Props> = ({constraint, level}) => {

    return <>
        <p style={{fontWeight: 'bold'}}>
            <LevelDash level={level}/>

            Value Bound Constraint:
        </p>

        <p>Constraint Type: {constraint.constraintType}</p>
        <p>Value type: {constraint.valueType}</p>
        <p>Description: {constraint.description}</p>
        <p>Maximum - value: {constraint.maximum.value}</p>
        <p>Maximum - unit: {constraint.maximum.unit}</p>

        <br/>
    </>;
};
