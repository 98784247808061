import {ActionType, createAction} from 'typesafe-actions';
import {TrafficCenterDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedTrafficCentersData = createAction(
    'VIEWER_API_SERVICE_RECEIVED_TRAFFIC_CENTERS_DATA',
    (action) => (data: TrafficCenterDTO[]) => action({data})
);

export const viewerApiServiceLoadingTrafficCentersDataUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_LOADING_TRAFFIC_CENTERS_DATA_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceLoadingTrafficCentersDataUnexpectedError = createAction(
    'VIEWER_API_SERVICE_LOADING_TRAFFIC_CENTERS_DATA_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type ViewerApiServiceTrafficCenterDataActionTypes = ActionType<typeof viewerApiServiceReceivedTrafficCentersData> |
    ActionType<typeof viewerApiServiceLoadingTrafficCentersDataUnauthorizedError> |
    ActionType<typeof viewerApiServiceLoadingTrafficCentersDataUnexpectedError>;
