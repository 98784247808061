import {StylesConfig} from 'react-select';
import {ThemeContentValue} from '../../../../../../contexts/ThemeContext';

export const selectStyles = (themeContentValue: ThemeContentValue): StylesConfig => ({
    menuList: (baseStyles) => ({...baseStyles, 'padding': 0}),
    option: (baseStyles) => ({
        ...baseStyles,
        'backgroundColor': themeContentValue.currentColorSchemeIsDark ? '#444' : baseStyles.backgroundColor,
        ':hover': {
            backgroundColor: themeContentValue.currentColorSchemeIsDark ? '#555': baseStyles[':hover']?.backgroundColor
        }
    })
});
