import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham-dark.css';
import '@szhsin/react-menu/dist/index.css';
import 'react-slidedown/lib/slidedown.css';
import {BASE, MAP, NOT_SUPPORTED} from './routes';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {Redirect, Route, Switch, useLocation} from 'react-router';
import MapScene from './scenes/MapScene';
import NotSupportedScene from './scenes/NotSupportedScene';
import Layout from './components/Layout';
import mapboxGL from 'mapbox-gl';
import NotFoundScene from './scenes/NotFoundScene';
import 'chart.js';
import {ProtectedRoute, ProtectedRoutes} from '@ndw/react-keycloak-authentication';
import {LicenseManager} from '@ag-grid-enterprise/core';
import {browserHistory, store} from './store';
import 'chartkick/chart.js';
import Chartkick from 'chartkick';
import PiwikPro from '@piwikpro/react-piwik-pro';
import {ANALYTICS_ENABLED, ENVIRONMENT, IS_DEV, SENTRY_DSN, VERSION} from './applicationContext';
import {chartColors} from './styles';
import * as Sentry from '@sentry/react';
import {useEffect} from 'react';
import {createRoutesFromChildren, matchRoutes, useNavigationType} from 'react-router-dom';

Chartkick.options = {
    colors: chartColors,
    empty: 'Geen data'
};


// Set the Mapbox access token and ag-grid license key
const {REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_AG_GRID_LICENSE} = process.env;
mapboxGL.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN as string;
LicenseManager.setLicenseKey(REACT_APP_AG_GRID_LICENSE as string);

if (ANALYTICS_ENABLED) {
    PiwikPro.initialize('3c7b1ac9-3ea8-4d74-8117-6d8d8c158fa5', 'https://statistiek.rijksoverheid.nl');
}

if (!IS_DEV) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        release: VERSION,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration()
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^https:\/\/ndwviewer(\.staging)?\.ndw\.nu/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
}

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={browserHistory}>
            <Layout>
                <Switch>
                    <Redirect exact to={MAP} from={BASE}/>
                    <ProtectedRoutes>
                        <ProtectedRoute exact path={MAP} component={MapScene}/>
                    </ProtectedRoutes>
                    <Route exact path={NOT_SUPPORTED} component={NotSupportedScene}/>
                    <Route path={'*'} component={NotFoundScene}/>
                </Switch>
            </Layout>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
