import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    button: {
        ':active': {
            backgroundColor: 'rgb(255, 255, 255)',
            borderColor: 'rgb(251, 216, 80)',
            color: 'rgb(235, 143, 0)',
            cursor: 'pointer'
        },
        ':hover': {
            backgroundColor: 'rgb(253, 245, 206)',
            borderColor: 'rgb(251, 203, 9)',
            color: 'rgb(199, 116, 5)',
            cursor: 'pointer'
        },
        'backgroundColor': 'rgb(246, 246, 246)',
        'borderColor': 'rgb(204, 204, 204)',
        'borderRadius': 4,
        'borderStyle': 'solid',
        'borderWidth': 1,
        'color': 'rgb(115, 115, 115)',
        'fontSize': 14,
        'fontWeight': 600,
        'marginBottom': 5,
        'marginTop': 5,
        'paddingBottom': 6,
        'paddingLeft': 15,
        'paddingRight': 15,
        'paddingTop': 6,
        'transition': 'all 0.1s'
    },
    selected: {
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(251, 216, 80)',
        color: 'rgb(235, 143, 0)',
        outline: '0'
    }
});

export default styles;
