import React, {useMemo} from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {Props} from './Props';

const StyledButton: React.FunctionComponent<Props> = ({disabled, title, selected, onPress, ...props}) => {

    const cssStyle = useMemo(() => selected ? css(styles.button, styles.selected) : css(styles.button), [selected]);

    return <button
        type="button"
        onClick={onPress}
        className={cssStyle}
        disabled={disabled}
        {...props}
    >
        {title}
    </button>;
};

export default StyledButton;
