import {ActionType, createAction} from 'typesafe-actions';
import {THEME_NUMBER} from '../../themes';
import {AccessDTO, TrafficCenterDTO} from '../../generated/ViewerApiClient';

export const changeCurrentTrafficCenter = createAction(
    'CHANGE_CURRENT_TRAFFIC_CENTER',
    (action) => (trafficCenter: TrafficCenterDTO | null) => action({trafficCenter})
);

export const changeCurrentTheme = createAction(
    'CHANGE_CURRENT_THEME',
    (action) => (newThemeNumber: THEME_NUMBER) => action({newThemeNumber})
);

export const storeAccessData = createAction(
    'STORE_ACCESS_DATA',
    (action) => (data: AccessDTO) => action({data})
);

export const userSettingsReducerShowRecordIds = createAction(
    'USER_SETTINGS_REDUCER_SHOW_RECORD_IDS',
    (action) => (value: boolean) => action({value})
);

export const userSettingsReducerShowTrafficJamIcons = createAction(
    'USER_SETTINGS_REDUCER_SHOW_TRAFFIC_JAM_ICONS',
    (action) => (value: boolean) => action({value})
);

export type ActionTypes = ActionType<typeof changeCurrentTheme>
    | ActionType<typeof changeCurrentTrafficCenter>
    | ActionType<typeof storeAccessData>
    | ActionType<typeof userSettingsReducerShowRecordIds>
    | ActionType<typeof userSettingsReducerShowTrafficJamIcons>;
