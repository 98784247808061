import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {overviewAllTables} from '../../../constants';
import {LegendDTO} from '../../../generated/ViewerApiClient';
import {MapView} from '../../../interfaces/Mapbox';
import {MapFlyTo} from '../../../interfaces/MapFlyTo';
import {OverviewAllTable, OverviewTableSource} from '../../../interfaces/OverviewAllTable';
import UrlService from '../../../services/UrlService';
import {getNativeWindow} from '../../../services/WindowService';
import {NAV_BAR_HEIGHT} from '../../../styles';
import {
    flyToLocation,
    mapSceneGeneralToggleMapMovementAvailable,
    mapSceneGeneralUpdateHighlightContextMenu,
    mapSceneGeneralUpdateHighlightFeatures,
    MapSceneMapActionTypes,
    storeLegendData,
    storeMapSceneContainerViewPartHeight,
    updateSidebarStatus
} from '../actions';
import {updateMapIsReadyStatus, updateMapViewport} from '../actions/mapBoxComponent';
import {
    mapSceneOverviewTableChangeOverviewTableSource,
    setNewVisibleOverviewAllTable,
    updateOverviewTableHeight
} from '../actions/overviewTableComponent';
import {mapSceneSettingsPanelSetNewTravelTimeLineOffset} from '../actions/settingsPane';
import {MapFeature} from '../../../interfaces/MapFeature';

export enum SidebarContentPanes {
    LAYERS = 'LAYERS',
    CONFIGURATION = 'CONFIGURATION',
    FILTERS = 'FILTERS',
    TRAFFIC_INFORMATION = 'TRAFFIC_INFORMATION',
    TRAJECTORY_SUMMED = 'TRAJECTORY_SUMMED',
    LEGEND = 'LEGEND',
    OVERVIEW_ALL = 'OVERVIEW_ALL',
    NOTIFICATION = 'NOTIFICATION'
}

export interface MapSettings {
    identifier: number;
    travelTimeFcdOffset: number;
}

export interface ViewPartHeight {
    mapHeight: number | null;
    overviewTables: number | null;
    totalHeight: number | null;
}

export interface MapSceneGeneralReducerState {
    flyToLocation: MapFlyTo | null;
    highlightContextMenu: HighlightContextMenu | null,
    highlightFeatures: HighlightFeatures | null,
    mapMovementAvailable: boolean;
    legendData: LegendDTO | null;
    mapIsReady: boolean;
    mapSettings: MapSettings;
    showTables: boolean;
    sidebarVisibleTab: SidebarContentPanes | null;
    viewport: MapView;
    viewPartHeight: ViewPartHeight;
    visibleTable: OverviewAllTable | null;
    visibleTableSource: OverviewTableSource | null;
}

export interface HighlightContextMenu {
    x: number;
    y: number;
    features: Record<string, MapFeature[]>;
}

export interface HighlightFeatures {
    identifier: number;
    features: MapFeature[];
}

const currentVisibleTable = UrlService.getInstance().getStringValueFromUrl('currentOverviewTable', '');
const currentOverviewTable = overviewAllTables().find((overviewTable: OverviewAllTable) => overviewTable.id === currentVisibleTable);
const currentVisibleTableSource = UrlService.getInstance().getStringValueFromUrl('currentOverviewTableSource', '');
const mapMovementAvailable = UrlService.getInstance().getBooleanValueFromUrl('mapMovementAvailable', true);
let currentTableSource: OverviewTableSource | undefined;
if (currentOverviewTable) {
    if (!currentVisibleTableSource.length) {
        currentTableSource = currentOverviewTable.sources[0];
    } else {
        currentTableSource = currentOverviewTable.sources
            .find(((overviewTableSource) => overviewTableSource.sourceType === currentVisibleTableSource));
    }
}

const initialState: MapSceneGeneralReducerState = {
    flyToLocation: null,
    highlightContextMenu: null,
    highlightFeatures: null,
    legendData: null,
    mapIsReady: false,
    mapMovementAvailable: mapMovementAvailable,
    mapSettings: {
        identifier: Date.now(),
        travelTimeFcdOffset: UrlService.getInstance().getNumericValueFromUrl('mapSettingTravelTimeFcdOffset', 0)
    },
    showTables: currentVisibleTable.length > 0,
    sidebarVisibleTab: null,
    viewPartHeight: {
        mapHeight: null,
        overviewTables: null,
        totalHeight: null
    },
    viewport: {
        latitude: UrlService.getInstance().getNumericValueFromUrl('latitude', 52.092876),
        longitude: UrlService.getInstance().getNumericValueFromUrl('longitude', 5.104480),
        zoom: UrlService.getInstance().getNumericValueFromUrl('zoom', 7)
    },
    visibleTable: currentOverviewTable || null,
    visibleTableSource: currentTableSource || null
};

const generalReducer: Reducer<MapSceneGeneralReducerState, MapSceneMapActionTypes> =
    (state: MapSceneGeneralReducerState = initialState, action: MapSceneMapActionTypes): MapSceneGeneralReducerState => {
        switch (action.type) {
            case getType(flyToLocation):
                let flyToLocationTarget: MapFlyTo | null = null;

                if (action.payload.latitude && action.payload.longitude && action.payload.zoom) {
                    flyToLocationTarget = {
                        center: [action.payload.longitude, action.payload.latitude],
                        offset: [
                            state.sidebarVisibleTab !== null ? (-490) / 2 : 0,
                            state.viewPartHeight.mapHeight && state.viewPartHeight.overviewTables ?
                                -(state.viewPartHeight.overviewTables) / 2 :
                                0
                        ],
                        zoom: action.payload.zoom
                    };
                }

                return {
                    ...state,
                    flyToLocation: flyToLocationTarget
                };
            case getType(mapSceneSettingsPanelSetNewTravelTimeLineOffset):
                return {
                    ...state,
                    mapSettings: {
                        ...state.mapSettings,
                        identifier: Date.now(),
                        travelTimeFcdOffset: action.payload.offset
                    }
                };
            case getType(setNewVisibleOverviewAllTable):
                const visibleTable: OverviewAllTable | null = action.payload.newTable;
                let showTables: boolean = false;
                let visibleTableSource: OverviewTableSource | null = null;
                let viewPartHeight = {
                    ...state.viewPartHeight,
                    mapHeight: state.viewPartHeight.totalHeight!,
                    overviewTables: 0
                };

                if (visibleTable) {
                    viewPartHeight = {
                        ...state.viewPartHeight,
                        mapHeight: state.viewPartHeight.totalHeight! - 200,
                        overviewTables: 200
                    };

                    visibleTableSource = visibleTable.sources[0];
                    showTables = true;
                }

                return {
                    ...state,
                    showTables,
                    viewPartHeight,
                    visibleTable,
                    visibleTableSource
                };
            case getType(mapSceneOverviewTableChangeOverviewTableSource):
                return {
                    ...state,
                    visibleTableSource: action.payload.newOverviewTableSource
                };
            case getType(storeLegendData):
                return {
                    ...state,
                    legendData: action.payload.data
                };
            case getType(storeMapSceneContainerViewPartHeight):
                if (state.showTables) {
                    return {
                        ...state,
                        viewPartHeight: {
                            ...state.viewPartHeight,
                            mapHeight: action.payload.containerViewPartHeight! - 200,
                            overviewTables: 200,
                            totalHeight: action.payload.containerViewPartHeight
                        }
                    };
                }

                return {
                    ...state,
                    viewPartHeight: {
                        ...state.viewPartHeight,
                        totalHeight: action.payload.containerViewPartHeight
                    }
                };
            case getType(updateMapIsReadyStatus):
                return {
                    ...state,
                    mapIsReady: action.payload.status
                };
            case getType(updateMapViewport):
                return {
                    ...state,
                    viewport: action.payload.newViewport
                };
            case getType(updateSidebarStatus):
                return {
                    ...state,
                    sidebarVisibleTab: action.payload.newVisibleTab
                };
            case getType(updateOverviewTableHeight):

                // Get the new heights
                let totalHeight = state.viewPartHeight.totalHeight;
                if (!totalHeight) {
                    totalHeight = getNativeWindow().innerHeight - NAV_BAR_HEIGHT;
                }
                const overviewTables = totalHeight - action.payload.newHeight + NAV_BAR_HEIGHT;
                const mapHeight = action.payload.newHeight - NAV_BAR_HEIGHT;

                // Top protection
                if (overviewTables > 600) {
                    return state;
                }
                // Bottom protection
                if (overviewTables < 80) {
                    return state;
                }

                return {
                    ...state,
                    viewPartHeight: {
                        ...state.viewPartHeight,
                        mapHeight,
                        overviewTables
                    }
                };
            case getType(mapSceneGeneralToggleMapMovementAvailable):
                return {
                    ...state,
                    mapMovementAvailable: !state.mapMovementAvailable
                };
            case getType(mapSceneGeneralUpdateHighlightContextMenu):
                return {
                    ...state,
                    highlightContextMenu: action.payload.highlightContextMenu
                };
            case getType(mapSceneGeneralUpdateHighlightFeatures):
                return {
                    ...state,
                    highlightFeatures: action.payload.features ? {
                        identifier: Date.now(),
                        features: action.payload.features
                    } : null,
                    highlightContextMenu: null
                };
            default:
                return {
                    ...state
                };
        }
    };

export default generalReducer;
