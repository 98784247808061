export enum OverviewAllTables {
    SituationOnda = 'SITUATION_ONDA',
    SituationTrafficMessages = 'SITUATION_TRAFFIC_MESSAGES',
    SituationBridge = 'SITUATION_BRIDGE',
    SituationRoadWorkActual = 'ROAD_WORK_ACTUAL',
    INCIDENTS = 'INCIDENTS'
}

export enum OverviewTableSourceType {
    SituationOnda = 'SITUATION_ONDA',
    SituationTrafficMessages = 'SITUATION_TRAFFIC_MESSAGES',
    SituationBridge = 'SITUATION_BRIDGE',
    SituationRoadWorkActual = 'ROAD_WORK_ACTUAL',
    WazeAlertIncident = 'WAZE_ALERT_INCIDENT',
    WazeAlertOther = 'WAZE_ALERT_OTHER',
}

export interface OverviewTableSource {
    sourceType: OverviewTableSourceType;
    name: string;
    mapLayers: string[];
    dataSource: string;
}

export interface OverviewAllTable {
    id: OverviewAllTables;
    name: string;
    sources: OverviewTableSource[];
}
