import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    image: {
        height: 'auto',
        maxWidth: '100%'
    },
    imageContainer: {
        textAlign: 'center'
    },
    textLine: {
        backgroundColor: 'black',
        borderBottomWidth: 0,
        borderColor: 'transparent',
        color: 'white',
        fontFamily: 'CdmsDefault',
        fontStyle: 'normal',
        fontWeight: 'normal'
    }
});

export default styles;
