import {
    keycloakAuthenticationEpics,
    keycloakService
} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic} from 'redux-observable';
import {filter, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {viewerApplicationBooted} from './actions';
import applicationEpics from './epics/applicationEpics';
import configurationEpics from './epics/configurationEpics';
import dialogEpics from './epics/dialogEpics';
import layoutEpics from './epics/layoutEpics';
import mapLayerConfigurationEpics from './epics/mapLayerConfigurationEpics';
import mapSceneEpics from './epics/mapSceneEpics';
import notificationModuleEpics from './epics/notificationModuleEpics';
import reloadingEpics from './epics/reloadingEpics';
import trafficCenterEpics from './epics/trafficCenterEpics';
import userSettingsEpics from './epics/userSettings';
import viewerWebsocketServiceEpics from './epics/viewerWebsocketService';
import {KEYCLOAK_CONFIG} from './constants';

const startKeycloakAuthenticationOnApplicationBooted: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApplicationBooted)),
        mergeMap(() => keycloakService.setupKeycloak(KEYCLOAK_CONFIG))
    );

const rootEpics: Epic = combineEpics(
    startKeycloakAuthenticationOnApplicationBooted,

    applicationEpics,
    configurationEpics,
    dialogEpics,
    layoutEpics,
    keycloakAuthenticationEpics,
    mapSceneEpics,
    mapLayerConfigurationEpics,
    notificationModuleEpics,
    reloadingEpics,
    trafficCenterEpics,
    userSettingsEpics,
    viewerWebsocketServiceEpics
);

export default rootEpics;
