import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    container: {
        borderRadius: 8,
        height: 12,
        position: 'relative',
        width: '100%',
        boxShadow: ' rgba(0, 0, 0, 0.1) 0px 3px 3px 0px inset'
    },
    insert: {
        borderRadius: 8,
        bottom: 0,
        left: 0,
        position: 'absolute',
        top: 0
    }
});

export default styles;
