import React from 'react';
import {Props} from './Props';
import {css} from 'aphrodite';
import styles from './styles';

const MeterBar: React.FunctionComponent<Props> = ({backgroundColor, fillColor, percentage, title}) => {
    return <div className={css(styles.container)} style={{backgroundColor}} title={title}>
        <div className={css(styles.insert)} style={{backgroundColor: fillColor, width: `${percentage}%`}}/>
    </div>;
};

export default MeterBar;
