import React, {useContext} from 'react';
import {Props} from './Props';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import ThemeContext from '../../../../../../contexts/ThemeContext';
import {LegendColorDTO, LegendIconDTO} from '../../../../../../generated/ViewerApiClient';

const LegendPane: React.FunctionComponent<Props> = ({legendData}) => {
    const themeContext = useContext(ThemeContext);

    if (!legendData) {
        return <React.Fragment/>;
    }

    return <React.Fragment>
        <h3 className={css(styles.heading)}>Knoppen in de popups</h3>
        <table className={css(styles.table)}>
            <tbody>
                <tr>
                    <td className={css(styles.firstColumn)}><i className="fas fa-thumbtack"/></td>
                    <td>Popup niet automatisch sluiten</td>
                </tr>
                <tr>
                    <td className={css(styles.firstColumn)}><i className="fas fa-window-close"/></td>
                    <td>Popup sluiten</td>
                </tr>
            </tbody>
        </table>

        <h3 className={css(styles.heading)}>Navigatie menu opties</h3>
        <table className={css(styles.table)}>
            <tbody>
                <tr>
                    <td className={css(styles.firstColumn)}><i className="fas fa-bars"/></td>
                    <td>Lagen op de kaart aan/uit zetten</td>
                </tr>
                <tr>
                    <td className={css(styles.firstColumn)}><i className="fas fa-filter"/></td>
                    <td>Verfijn de resultaten van de kaartlagen en/of lijst weergave</td>
                </tr>
                <tr>
                    <td className={css(styles.firstColumn)}><i className="fas fa-exclamation-triangle"/></td>
                    <td>Verkeersinformatie</td>
                </tr>
                <tr>
                    <td className={css(styles.firstColumn)}><i className="fas fa-chart-bar"/></td>
                    <td>Gesommeerde grafieken gebaseerd op selectie van trajecten</td>
                </tr>
                <tr>
                    <td className={css(styles.firstColumn)}><i className="fas fa-table"/></td>
                    <td>Lijst weergave met huidige Waze en situatie gegevens</td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td><i className="fas fa-info-circle"/></td>
                    <td>Legenda (dit tabblad)</td>
                </tr>
                <tr>
                    <td><i className="fas fa-code-branch"/></td>
                    <td>Versiegeschiedenis</td>
                </tr>
                <tr>
                    <td><i className="fas fa-cogs"/></td>
                    <td>Instellingen voor uw NDW Viewer</td>
                </tr>
            </tbody>
        </table>

        <h3 className={css(styles.heading)}>Kleuren op de kaart</h3>
        <table className={css(styles.table)}>
            <thead>
                <tr>
                    <th>Icoon</th>
                    <th>Omschrijving</th>
                </tr>
            </thead>
            <tbody>
                {legendData.icons.map((item: LegendIconDTO, index: number) => <tr key={index}>
                    <td>
                        <img src={`/assets/legend-icons/${item.icon}.svg`} alt={item.name} title={item.name}/>
                    </td>
                    <td>{item.name}</td>
                </tr>)}
            </tbody>
        </table>

        <h3 className={css(styles.heading)}>Kleuren op de kaart</h3>
        <table className={css(styles.table)}>
            <thead>
                <tr>
                    <th>Kleur</th>
                    <th>Omschrijving/Percentage</th>
                </tr>
            </thead>
            <tbody>
                {legendData.colors.map((item: LegendColorDTO, index: number) => <tr key={index}>
                    <td>
                        <div
                            className={css(styles.lineFill)}
                            style={{backgroundColor: themeContext.currentColorSchemeIsSuitableForColorBlindness ? item.colorBlind : item.color}}
                        >&nbsp;</div>
                    </td>
                    <td>{item.name}</td>
                </tr>)}
            </tbody>
        </table>
    </React.Fragment>;
};

export default LegendPane;
