import {FrontOfficeEventStatus} from '../../constants';

export const convertWazeTypeAndSubtypeToTranslatedEventType = (type: string, subType?: string): string => {
    let value = subType;
    if (!subType || !subType.length) {
        value = (type !== 'WEATHERHAZARD' ? type : 'HAZARD');
    }

    switch (value) {
        case 'POLICEMAN':
        case 'POLICE_VISIBLE':
        case 'POLICE_HIDING':
            return 'Politieagent';
        case 'ACCIDENT':
            return 'Ongeluk';
        case 'ACCIDENT_MINOR':
            return 'Ongeluk (klein)';
        case 'ACCIDENT_MAJOR':
            return 'Ongeluk (groot)';
        case 'JAM_MODERATE_TRAFFIC':
            return 'File (matig)';
        case 'JAM_HEAVY_TRAFFIC':
            return 'File (zwaar)';
        case 'JAM_STAND_STILL_TRAFFIC':
            return 'File (stilstaand)';
        case 'JAM_LIGHT_TRAFFIC':
            return 'File (licht)';
        case 'HAZARD_ON_ROAD':
            return 'Gevaar op wegdek';
        case 'HAZARD_ON_SHOULDER':
            return 'Gevaar op vluchtstrook';
        case 'HAZARD_WEATHER':
            return 'Gevaarlijk weer';
        case 'HAZARD_ON_ROAD_OBJECT':
            return 'Object op wegdek';
        case 'HAZARD_ON_ROAD_POT_HOLE':
            return 'Gat in wegdek';
        case 'HAZARD_ON_ROAD_ROAD_KILL':
            return 'Aangereden dier op wegdek';
        case 'HAZARD_ON_SHOULDER_CAR_STOPPED':
            return 'Stilstaande auto vluchtstrook';
        case 'HAZARD_ON_SHOULDER_ANIMALS':
            return 'Dier op vluchtstrook';
        case 'HAZARD_ON_SHOULDER_MISSING_SIGN':
            return 'Missend bord vluchtstrook';
        case 'HAZARD_WEATHER_FOG':
            return 'Weer (mist)';
        case 'HAZARD_WEATHER_HAIL':
            return 'Weer (hagel)';
        case 'HAZARD_WEATHER_HEAVY_RAIN':
            return 'Weer (zware regenval)';
        case 'HAZARD_WEATHER_HEAVY_SNOW':
            return 'Weer (zware sneeuwval)';
        case 'HAZARD_WEATHER_FLOOD':
            return 'Weer (overstroming)';
        case 'HAZARD_WEATHER_MONSOON':
            return 'Weer (moesson)';
        case 'HAZARD_WEATHER_TORNADO':
            return 'Weer (tornado)';
        case 'HAZARD_WEATHER_HEAT_WAVE':
            return 'Weer (hitte gold)';
        case 'HAZARD_WEATHER_HURRICANE':
            return 'Weer (orkaan)';
        case 'HAZARD_WEATHER_FREEZING_RAIN':
            return 'Weer (ijzel)';
        case 'HAZARD_ON_ROAD_LANE_CLOSED':
            return 'Rijstrook gesloten';
        case 'HAZARD_ON_ROAD_OIL':
            return 'Olie op wegdek';
        case 'HAZARD_ON_ROAD_ICE':
            return 'IJs op wegdek';
        case 'HAZARD_ON_ROAD_CONSTRUCTION':
            return 'Wegwerkzaamheden';
        case 'HAZARD_ON_ROAD_CAR_STOPPED':
            return 'Stilstaand voertuig';
        case 'ROAD_CLOSED_HAZARD':
            return 'Weg afgesloten wegens gevaar';
        case 'ROAD_CLOSED_CONSTRUCTION':
            return 'Weg afgesloten wegens werkzaamheden';
        case 'ROAD_CLOSED_EVENT':
            return 'Weg afgesloten wegens evenement';
        case 'JAM':
            return 'File';
        case 'HAZARD':
            return 'Gevaar';
        default:
            return value as string;
    }
};

export const translateFrontOfficeEventStatus = (status: FrontOfficeEventStatus): string => {
    switch (status) {
        case FrontOfficeEventStatus.noAction:
            return 'Niets aan de hand';
        case FrontOfficeEventStatus.selfAction:
            return 'Zelf laten oplossen';
        case FrontOfficeEventStatus.startAction:
            return 'IM Opstarten';
        case FrontOfficeEventStatus.none:
            return 'Niet beoordeeld';
        case FrontOfficeEventStatus.knowMessage:
            return 'Reeds bekend';
        default:
            return status;
    }
};

export const translateSituationType = (situationType: string): string => {
    switch (situationType) {
        case 'CarParks':
            return 'Parkeergelegenheden';
        case 'TransitInformation':
            return 'OV informatie';
        case 'AbnormalTraffic':
            return 'Afwijkend verkeer / files';
        case 'Accident':
            return 'Ongeval';
        case 'EquipmentOrSystemFault':
            return 'Apparatuur storing';
        case 'AuthorityOperation':
            return 'Politie of autoriteiten operatie';
        case 'PublicEvent':
            return 'Evenement';
        case 'AnimalPresenceObstruction':
            return 'Dier op de weg';
        case 'EnvironmentalObstruction':
        case 'GeneralObstruction':
        case 'VehicleObstruction':
            return 'Weg obstructie';
        case 'InfrastructureDamageObstruction':
            return 'Beschadigde infrastructuur';
        case 'NonWeatherRelatedRoadConditions':
            return 'Slecht wegdek';
        case 'WeatherRelatedRoadConditions':
            return 'Glad wegdek';
        case 'RoadsideAssistance':
            return 'Pechgeval';
        case 'ConstructionWorks':
        case 'MaintenanceWorks':
            return 'Wegwerkzaamheden';
        case 'GeneralInstructionOrMessageToRoadUsers':
            return 'Instructie aan weggebruiker';
        case 'RoadOrCarriagewayOrLaneManagement':
            return 'Rijstrookafsluiting';
        case 'SpeedManagement':
            return 'Snelheidsrestrictie';
        default:
            return situationType;
    }
};
export const timeToMessageAgeString = (time: string | number, addSeconds: boolean = false): string => {
    const date = new Date(time);
    const timeInMS = date.getTime();

    if (isNaN(timeInMS)) {
        return '-';
    }

    let delta = Math.abs(Date.now() - timeInMS) / 1000;

    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = delta % 60;

    let timeString = '';
    if (days > 0) {
        timeString += `${days} dag${days > 1 ? 'en' : ''}, `;
    }
    if (hours > 0 || days > 0) {
        timeString += `${hours} ${hours > 1 ? 'uren' : 'uur'}, `;
    }
    if ((addSeconds && minutes > 0) || !addSeconds) {
        timeString += `${minutes} ${minutes > 1 ? 'minuten' : 'minuut'}`;
    }
    if (addSeconds) {
        timeString += `${minutes > 0 ? ', ' : ''}${Math.round(seconds)} seconde${seconds > 1 ? 'n' : ''}`;
    }

    return timeString;
};

export const parseDateToFormatted = (dateObject: Date, addMS: boolean): string => {

    const now = dateObject || new Date();
    addMS = addMS || false;
    const year = '' + now.getFullYear();
    let month = '' + (now.getMonth() + 1);
    if (month.length == 1) {
        month = '0' + month;
    }
    let day = '' + now.getDate();
    if (day.length == 1) {
        day = '0' + day;
    }
    let hour = '' + now.getHours();
    if (hour.length == 1) {
        hour = '0' + hour;
    }
    let minute = '' + now.getMinutes();
    if (minute.length == 1) {
        minute = '0' + minute;
    }
    let second = '' + now.getSeconds();
    if (second.length == 1) {
        second = '0' + second;
    }
    let miliseconds = '' + now.getMilliseconds();
    if (miliseconds.length == 1) {
        miliseconds = '0' + miliseconds;
    }

    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second + (addMS ? '.' + miliseconds : '');
};

export const extractOriginalFlowSpeedIdFromRegex: (flowSpeedIdWithLane: string) => string = (flowSpeedIdWithLane) => {
    const flowSpeedIdSelectRegex: RegExp = /^(.*?)(?=_lane)/;
    const flowSpeedIdSelectResult: RegExpExecArray | null = flowSpeedIdSelectRegex.exec(flowSpeedIdWithLane);

    return flowSpeedIdSelectResult ? flowSpeedIdSelectResult[0] : '';
};
