import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    featureItem: {
        'background': '#f6f6f6',
        'borderColor': '#ccc',
        'borderStyle': 'solid',
        'borderWidth': 1,
        'color': '#636363',
        'cursor': 'pointer',
        'fontWeight': 'bold',
        'marginBottom': '.2rem',
        'overflow': 'hidden',
        'padding': '.4em .8rem',
        'textOverflow': 'ellipsis',
        'whiteSpace': 'nowrap',
        'width': 'auto',
        ':hover': {
            backgroundColor: '#fff'
        }
    },
    header: {
        fontWeight: 'bold',
        marginTop: '1rem',
        marginBottom: '.5rem'
    }
});

export default styles;
