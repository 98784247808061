import {ActionType, createAction} from 'typesafe-actions';
import {SegmentHeatMapDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceSegmentHeatMapLoadedData = createAction(
    'VIEWER_API_SERVICE_SEGMENT_HEAT_MAP_LOADED_DATA',
    (action) => (featureId: string, featureKey: number, segmentData: SegmentHeatMapDTO) => action({featureId, featureKey, segmentData})
);

export const viewerApiServiceSegmentHeatmapLoadingDataFailedWithBadRequestException = createAction(
    'VIEWER_API_SERVICE_SEGMENT_HEAT_MAP_LOADING_DATA_FAILED_WITH_BAD_REQUEST_EXCEPTION',
    (action) => (featureId: string) => action({featureId})
);

export const viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnauthorizedException = createAction(
    'VIEWER_API_SERVICE_SEGMENT_HEAT_MAP_LOADING_DATA_FAILED_WITH_UNAUTHORIZED_EXCEPTION',
    (action) => (featureId: string) => action({featureId})
);

export const viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_SEGMENT_HEAT_MAP_LOADING_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => (featureId: string) => action({featureId})
);

export type ViewerApiServiceSegmentHeatMapActionTypes = ActionType<typeof viewerApiServiceSegmentHeatMapLoadedData> |
    ActionType<typeof viewerApiServiceSegmentHeatmapLoadingDataFailedWithBadRequestException> |
    ActionType<typeof viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnauthorizedException> |
    ActionType<typeof viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnexpectedError>;
