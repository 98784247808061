import {createAction} from 'typesafe-actions';
import {WAZE_ALERT_ITEM_SUB_TYPES, WAZE_ALERT_ITEM_TYPES} from '../../../../interfaces/WazeAlertItemStatus';
import {FrontOfficeEventStatus} from '../../../../constants';

export const closeWazeAlertDialog = createAction(
    'CLOSE_WAZE_ALERT_DIALOG',
    (action) => (id: string) => action({id})
);

export const openWazeAlertDialog = createAction(
    'OPEN_WAZE_ALERT_DIALOG',
    (action) => (id: string) => action({id})
);

export const changeWazeAlertData = createAction(
    'CHANGE_WAZE_ALERT_DATA',
    (action) => (
        id: string | number,
        name: string,
        status: FrontOfficeEventStatus | undefined,
        hectometrePost: string | undefined,
        type: WAZE_ALERT_ITEM_TYPES | undefined,
        subType: WAZE_ALERT_ITEM_SUB_TYPES | undefined
    ) => action({id, name, status, hectometrePost, type, subType})
);

export const saveWazeAlertData = createAction(
    'SAVE_WAZE_ALERT_DATA',
    (action) => (
        id: string | number,
        name: string,
        status: FrontOfficeEventStatus | undefined,
        hectometrePost: string | undefined,
        type: WAZE_ALERT_ITEM_TYPES | undefined,
        subType: WAZE_ALERT_ITEM_SUB_TYPES | undefined
    ) =>
        action({id, name, status, hectometrePost, type, subType})
);
