import React, {ChangeEvent, ReactElement} from 'react';
import {Theme, THEME_NUMBER, themes} from '../../../../../../themes';
import {Props} from './Props';
import StyledSelect from '../../../../../../components/StyledSelect';
import StyledLabel from '../../../../../../components/StyledLabel';
import {TrafficCenterDTO} from '../../../../../../generated/ViewerApiClient';
import StyledInput from '../../../../../../components/StyledInput';

class UserSettingsPane extends React.Component<Props> {

    userSelectedNewTheme = (value: string): void => {
        const props: Readonly<Props> = this.props;
        props.changeCurrentTheme(parseInt(value, 10) as THEME_NUMBER);
    };

    userSelectedNewTrafficCenter = (key: string): void => {
        const props: Readonly<Props> = this.props;
        const {trafficCenters} = props;

        if (key.length === 0) {
            this.props.selectedNewTrafficCenter(null);
        }

        let trafficCenter: TrafficCenterDTO | null = null;
        trafficCenters.forEach((item: TrafficCenterDTO) => {
            if (item.key === key) {
                trafficCenter = item;
            }
        });

        this.props.selectedNewTrafficCenter(trafficCenter);
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {
            currentTrafficCenter,
            currentThemeID,
            mapSettings,
            trafficCenters,
            showRecordIds,
            showTrafficJamIcons,
            mapMovementAvailable
        } = props;

        return <React.Fragment>

            <StyledLabel title={'Verkeerscentrale'}/>
            <StyledSelect
                value={currentTrafficCenter ? currentTrafficCenter.key : undefined}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => this.userSelectedNewTrafficCenter(e.target.value)}
            >
                <option value={''}>Geen selectie</option>
                {trafficCenters.map((trafficCenter: TrafficCenterDTO) =>
                    <option value={trafficCenter.key} key={trafficCenter.key}>{trafficCenter.name}</option>)}
            </StyledSelect>
            <StyledLabel title={'Toon file iconen'}/>
            <StyledSelect
                value={showTrafficJamIcons ? 'true' : 'false'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => props.onToggleShowTrafficJamIcons(e.target.value === 'true')}
            >
                <option value={'true'}>Ja</option>
                <option value={'false'}>Nee</option>
            </StyledSelect>
            <StyledLabel title={'Kleurensamenstelling'}/>
            <StyledSelect
                value={currentThemeID}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => this.userSelectedNewTheme(e.target.value)}
            >
                {themes.map((theme: Theme) => <option value={theme.index} key={theme.index}>{theme.name}</option>)}
            </StyledSelect>

            <StyledLabel title={'ID\'s weergeven bij records'}/>
            <StyledSelect
                value={showRecordIds ? 'true' : 'false'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => props.onToggleShowRecordIds(e.target.value === 'true')}
            >
                <option value={'true'}>Ja</option>
                <option value={'false'}>Nee</option>
            </StyledSelect>

            <StyledLabel title={'Lijn offset (FCD Trajecten en RWS keten)'}/>
            <StyledInput
                type={'number'}
                value={mapSettings.travelTimeFcdOffset}
                changeInput={(e: string) => {
                    this.props.setNewTravelTimeLineOffset(parseInt(e || '0', 10));
                }}
            />

            <StyledLabel title={'Bevriezen van de locatie en zoom niveau'}/>
            <StyledSelect
                value={mapMovementAvailable ? 'true' : 'false'}
                onChange={() => props.onToggleMapMovementAvailable()}
            >
                <option value={'false'}>Bevroren</option>
                <option value={'true'}>Verplaatsing is mogelijk</option>
            </StyledSelect>
        </React.Fragment>;
    }
}

export default UserSettingsPane;
