import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    bearingIcon: {
        color: '#f8b74c',
        fontSize: 23,
        padding: 10
    }
});

export default styles;
