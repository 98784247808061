import {ActionType, createAction} from 'typesafe-actions';

export const viewerApiServiceReceivedWazeStatusCloseSuccessful = createAction(
    'VIEWER_API_SERVICE_RECEIVED_WAZE_STATUS_CLOSE_SUCCESSFUL',
    (action) => (id: string) => action({id})
);

export const viewerApiServiceReceivingWazeStatusCloseFailedWithBadRequestResponse = createAction(
    'VIEWER_API_SERVICE_RECEIVING_WAZE_STATUS_CLOSE_FAILED_WITH_BAD_REQUEST_RESPONSE',
    (action) => () => action({})
);

export const viewerApiServiceReceivingWazeStatusCloseFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_WAZE_STATUS_CLOSE_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingWazeStatusCloseFailedWithNotFoundError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_WAZE_STATUS_CLOSE_FAILED_WITH_NOT_FOUND_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingWazeStatusCloseFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_WAZE_STATUS_CLOSE_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type WazeStatusCloseDataActionTypes = ActionType<typeof viewerApiServiceReceivedWazeStatusCloseSuccessful> |
    ActionType<typeof viewerApiServiceReceivingWazeStatusCloseFailedWithBadRequestResponse> |
    ActionType<typeof viewerApiServiceReceivingWazeStatusCloseFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceReceivingWazeStatusCloseFailedWithNotFoundError> |
    ActionType<typeof viewerApiServiceReceivingWazeStatusCloseFailedWithUnexpectedError>;
