import {StyleSheet} from 'aphrodite';

export const colorBlindnessStyles = StyleSheet.create({
    dialogContainer: {
        backgroundColor: '#80b1d3',
        borderColor: '#a6761d',
        color: 'white'
    }
});

export const darkStyles = StyleSheet.create({
    dialogContainer: {
        backgroundColor: '#555',
        borderColor: '#363636',
        color: 'white'
    }
});

export const styles = StyleSheet.create({
    dialogContainer: {
        backgroundColor: 'rgb(238, 238, 238)',
        borderColor: '#ccc',
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        padding: '.2em',
        transition: 'height .3s ease-out, top .3s ease-out, width .3s ease-out, left .3s ease-out',
        width: 400
    },
    dialogContent: {
        maxHeight: 'calc(100vh - 190px)',
        overflowY: 'auto',
        padding: '.5em 1em'
    },
    dialogHeader: {
        backgroundColor: '#f8b74c',
        borderColor: '#e78f08',
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        color: 'white',
        cursor: 'grab',
        display: 'flex',
        fontWeight: 'bold',
        padding: '.4em 1em',
        position: 'relative',
        wordBreak: 'break-word'
    },
    dialogContainerMouseDown: {
        cursor: 'grabbing'
    },
    dialogHeaderButtons: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    dialogHeaderClose: {
        color: '#dc3545',
        cursor: 'pointer'
    },
    dialogHeaderPin: {
        color: '#dc3545',
        cursor: 'pointer',
        marginLeft: 10,
        marginRight: 10,
        transition: 'transform 0.3s, color 0.3s'
    },
    dialogHeaderPinActive: {
        color: '#28a745',
        transform: 'rotate(45deg)'
    },
    dialogHeaderTitle: {
        flex: 1
    }
});
