import {ActionType, createAction} from 'typesafe-actions';
import {NotificationDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceSavedNotificationMessage = createAction(
    'VIEWER_API_SERVICE_SAVED_NOTIFICATION_MESSAGE',
    (action) => () => action({})
);

export const viewerApiServiceSavingNotificationMessageFailedWithBadRequestException = createAction(
    'VIEWER_API_SERVICE_SAVING_NOTIFICATION_MESSAGE_FAILED_WITH_BAD_REQUEST_EXCEPTION',
    (action) => () => action({})
);

export const viewerApiServiceSavingNotificationFailedWithUnauthorizedException = createAction(
    'VIEWER_API_SERVICE_SAVING_NOTIFICATION_FAILED_WITH_UNAUTHORIZED_EXCEPTION',
    (action) => () => action({})
);

export const viewerApiServiceSavingNotificationFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_SAVING_NOTIFICATION_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivedNotificationMessage = createAction(
    'VIEWER_API_SERVICE_RECEIVED_NOTIFICATION_MESSAGE',
    (action) => (notification: NotificationDTO | null) => action({notification})
);

export const viewerApiServiceReceivingNotificationMessageFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_NOTIFICATION_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingNotificationMessageFailedWithNotFoundError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_NOTIFICATION_MESSAGE_FAILED_WITH_NOT_FOUND_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingNotificationMessageFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_NOTIFICATION_MESSAGE_FAILED',
    (action) => () => action({})
);

export type NotificationPaneActionTypes = ActionType<typeof viewerApiServiceSavedNotificationMessage> |
    ActionType<typeof viewerApiServiceSavingNotificationMessageFailedWithBadRequestException> |
    ActionType<typeof viewerApiServiceSavingNotificationFailedWithUnauthorizedException> |
    ActionType<typeof viewerApiServiceSavingNotificationFailedWithUnexpectedError> |
    ActionType<typeof viewerApiServiceReceivedNotificationMessage> |
    ActionType<typeof viewerApiServiceReceivingNotificationMessageFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceReceivingNotificationMessageFailedWithNotFoundError> |
    ActionType<typeof viewerApiServiceReceivingNotificationMessageFailedWithUnexpectedError>;
