import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import MeterBar from '../../../../../../components/MeterBar';
import StyledHeading from '../../../../../../components/StyledHeading';

class DialogParking extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Tijd melding</th>
                        <td>
                            <DateTimeFormatted
                                dateTime={properties.publicationTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{properties.state}</td>
                    </tr>
                </tbody>
            </table>

            <StyledHeading>Openbare plekken</StyledHeading>
            <table>
                <tr>
                    <th>Capaciteit</th>
                    <td>{properties.shortCapacity}</td>
                </tr>
                <tr>
                    <th>Beschikbare plekken</th>
                    <td>{properties.freeSpaceShort}</td>
                </tr>
            </table>
            <MeterBar
                percentage={properties.percentageFreeShort}
                backgroundColor={'#dc3545'}
                fillColor={'#28a745'}
            />

            {properties.longCapacity > 0 && <React.Fragment>
                <StyledHeading>Gereserveerde plekken</StyledHeading>
                <table>
                    <tr>
                        <th>Capaciteit</th>
                        <td>{properties.longCapacity}</td>
                    </tr>
                    <tr>
                        <th>Beschikbare plekken</th>
                        <td>{properties.freeSpaceLong}</td>
                    </tr>
                </table>
                <MeterBar
                    percentage={properties.percentageFreeLong}
                    backgroundColor={'#dc3545'}
                    fillColor={'#28a745'}
                />
            </React.Fragment>}
        </React.Fragment>;
    }

}

export default DialogParking;
