import {createAction} from 'typesafe-actions';
import {THEME_NUMBER} from '../../../../themes';

export const mapSceneSettingsPaneSelectedNewTheme = createAction(
    'MAP_SCENE_SETTINGS_PANE_SELECTED_NEW_THEME',
    (action) => (newThemeID: THEME_NUMBER) => action({newThemeID})
);

export const mapSceneSettingsPanelSetNewTravelTimeLineOffset = createAction(
    'MAP_SCENE_SETTINGS_PANEL_SET_NEW_TRAVEL_TIME_LINE_OFFSET',
    (action) => (offset: number) => action({offset})
);

export const mapSceneSettingsPanelToggleShowRecordId = createAction(
    'MAP_SCENE_SETTINGS_PANEL_TOGGLE_SHOW_RECORD_ID',
    (action) => (value: boolean) => action({value})
);

export const mapSceneSettingsPanelToggleShowTrafficJamIcons = createAction(
    'MAP_SCENE_SETTINGS_PANEL_TOGGLE_SHOW_TRAFFIC_JAM_ICONS',
    (action) => (value: boolean) => action({value})
);
