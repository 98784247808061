import React, {useContext} from 'react';
import {Props} from './Props';
import {css} from 'aphrodite/no-important';
import {darkStyles, styles} from './styles';
import ThemeContext from '../../../../../../contexts/ThemeContext';

const OverviewTableSourceButton: React.FunctionComponent<Props> = ({onOverviewTableChangeOverviewTableSource, source, active}) => {
    const themeContext = useContext(ThemeContext);
    const {name} = source;

    return <>
        <li
            onClick={() => onOverviewTableChangeOverviewTableSource(source)}
            className={css(
                styles.buttonItem,
                themeContext.currentColorSchemeIsDark && darkStyles.buttonItem,
                active && styles.buttonItemActive
            )}
        >
            <span>{name}</span>
        </li>
    </>;
};

export default OverviewTableSourceButton;
