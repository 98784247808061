import {IS_ACCEPTANCE, MAPS_SERVER_BASE_URL} from './applicationContext';

export enum ThemeColorScheme {
    LIGHT = 'light',
    DARK = 'dark'
}

export type THEME_NUMBER = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export interface Theme {
    colorScheme: ThemeColorScheme;
    index: THEME_NUMBER;
    mapboxStyleUrl: string;
    name: string;
    colorBlind: boolean;
}

const lightTheme: Theme = {
    colorBlind: false,
    colorScheme: ThemeColorScheme.LIGHT,
    index: 1,
    mapboxStyleUrl: `${MAPS_SERVER_BASE_URL}/styles/osm-basic.json`,
    name: 'Licht'
};

const streetsTheme: Theme = {
    colorBlind: false,
    colorScheme: ThemeColorScheme.LIGHT,
    index: 2,
    mapboxStyleUrl: `${MAPS_SERVER_BASE_URL}/styles/osm-default.json`,
    name: 'Streets'
};

const darkTheme: Theme = {
    colorBlind: false,
    colorScheme: ThemeColorScheme.DARK,
    index: 3,
    mapboxStyleUrl: `${MAPS_SERVER_BASE_URL}/styles/osm-dark.json`,
    name: 'Donker'
};

const wvlLightTheme: Theme = {
    colorBlind: false,
    colorScheme: ThemeColorScheme.LIGHT,
    index: 4,
    mapboxStyleUrl: `${MAPS_SERVER_BASE_URL}/styles/osm-front-office-light.json`,
    name: 'WVL - Licht'
};

const wvlDarkTheme: Theme = {
    colorBlind: false,
    colorScheme: ThemeColorScheme.DARK,
    index: 5,
    mapboxStyleUrl: `${MAPS_SERVER_BASE_URL}/styles/osm-front-office-dark.json`,
    name: 'WVL - Donker'
};

const lightThemeColorBlind: Theme = {
    colorBlind: true,
    colorScheme: ThemeColorScheme.LIGHT,
    index: 6,
    mapboxStyleUrl: `${MAPS_SERVER_BASE_URL}/styles/osm-color-blind.json`,
    name: 'Hoog contrast'
};

const pngThemeLight: Theme = {
    colorBlind: true,
    colorScheme: ThemeColorScheme.LIGHT,
    index: 7,
    mapboxStyleUrl: '/assets/map-styles/maps-png-light.json',
    name: 'PNG - Licht'
};

const osmDarkHighlightedRoads: Theme = {
    colorBlind: false,
    colorScheme: ThemeColorScheme.DARK,
    index: 8,
    mapboxStyleUrl: `${MAPS_SERVER_BASE_URL}/styles/osm-dark-with-highlighted-main-roads.json`,
    name: 'Donker - Uitgelichte wegen'
};

const osmWithUrbanAreaHighlighted: Theme = {
    colorBlind: false,
    colorScheme: ThemeColorScheme.LIGHT,
    index: 9,
    mapboxStyleUrl: `${MAPS_SERVER_BASE_URL}/styles/osm-green.json`,
    name: 'Licht - Uitgelichte stedelijke gebieden'
};

export const themes: Theme[] = [
    lightTheme,
    streetsTheme,
    darkTheme,
    wvlLightTheme,
    wvlDarkTheme,
    lightThemeColorBlind,
    ... IS_ACCEPTANCE ? [pngThemeLight] : [],
    osmDarkHighlightedRoads,
    osmWithUrbanAreaHighlighted
];
