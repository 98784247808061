import {ActionType, createAction} from 'typesafe-actions';

import {SortingDirection, SortingValue} from '../../../../interfaces/TrafficInformation';

export const mapSceneTrafficInformationSortingValue = createAction(
    'MAP_SCENE_TRAFFIC_INFORMATION_SORTING_VALUE',
    (action) => (isSortByValue: SortingValue) => action({isSortByValue})
);

export const mapSceneTrafficInformationSortingDirection = createAction(
    'MAP_SCENE_TRAFFIC_INFORMATION_SORTING_DIRECTION',
    (action) => (isSortInDirection: SortingDirection) => action({isSortInDirection})
);

export type MapSceneTrafficInformationReducerActionTypes =
    ActionType<typeof mapSceneTrafficInformationSortingValue> |
    ActionType<typeof mapSceneTrafficInformationSortingDirection>;
