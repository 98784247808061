import {Props} from './Props';
import React, {useState} from 'react';
import {css} from 'aphrodite';
import styles from './styles';

export const DialogueHiddenContent: React.FunctionComponent<Props> = (props) => {
    const {Dialogue} = props;
    const [showContent, toggleContent] = useState<boolean>(false);

    return (
        <div>
            <div className={css(styles.subTitle)}>
                {props.subTitle}
            </div>
            <div className={css(styles.description)}>
                {props.description}
            </div>
            <hr/>
            {showContent ?
                <div className={css(styles.showMoreButton)} onClick={() => toggleContent(!showContent)}>toon minder <i
                    className="fas fa-arrow-up"/></div> :
                <div className={css(styles.showMoreButton)} onClick={() => toggleContent(!showContent)}>toon meer <i
                    className="fas fa-arrow-down"/></div>}
            <div className={showContent ? css(styles.showContent) : css(styles.hideContent)}>
                {Dialogue}
            </div>
        </div>
    );
};
