import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    line: {
        height: '8px',
        width: '100%'
    }
});

export default styles;
