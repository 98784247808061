import {ActionType, createAction} from 'typesafe-actions';
import {DataSource} from '../../../../interfaces/DataSource';
import {LayersState} from '../../reducers/mapboxLayersReducer';
import {PrefixDTO} from '../../../../generated/ViewerApiClient';
import {MapLayer} from '../../../../interfaces/MapLayer';
import {viewerApplicationTestAction} from '../../../../actions';
import {DataSourceState} from '../../reducers/dataReducer';

export const mapSceneMapboxLayersActivateMapLayer = createAction(
    'MAP_SCENE_MAPBOX_LAYERS_ACTIVATE_MAP_LAYER',
    (action) => (mapLayer: MapLayer) => action({mapLayer})
);

export const mapSceneMapboxLayersActivateDataSource = createAction(
    'MAP_SCENE_MAPBOX_LAYERS_ACTIVATE_DATA_SOURCE',
    (action) => (mapSource: DataSource, mapLayerToActivate: string) => action({mapSource, mapLayerToActivate})
);

export const mapSceneMapboxLayersDeactivateMapLayer = createAction(
    'MAP_SCENE_MAPBOX_LAYERS_DEACTIVATE_MAP_LAYER',
    (action) => (mapLayer: MapLayer) => action({mapLayer})
);

export const mapSceneMapboxLayersUpdateMapLayerConfiguration = createAction(
    'MAP_SCENE_MAPBOX_LAYERS_UPDATE_MAP_LAYER_CONFIGURATION',
    (action) => (mapLayers: LayersState, dataSources: DataSourceState) => action({mapLayers, dataSources})
);

export const mapSceneMapboxLayersUpdatePrefixData = createAction(
    'MAP_SCENE_MAPBOX_LAYERS_UPDATE_PREFIX_DATA',
    (action) => (prefixes: PrefixDTO[]) => action({prefixes})
);

export type MapSceneMapboxLayersActionTypes =
    ActionType<typeof mapSceneMapboxLayersActivateMapLayer> |
    ActionType<typeof mapSceneMapboxLayersActivateDataSource> |
    ActionType<typeof mapSceneMapboxLayersDeactivateMapLayer> |
    ActionType<typeof mapSceneMapboxLayersUpdateMapLayerConfiguration> |
    ActionType<typeof mapSceneMapboxLayersUpdatePrefixData> |
    ActionType<typeof viewerApplicationTestAction>;
