import {css} from 'aphrodite/no-important';
import React, {ReactElement} from 'react';
import {ENVIRONMENT, IS_ACCEPTANCE, VERSION} from '../../../../applicationContext';
import ndwLogo from '../../../../assets/logo-ndw.svg';
import ThemeContext from '../../../../contexts/ThemeContext';
import {MAP} from '../../../../routes';
import {getNativeWindow} from '../../../../services/WindowService';
import {ACCEPTANCE_STYLE, ACCEPTANCE_STYLE_DARK} from '../../../../styles';
import ColorLine from '../../../ColorLine';
import NavbarItem from './components/NavbarItem';
import NavbarItemCollection from './components/NavbarItemCollection';
import NotificationItem from './components/NotificationItem';
import UserMenu from './components/UserMenu';
import {Props} from './Props';
import styles, {darkStyles} from './styles';
import ButtonIcon from '../../../ButtonIcon';

const SHARE_LINK_LABEL = 'Deel je huidige NDW Viewer configuratie via een email';

class NavigationBar extends React.Component<Props> {

    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;

    openEmailWithShareLink = (): void => {

        const emailAddress = '';
        const subject = 'NDW Viewer configuratie';
        const body = encodeURIComponent(`Beste lezer,

Met deze link kan je de NDW Viewer openen met mijn configuratie: ${document.location.href}.

Met vriendelijke groet,`);

        const window = getNativeWindow().open(`mailto:${emailAddress}?subject=${subject}&body=${body}`);
        setTimeout(() => {
            if (window && !window.closed) {
                window.close();
            }
        }, 100);
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {currentRoute, notification} = props;

        const {colorLinePrimary, colorLineSecondary} = this.colorLineColors();

        return <div>
            <div className={css(
                styles.navigationContainer,
                this.context.currentColorSchemeIsDark && darkStyles.navigationContainer
            )}>

                <div className={css(styles.logoContainer)}>
                    <img src={ndwLogo} title={'NDW'} className={css(styles.logo)} alt={'NDW'}/>
                    <div className={css(styles.versionText)}>{VERSION} ({ENVIRONMENT})</div>
                </div>

                <NavbarItemCollection alignNavbar={'LEFT'}>
                    <NavbarItem
                        active={currentRoute === MAP}
                        title={'Viewer'}
                        onClick={() => {
                            props.changeCurrentRoute(MAP);
                        }}
                    />
                </NavbarItemCollection>

                <NavbarItemCollection alignNavbar={'RIGHT'}>
                    <li><NotificationItem currentNotification={notification}/></li>

                    <li>
                        <ButtonIcon
                            iconClassName="fas fa-share-square"
                            onClick={this.openEmailWithShareLink}
                            aria-label={SHARE_LINK_LABEL}
                            title={SHARE_LINK_LABEL}
                            addHoverStyle
                        />
                    </li>

                    <li>
                        <UserMenu
                            authenticationIsInDevelopment={props.authenticationIsInDevelopment}
                            userProfile={props.userProfile}
                            onRequestedLogout={props.onRequestedLogout}
                        />
                    </li>
                </NavbarItemCollection>

            </div>
            <ColorLine primaryColor={colorLinePrimary} secondaryColor={colorLineSecondary}/>
        </div>;
    }

    private colorLineColors = () => {
        if (!IS_ACCEPTANCE) {
            return {colorLinePrimary: '#F7893C', colorLineSecondary: '#1DCAD3'};
        }

        return {
            colorLinePrimary: this.context.currentColorSchemeIsDark ? ACCEPTANCE_STYLE_DARK.secondary : ACCEPTANCE_STYLE.secondary,
            colorLineSecondary: this.context.currentColorSchemeIsDark ? ACCEPTANCE_STYLE_DARK.tertiary : ACCEPTANCE_STYLE.tertiary
        };
    };
}

export default NavigationBar;
