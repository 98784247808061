import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {EMPTY, mergeMap, switchMap} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    ActiveDialogGeoJsonFeatureBridge,
    ActiveDialogGeoJsonFeatureFlowSpeed,
    ActiveDialogGeoJsonFeatureGeneral,
    ActiveDialogGeoJsonFeatureWazeAlertIncident,
    ActiveDialogSelectFeature,
    ActiveDialogVectorLayerFeatureGeneral,
    ActiveDialogVectorLayerFeatureTravelTime,
    DIALOG_INTERFACES,
    DialogTypes,
    GeoJsonSubTypes,
    VectorLayerSubTypes
} from '../../interfaces/ActiveDialog';
import {GeoJsonPropertiesWaze} from '../../interfaces/GeoJsonSources/GeoJsonPropertiesWaze';
import {RootState} from '../../reducer';
import {
    changeDialogPinStatus,
    closeAllUnpinnedPopups,
    closeHighestPriorityDialog,
    closePopupWithID,
    focusOnDialog
} from '../../scenes/MapScene/actions';
import {
    hidePreviewPopup,
    showPopupForFeatures,
    showPreviewPopupForFeatures
} from '../../scenes/MapScene/actions/mapBoxComponent';
import {
    closeCurrentDialog,
    closeHighestIndexDialog,
    makeDialogProminent,
    mapSceneActiveDialogAddNewDialog,
    openNewDialog,
    openNewWazeDialog,
    removePopupsWithMapSource,
    removeUnpinnedActiveDialogs,
    updateActiveDialogCountUnpinned,
    updateActiveDialogPinStatus
} from '../../scenes/MapScene/actions/reducers/activeDialogs';
import {mapSceneDataDeactivateDataSource} from '../../scenes/MapScene/actions/reducers/data';
import graphEpicEffects from './graphEpics';
import wazeEpics from './wazeEpics';
import _ from 'lodash';

const addNewDialogOnOpenNewDialog: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(openNewDialog)),
        filter(({payload: {dialog}}) =>
            state$.value.mapScene.activeDialogs.activeDialogs.filter((filterDialog: DIALOG_INTERFACES) =>
                dialog.id === filterDialog.id && dialog.type === filterDialog.type
            ).length === 0),
        map(({payload: {dialog}}) => mapSceneActiveDialogAddNewDialog(dialog))
    );

const closeCurrentDialogOnClosePopupWithID: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(closePopupWithID)),
        map((action) => closeCurrentDialog(action.payload.id))
    );

const closeHighestIndexDialogOnCloseHighestPriorityDialog: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(closeHighestPriorityDialog)),
        map(() => closeHighestIndexDialog())
    );

const makeDialogProminentOnFocusOnDialog: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(focusOnDialog)),
        map(({payload: {index}}) => makeDialogProminent(index, state$.value.mapScene.activeDialogs.activeDialogs[index].preview))
    );

const makeDialogProminentOnDoubleOpenDialog: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(openNewDialog)),
        filter(({payload: {dialog}}) =>
            state$.value.mapScene.activeDialogs.activeDialogs.filter((filterDialog: DIALOG_INTERFACES) =>
                dialog.id === filterDialog.id && dialog.type === filterDialog.type
            ).length > 0),
        map(({payload: {dialog}}) => {
            const dialogIndex = state$.value.mapScene.activeDialogs.activeDialogs
                .findIndex((filterDialog: DIALOG_INTERFACES | undefined) =>
                    filterDialog && dialog.id === filterDialog.id && dialog.type === filterDialog.type);

            const preview = state$.value.mapScene.activeDialogs.activeDialogs[dialogIndex].preview
                ? dialog.preview
                : false;
            return makeDialogProminent(dialogIndex, preview);
        })
    );

const hideHoverPopups: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(hidePreviewPopup)),
        switchMap(() => {
            const dialogsToBeClosed = state$.value.mapScene.activeDialogs.activeDialogs.filter(dialog => dialog.preview);
            return dialogsToBeClosed.map(dialog => closeCurrentDialog(dialog.id));
        })
    );

const openNewDialogOnShowPopupForFeatures: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf([showPreviewPopupForFeatures, showPopupForFeatures])),
        map((action) => {
            const isPreview = action.type === 'SHOW_PREVIEW_POPUP_FOR_FEATURES';
            if (_.uniqBy(action.payload.clickedFeatures, (f) => f.id).length > 1) {
                const newDialog: ActiveDialogSelectFeature = {
                    features: action.payload.clickedFeatures,
                    id: Date.now(),
                    pinned: true,
                    preview: isPreview,
                    startPosition: {
                        x: action.payload.pointX,
                        y: action.payload.pointY
                    },
                    type: DialogTypes.SELECT_FEATURES,
                    zIndex: state$.value.mapScene.activeDialogs.currentHighestPopupZIndex + 1
                };
                return openNewDialog(newDialog);
            }

            if (action.payload.clickedFeatures.length === 0) {
                return EMPTY;
            }

            const firstFeature = action.payload.clickedFeatures[0];
            const dataSource = state$.value.mapScene.data.sources[firstFeature.source];

            switch (dataSource.dataLoadMethod) {
                case 'GeoJSON':
                    switch (firstFeature.layer) {
                        case 'bridge':
                            const geoJsonFeatureBridge: ActiveDialogGeoJsonFeatureBridge = {
                                bridgeHistory: null,
                                currentProperties: firstFeature.featurePropertiesData,
                                id: firstFeature.id,
                                mapLayer: firstFeature.layer,
                                dataSource: firstFeature.source,
                                pinned: false,
                                preview: isPreview,
                                startPosition: {
                                    x: action.payload.pointX + 10,
                                    y: action.payload.pointY + 10
                                },
                                subType: GeoJsonSubTypes.BRIDGE,
                                type: DialogTypes.GEO_JSON,
                                zIndex: state$.value.mapScene.activeDialogs.currentHighestPopupZIndex + 1
                            };

                            return openNewDialog(geoJsonFeatureBridge);
                        case 'flowSpeed':
                            const geoJsonFeatureFlowSpeed: ActiveDialogGeoJsonFeatureFlowSpeed = {
                                currentProperties: firstFeature.featurePropertiesData,
                                graphs: null,
                                id: firstFeature.id,
                                mapLayer: firstFeature.layer,
                                dataSource: firstFeature.source,
                                pinned: false,
                                preview: isPreview,
                                startPosition: {
                                    x: action.payload.pointX + 10,
                                    y: action.payload.pointY + 10
                                },
                                subType: GeoJsonSubTypes.FLOW_SPEED,
                                type: DialogTypes.GEO_JSON,
                                zIndex: state$.value.mapScene.activeDialogs.currentHighestPopupZIndex + 1
                            };

                            return openNewDialog(geoJsonFeatureFlowSpeed);
                        case 'wazeAlerts':
                            const geoJsonFeatureWazeAlert: ActiveDialogGeoJsonFeatureWazeAlertIncident = {
                                currentProperties: firstFeature.featurePropertiesData as unknown as GeoJsonPropertiesWaze,
                                hasLoadedData: false,
                                hectometrePost: null,
                                id: firstFeature.id,
                                isOpen: false,
                                lastChangeTimestamp: null,
                                lastSaveTimestamp: null,
                                loadingDataFailed: false,
                                mapLayer: firstFeature.layer,
                                dataSource: firstFeature.source,
                                newSubType: null,
                                pinned: false,
                                preview: isPreview,
                                savingStatus: null,
                                startPosition: {
                                    x: action.payload.pointX + 10,
                                    y: action.payload.pointY + 10
                                },
                                status: null,
                                subType: GeoJsonSubTypes.WAZE_ALERT_INCIDENT,
                                timeRemainingBeforeAutomaticClosure: null,
                                type: DialogTypes.GEO_JSON,
                                zIndex: state$.value.mapScene.activeDialogs.currentHighestPopupZIndex + 1
                            };

                            return openNewWazeDialog(geoJsonFeatureWazeAlert);
                        default:
                            const geoJsonFeatureGeneral: ActiveDialogGeoJsonFeatureGeneral = {
                                currentProperties: firstFeature.featurePropertiesData,
                                id: firstFeature.id,
                                mapLayer: firstFeature.layer,
                                dataSource: firstFeature.source,
                                pinned: false,
                                preview: isPreview,
                                startPosition: {
                                    x: action.payload.pointX + 10,
                                    y: action.payload.pointY + 10
                                },
                                subType: GeoJsonSubTypes.GENERAL,
                                type: DialogTypes.GEO_JSON,
                                zIndex: state$.value.mapScene.activeDialogs.currentHighestPopupZIndex + 1
                            };

                            return openNewDialog(geoJsonFeatureGeneral);
                    }
                case 'VectorTiles':
                    let subType: VectorLayerSubTypes.TRAVEL_TIME_FCD | VectorLayerSubTypes.TRAVEL_TIME_OTHER | VectorLayerSubTypes.TRAVEL_TIME_DRIPS;

                    switch (firstFeature.source) {
                        case 'travelTimeFcd':
                            subType = VectorLayerSubTypes.TRAVEL_TIME_FCD;
                            break;
                        case 'travelTimeDrips':
                            subType = VectorLayerSubTypes.TRAVEL_TIME_DRIPS;
                            break;
                        case 'travelTimeOther':
                        default:
                            subType = VectorLayerSubTypes.TRAVEL_TIME_OTHER;
                            break;
                    }

                    switch (firstFeature.source) {
                        case 'travelTimeFcd':
                        case 'travelTimeOther':
                        case 'travelTimeDrips':
                            const vectorTileFeatureTravelTime: ActiveDialogVectorLayerFeatureTravelTime = {
                                currentProperties: firstFeature.featurePropertiesData,
                                currentState: firstFeature.featureStateData,
                                graphs: null,
                                segmentHeatMap: null,
                                id: firstFeature.id,
                                mapLayer: firstFeature.layer,
                                dataSource: firstFeature.source,
                                pinned: false,
                                preview: isPreview,
                                startPosition: {
                                    x: action.payload.pointX + 10,
                                    y: action.payload.pointY + 10
                                },
                                subType: subType,
                                type: DialogTypes.VECTOR_LAYER,
                                zIndex: state$.value.mapScene.activeDialogs.currentHighestPopupZIndex + 1
                            };

                            return openNewDialog(vectorTileFeatureTravelTime);
                        default:
                            const vectorTileFeatureGeneral: ActiveDialogVectorLayerFeatureGeneral = {
                                currentProperties: firstFeature.featurePropertiesData,
                                currentState: firstFeature.featureStateData,
                                id: firstFeature.id,
                                mapLayer: firstFeature.layer,
                                dataSource: firstFeature.source,
                                pinned: false,
                                preview: isPreview,
                                startPosition: {
                                    x: action.payload.pointX + 10,
                                    y: action.payload.pointY + 10
                                },
                                subType: VectorLayerSubTypes.GENERAL,
                                type: DialogTypes.VECTOR_LAYER,
                                zIndex: state$.value.mapScene.activeDialogs.currentHighestPopupZIndex + 1
                            };

                            return openNewDialog(vectorTileFeatureGeneral);
                    }
            }
        })
    );

const removePreviewPopups: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf([showPreviewPopupForFeatures])),
        mergeMap((action) => {
            return state$.value.mapScene.activeDialogs.activeDialogs
                .filter(dialog => dialog.preview && !action.payload.clickedFeatures.map(f => f.id).includes(dialog.id))
                .map(dialog => closePopupWithID(dialog.id));
        })
    );
const removeNonPinnedPopups: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf([showPopupForFeatures])),
        mergeMap((action) => {
            return state$.value.mapScene.activeDialogs.activeDialogs
                .filter(dialog => !dialog.pinned && !action.payload.clickedFeatures.map(f => f.id).includes(dialog.id))
                .map(dialog => closePopupWithID(dialog.id));
        })
    );

const removePopupsWithMapSourceOnDeactivateMapSource: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(mapSceneDataDeactivateDataSource)),
        map((action) => removePopupsWithMapSource(action.payload.dataSource.id))
    );

const updateActiveDialogCountUnpinnedOnPinnedDialogsChanged: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf([
            updateActiveDialogPinStatus,
            removePopupsWithMapSource,
            removeUnpinnedActiveDialogs,
            openNewDialog,
            closeHighestIndexDialog,
            closePopupWithID,
            closeCurrentDialog
        ])),
        map(() => updateActiveDialogCountUnpinned())
    );

const removeUnpinnedActiveDialogsOnCloseAllUnpinnedPopups: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(closeAllUnpinnedPopups)),
        map(() => removeUnpinnedActiveDialogs())
    );

const updateActiveDialogPinStatusOnChangeDialogPinStatus: Epic = (action$) => action$.pipe(
    filter(isActionOf(changeDialogPinStatus)),
    map((action) => updateActiveDialogPinStatus(action.payload.index, action.payload.newStatus))
);

const dialogEpics: Epic = combineEpics(
    addNewDialogOnOpenNewDialog,
    closeCurrentDialogOnClosePopupWithID,
    closeHighestIndexDialogOnCloseHighestPriorityDialog,
    makeDialogProminentOnFocusOnDialog,
    makeDialogProminentOnDoubleOpenDialog,
    hideHoverPopups,
    openNewDialogOnShowPopupForFeatures,
    removePopupsWithMapSourceOnDeactivateMapSource,
    removeUnpinnedActiveDialogsOnCloseAllUnpinnedPopups,
    updateActiveDialogCountUnpinnedOnPinnedDialogsChanged,
    updateActiveDialogPinStatusOnChangeDialogPinStatus,
    graphEpicEffects,
    wazeEpics,
    removePreviewPopups,
    removeNonPinnedPopups
);

export default dialogEpics;
