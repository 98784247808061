import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {debounceTime, filter, flatMap, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {RootState} from '../../../reducer';
import {SearchFeature, SearchResult} from '../../../interfaces/SearchFeature';
import _ from 'lodash';
import {EMPTY, of} from 'rxjs';
import {storeNewSearchResult, storeNewSearchValue} from '../../../scenes/MapScene/actions/reducers/filterAndSearch';
import {
    mapSceneDataClearMapFilteredData,
    mapSceneDataUpdateDataSourceFilteredMapData
} from '../../../scenes/MapScene/actions/reducers/data';
import {FeatureCollection} from 'geojson';

const searchFilterFeaturesOnStoreNewSearchValue: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(storeNewSearchValue)),
        debounceTime(1000),
        map(({payload: {searchValue}}) => {
            const lowedQuery = searchValue.toLowerCase();

            if (lowedQuery.length === 0) {
                return storeNewSearchResult(null);
            }

            const searchFeaturesFound: SearchResult[] = [];
            for (const sourcesKey in state$.value.mapScene.data.sources) {
                if (!state$.value.mapScene.data.sources.hasOwnProperty(sourcesKey)) {
                    continue;
                }

                const dataSource = state$.value.mapScene.data.sources[sourcesKey];

                if (dataSource.isActive) {
                    const sourceMatchedFeatures = _.filter(dataSource.searchFeatures, (item: SearchFeature) => {
                        const stringSearchValue: string = typeof item.searchValue !== 'string' ?
                            item.searchValue.toString()
                            : item.searchValue;
                        return stringSearchValue.toLowerCase().indexOf(lowedQuery) > -1;
                    });

                    const searchResult: SearchResult = {
                        id: dataSource.id,
                        name: dataSource.name,
                        results: _.take(sourceMatchedFeatures, 150)
                    };
                    searchFeaturesFound.push(searchResult);
                }
            }

            return storeNewSearchResult({
                identifier: Date.now(),
                searchResults: searchFeaturesFound
            });
        })
    );

const updateFilteredMapDataOnStoreNewSearchResult: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(storeNewSearchResult)),
        filter((action) => action.payload.searchResults !== null),
        flatMap(({payload: {searchResults}}) => {

            const layerSearchResults = searchResults!.searchResults;
            return of(...layerSearchResults)
                .pipe(
                    map((searchResultSet: SearchResult) => {
                        const dataSource = state$.value.mapScene.data.sources[searchResultSet.id];
                        const filterIDs = _.map(searchResultSet.results, 'id');

                        if (dataSource.dataLoadMethod === 'GeoJSON') {
                            const filteredFeatures = _.cloneDeep(
                                state$.value.mapScene.data.sources[searchResultSet.id].layerMapData!.features as FeatureCollection['features']
                            ).filter((feature) => filterIDs.indexOf(feature.properties && feature.properties.id) > -1);

                            return mapSceneDataUpdateDataSourceFilteredMapData(searchResultSet.id, {
                                features: filteredFeatures,
                                type: 'FeatureCollection'
                            });
                        } else if (dataSource.dataLoadMethod === 'VectorTiles') {
                            const layerMapData = _.cloneDeep(dataSource.layerMapData);
                            let filtered = {};
                            if (layerMapData) {
                                filtered = Object.keys(layerMapData)
                                    .filter((key) => {
                                        return filterIDs.includes(parseInt(key, 10));
                                    })
                                    .reduce<Record<string, unknown>>((obj, key) => {
                                        obj[key] = layerMapData[key];
                                        return obj;
                                    }, {});
                            }

                            return mapSceneDataUpdateDataSourceFilteredMapData(searchResultSet.id, filtered);
                        }

                        return EMPTY;
                    })
                );
        })
    );

const clearFilteredMapDataOnStoreNewSearchResultEmpty: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(storeNewSearchResult)),
        filter((action) => action.payload.searchResults === null),
        map(() => mapSceneDataClearMapFilteredData())
    );

const searchPaneEpics: Epic = combineEpics(
    clearFilteredMapDataOnStoreNewSearchResultEmpty,
    searchFilterFeaturesOnStoreNewSearchValue,
    updateFilteredMapDataOnStoreNewSearchResult
);

export default searchPaneEpics;
