import {ActionType, createAction} from 'typesafe-actions';
import {LegendDTO} from '../../../generated/ViewerApiClient';
import {updateMapIsReadyStatus, updateMapViewport} from './mapBoxComponent';
import {
    mapSceneOverviewTableChangeOverviewTableSource,
    setNewVisibleOverviewAllTable,
    updateOverviewTableHeight
} from './overviewTableComponent';
import {mapSceneSettingsPanelSetNewTravelTimeLineOffset} from './settingsPane';
import {
    setNewDfineFilterStatusOnlyRvmNetwork,
    setNewFdVerifiedTrafficJamsFilterStatus,
    setNewPrefixFilterForLayer,
    setNewWazeAlertFilterStatusSet,
    setNewWazeAlertNdwKnownFilterStatus
} from './reducers/filterAndSearch';
import {HighlightContextMenu, SidebarContentPanes} from '../reducers/generalReducer';
import {MapFeature} from '../../../interfaces/MapFeature';

export type DIALOG_GRAPHS = 'travelTime' | 'flowSpeed';

export const changeDialogPinStatus = createAction(
    'CHANGE_DIALOG_PIN_STATUS',
    (action) => (index: number, newStatus: boolean) => action({index, newStatus})
);

export const closeAllUnpinnedPopups = createAction(
    'CLOSE_ALL_UNPINNED_POPUPS',
    (action) => () => action({})
);

export const closeHighestPriorityDialog = createAction(
    'CLOSE_HIGHEST_PRIORITY_DIALOG',
    (action) => () => action({})
);

export const focusOnDialog = createAction(
    'FOCUS_ON_DIALOG',
    (action) => (index: number) => action({index})
);

export const flyToLocation = createAction(
    'FLY_TO_LOCATION',
    (action) => (latitude: number | null, longitude: number | null, zoom: number | null) => action({
        latitude,
        longitude,
        zoom
    })
);

export const closePopupWithID = createAction(
    'CLOSE_POPUP_WITH_ID',
    (action) => (id: string | number) => action({id})
);

export const storeLegendData = createAction(
    'STORE_LEGEND_DATA',
    (action) => (data: LegendDTO) => action({data})
);

export const storeMapSceneContainerViewPartHeight = createAction(
    'STORE_MAP_SCENE_CONTAINER_VIEW_PART_HEIGHT',
    (action) => (containerViewPartHeight: number) => action({containerViewPartHeight})
);

export const updateSidebarStatus = createAction(
    'UPDATE_SIDEBAR_STATUS',
    (action) => (newVisibleTab: SidebarContentPanes | null) => action({newVisibleTab})
);

export const mapSceneGeneralToggleMapMovementAvailable = createAction(
    'MAP_SCENE_GENERAL_TOGGLE_MAP_MOVEMENT_AVAILABLE'
);

export const mapSceneGeneralUpdateHighlightContextMenu = createAction(
    'MAP_SCENE_GENERAL_UPDATE_HIGHLIGHT_CONTEXT_MENU',
    (action) => (highlightContextMenu: HighlightContextMenu | null) => action({highlightContextMenu})
);

export const mapSceneGeneralUpdateHighlightFeatures = createAction(
    'MAP_SCENE_GENERAL_UPDATE_HIGHLIGHT_FEATURES',
    (action) => (features: MapFeature[] | null) => action({features})
);

export const mapSceneNumberOfFeaturesVisibleOnMap = createAction(
    'MAP_SCENE_NUMBER_OF_FEATURES_VISIBLE_ON_MAP',
    action => (numberOfFeaturesVisible: number) => action({numberOfFeaturesVisible}));

export type MapSceneMapActionTypes = ActionType<typeof flyToLocation> |
    ActionType<typeof storeLegendData> |
    ActionType<typeof setNewVisibleOverviewAllTable> |
    ActionType<typeof mapSceneOverviewTableChangeOverviewTableSource> |
    ActionType<typeof mapSceneSettingsPanelSetNewTravelTimeLineOffset> |
    ActionType<typeof setNewWazeAlertNdwKnownFilterStatus> |
    ActionType<typeof setNewWazeAlertFilterStatusSet> |
    ActionType<typeof storeMapSceneContainerViewPartHeight> |
    ActionType<typeof updateMapViewport> |
    ActionType<typeof updateMapIsReadyStatus> |
    ActionType<typeof updateSidebarStatus> |
    ActionType<typeof updateOverviewTableHeight> |
    ActionType<typeof setNewDfineFilterStatusOnlyRvmNetwork> |
    ActionType<typeof setNewPrefixFilterForLayer> |
    ActionType<typeof mapSceneGeneralToggleMapMovementAvailable> |
    ActionType<typeof mapSceneGeneralUpdateHighlightContextMenu> |
    ActionType<typeof mapSceneGeneralUpdateHighlightFeatures> |
    ActionType<typeof setNewFdVerifiedTrafficJamsFilterStatus> |
    ActionType<typeof mapSceneNumberOfFeaturesVisibleOnMap>;
