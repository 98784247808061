import {Props} from './Props';
import React from 'react';

export const DialogSchoolZones: React.FunctionComponent<Props> = ({properties}) => {

    return <>
        <table>
            <tbody>
                <tr>
                    <th>Schoolnaam</th>
                    <td>{properties.name}</td>
                </tr>
                <tr>
                    <th>Heeft zone?</th>
                    <td>{properties.hasZone ? 'Ja' : 'Nee'}</td>
                </tr>
            </tbody>
        </table>
    </>;
};
