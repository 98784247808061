import {createAction} from 'typesafe-actions';
import {SCENES} from './scenes';

export const viewerApplicationBooted = createAction(
    'VIEWER_APPLICATION_BOOTED',
    (action) => () => action({})
);

export const viewerApplicationSceneChanged = createAction(
    'VIEWER_APPLICATION_SCENE_CHANGED',
    (action) => (scene: SCENES) => action({scene})
);

export const viewerApplicationNavigationRequested = createAction(
    'VIEWER_APPLICATION_NAVIGATION_REQUESTED',
    (action) => (route: string) => action({route})
);

export const viewerApplicationTestAction = createAction(
    'VIEWER_APPLICATION_TEST_ACTION',
    (action) => () => action({})
);
