import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    item: {
        ':hover': {
            backgroundColor: '#F8981D',
            color: 'white'
        },
        'cursor': 'pointer'
    }
});

export default styles;
