import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';

class DialogHere extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {currentProperties, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{currentProperties.id}</td>
                    </tr>}
                    <tr>
                        <th>Data bron</th>
                        <td>{currentProperties.dataType}</td>
                    </tr>
                    <tr>
                        <th>Tijdstip</th>
                        <td>
                            <DateTimeFormatted dateTime={currentProperties.timestamp}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>;
    }

}

export default DialogHere;
