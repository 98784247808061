import {ActionType, createAction} from 'typesafe-actions';
import {HistoricalDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedHistoricalData = createAction(
    'VIEWER_API_SERVICE_RECEIVED_HISTORICAL_DATA',
    (action) => (
        dataType: 'flowSpeed' | 'travelTime',
        mapSourceID: string,
        featureID: number | string,
        locationID: string,
        timestamp: number,
        data: HistoricalDTO
    ) => action({dataType, mapSourceID, featureID, locationID, timestamp, data})
);

export const viewerApiServiceReceivingHistoricalDataFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_HISTORICAL_DATA_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingHistoricalDataFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_HISTORICAL_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type HistoricalDataDataActionTypes = ActionType<typeof viewerApiServiceReceivedHistoricalData> |
    ActionType<typeof viewerApiServiceReceivingHistoricalDataFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceReceivingHistoricalDataFailedWithUnexpectedError>;
