import React, {ReactElement} from 'react';
import AgGrid from '../../../AgGrid';
import {DateCellRenderer} from '../../../AgGrid/components/DateCellRenderer';
import {Props} from './Props';
import {handleRowClick} from '../TableService';
import {SituationFeature} from '../../../../interfaces/SituationFeature';
import {ICellRendererParams} from '@ag-grid-community/core';
import {mapToIconName} from '../../../../services/SituationIconService';

class OverviewAllSituationOnda extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {data, showRecordIds, suppressRowClickSelection} = props;
        return <React.Fragment>
            <AgGrid<SituationFeature>
                columns={[
                    {
                        cellRenderer: (columnData: ICellRendererParams) => {
                            return `<img src="/assets/legend-icons/${mapToIconName(columnData.data.properties)}.svg" alt="Icoon"/>`;
                        },
                        valueGetter: item => item.data.properties,
                        headerName: '',
                        maxWidth: 50,
                        resizable: false
                    },
                    {
                        valueGetter: item => item.data.properties.id,
                        headerName: 'ID',
                        hide: !showRecordIds
                    },
                    {
                        valueGetter: item => item.data.properties.reference,
                        headerName: 'Type',
                        maxWidth: 75
                    },
                    {
                        cellRenderer: DateCellRenderer,
                        valueGetter: item => item.data.properties.overallStartTime,
                        headerName: 'Tijd',
                        minWidth: 130,
                        sort: 'desc'
                    },
                    {
                        cellRenderer: (columnData: ICellRendererParams) => {
                            if (columnData.value && columnData.value.length) {
                                return columnData.value;
                            }
                            return '-';
                        },
                        valueGetter: item => item.data.properties.subType,
                        headerName: 'Incident type'
                    },
                    {
                        valueGetter: item => item.data.properties.source,
                        headerName: 'Melder'
                    },
                    {
                        cellRenderer: (columnData: ICellRendererParams) => {
                            return `${columnData.data.properties.roadNumberAndSide} ${columnData.data.properties.primaryLocationDescription} - ${columnData.data.properties.primaryLocationKilometers}`;
                        },
                        headerName: 'Locatie'
                    }
                ]}
                data={data}
                enableColResize={true}
                enableFilter={true}
                enableSorting={true}
                rowSelection={'single'}
                suppressScrollOnNewData={true}
                onRowClicked={(item) => handleRowClick(this.props, item)}
                suppressRowClickSelection={suppressRowClickSelection}
                getRowStyle={(params) => {
                    if (params.data && params.data.source && params.data.source === 'ANWB_IM') {
                        return {
                            'background-color': '#98FB98'
                        };
                    }

                    return {
                        'background-color': 'white'
                    };
                }}
            />
        </React.Fragment>;
    }

}

export default OverviewAllSituationOnda;
