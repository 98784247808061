import {ICellRendererParams, MenuItemDef} from '@ag-grid-community/core';
import React, {ReactElement} from 'react';
import {WAZE_ALERT_ITEM_SUB_TYPES, WAZE_ALERT_ITEM_TYPES} from '../../../../interfaces/WazeAlertItemStatus';
import {MapFilters} from '../../../../scenes/MapScene/reducers/filterAndSearchReducer';
import {
    convertWazeTypeAndSubtypeToTranslatedEventType,
    translateFrontOfficeEventStatus
} from '../../../../services/Utilities';
import AgGrid from '../../../AgGrid';
import {TimeCellRenderer} from '../../../AgGrid/components/DateCellRenderer';
import {MessageAgeCellRenderer} from '../../../AgGrid/components/MessageAgeCellRenderer';
import {FilterSettings} from '../../../AgGrid/Props';
import {Props} from './Props';
import {State} from './State';
import {FrontOfficeEventStatus} from '../../../../constants';
import {WazeFeature} from '../../../../interfaces/WazeFeature';
import {handleRowClick} from '../TableService';

class OverviewAllWazeAlertIncident extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            filters: null
        };
    }

    componentDidMount(): void {
        this.updateGridFilters(this.props.mapFilter);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const props: Readonly<Props> = this.props;
        const {mapFilter} = props;

        if (mapFilter.filterTimeStamp !== prevProps.mapFilter.filterTimeStamp) {
            this.updateGridFilters(mapFilter);
        }
    }

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {data, wazeDataTypes, showRecordIds, suppressRowClickSelection} = props;
        const state: Readonly<State> = this.state;
        const {filters} = state;

        return <React.Fragment>
            <AgGrid<WazeFeature>
                columns={[
                    {
                        valueGetter: item => item.data.properties.id as keyof WazeFeature,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: [true, false]
                        },
                        hide: !showRecordIds
                    },
                    {
                        cellRenderer: (columnData: ICellRendererParams) => {
                            return `<img src="/assets/legend-icons/ndw-${columnData.value}-25.svg" alt="Icoon"/>`;
                        },
                        valueGetter: item => item.data.properties.icon,
                        headerName: '',
                        maxWidth: 50,
                        resizable: false
                    },
                    {
                        cellRenderer: (columnData: ICellRendererParams) =>
                            convertWazeTypeAndSubtypeToTranslatedEventType(columnData.data.properties.type, columnData.data.properties.subType),
                        valueGetter: item => item.data.properties.subType,
                        headerName: 'Type'
                    },
                    {
                        valueGetter: item => item.data.properties.street,
                        headerName: 'Locatie (Waze)'
                    },
                    {
                        headerName: 'Locatie (NDW)',
                        valueGetter: (columData) => {
                            return `${columData.data.properties.hectometrePostRoad} ${columData.data.properties.hectometrePost}`;
                        }
                    },
                    {
                        valueGetter: item => item.data.properties.location,
                        headerName: 'Locatie (WVL)'
                    },
                    {
                        cellRenderer: (columnData: ICellRendererParams) => translateFrontOfficeEventStatus(columnData.value),
                        valueGetter: item => item.data.properties.status,
                        headerName: 'Status'
                    },
                    {
                        cellRenderer: TimeCellRenderer,
                        valueGetter: item => item.data.properties.publicationTime,
                        headerName: 'Starttijd',
                        minWidth: 130,
                        sort: 'desc'
                    },
                    {
                        cellRenderer: MessageAgeCellRenderer,
                        valueGetter: item => item.data.properties.publicationTime,
                        headerName: 'Duur incident'
                    },
                    {
                        valueGetter: item => item.data.properties.thumbsUp,
                        headerName: 'Bevestigingen',
                        maxWidth: 130
                    },
                    {
                        cellRenderer: MessageAgeCellRenderer,
                        valueGetter: item => item.data.properties.lastConfirmedTime,
                        headerName: 'Laatste bevestiging'
                    },
                    {
                        cellRenderer: (columnData: ICellRendererParams) => {
                            return columnData.value === true ?
                                '<i class="fas fa-check" style="color: #28a745"/>' :
                                '<i style="color: #dc3545" class="fas fa-times"/>';
                        },
                        valueGetter: item => item.data.properties.ndw,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            values: [true, false]
                        },
                        headerName: 'NDW Bekend'
                    }
                ]}
                data={data}
                enableColResize={true}
                enableFilter={true}
                enableSorting={true}
                filters={filters ? filters : undefined}
                rowSelection={'single'}
                suppressScrollOnNewData={true}
                onRowClicked={(item) => handleRowClick(props, item)}
                autoRedrawTableColumns={true}
                suppressRowClickSelection={suppressRowClickSelection}
                getContextMenuItems={(params) => {
                    if (!params.node) {
                        return [];
                    }

                    const subMenuItemsType: MenuItemDef[] = wazeDataTypes.map<MenuItemDef>((wazeDataType) => {
                        return {
                            action: () => this.handleContextMenuItemType(
                                params.node!.data.id,
                                wazeDataType.type,
                                wazeDataType.subType
                            ),
                            icon: `<img src="${wazeDataType.icon}" alt="Icoon" style="height: 20px; width: 20px;"/>`,
                            name: wazeDataType.name
                        };
                    });

                    return [
                        {
                            disabled: true,
                            icon: `<img src="/assets/legend-icons/ndw-${params.node.data.icon}-25.svg" alt="Icoon"/>`,
                            name: `Melding weggebruiker: ${params.node.data.street}, ${params.node.data.hectometrePost}`
                        },
                        {
                            disabled: true,
                            name: `Huidige status: ${translateFrontOfficeEventStatus(params.node.data.status)}`
                        },
                        {
                            name: 'Status aanpassen',
                            subMenu: [
                                {
                                    disabled: true,
                                    icon: params.node.data.status === 'NONE' ? '<i class="fas fa-check" style="color: #28a745"/>' : '',
                                    name: 'Niet beoordeeld'
                                },
                                {
                                    action: () => this.handleContextMenuItemStatusChange(
                                        params.node!.data.id,
                                        FrontOfficeEventStatus.noAction
                                    ),
                                    icon: params.node.data.status === 'NO_ACTION' ? '<i class="fas fa-check" style="color: #28a745"/>' : '',
                                    name: 'Niets aan de hand'
                                },
                                {
                                    action: () => this.handleContextMenuItemStatusChange(
                                        params.node!.data.id,
                                        FrontOfficeEventStatus.selfAction
                                    ),
                                    icon: params.node.data.status === 'SELF_ACTION' ? '<i class="fas fa-check" style="color: #28a745"/>' : '',
                                    name: 'Zelf laten oplossen'
                                },
                                {
                                    action: () => this.handleContextMenuItemStatusChange(
                                        params.node!.data.id,
                                        FrontOfficeEventStatus.knowMessage
                                    ),
                                    icon: params.node.data.status === 'KNOW_MESSAGE' ? '<i class="fas fa-check" style="color: #28a745"/>' : '',
                                    name: 'Reeds bekend'
                                },
                                {
                                    action: () => this.handleContextMenuItemStatusChange(
                                        params.node!.data.id,
                                        FrontOfficeEventStatus.startAction
                                    ),
                                    icon: params.node.data.status === 'START_ACTION' ? '<i class="fas fa-check" style="color: #28a745"/>' : '',
                                    name: 'IM Opstarten'
                                }
                            ]
                        },
                        {
                            name: 'Type aanpassen',
                            subMenu: subMenuItemsType
                        }
                    ];
                }}
            />
        </React.Fragment>;
    }

    private updateGridFilters = (mapFilters: MapFilters) => {
        const filters: FilterSettings<WazeFeature> = {
            filters: [],
            identifier: mapFilters.filterTimeStamp
        };

        if (mapFilters.wazeAlertStatusSet) {
            filters.filters.push({
                field: 'properties.status' as keyof WazeFeature,
                value: ['NONE']
            });
        }
        if (mapFilters.wazeAlertNdwKnown) {
            filters.filters.push({
                field: 'properties.ndw' as keyof WazeFeature,
                value: ['false']
            });
        }

        this.setState({
            filters
        });
    };

    private handleContextMenuItemStatusChange = (id: string, status: FrontOfficeEventStatus) => {
        const props: Readonly<Props> = this.props;
        props.onSaveWazeAlertData(id, '', status, undefined, undefined);
    };

    private handleContextMenuItemType = (id: string, type: WAZE_ALERT_ITEM_TYPES, subType: WAZE_ALERT_ITEM_SUB_TYPES) => {
        const props: Readonly<Props> = this.props;
        props.onSaveWazeAlertData(id, '', undefined, type, subType);
    };
}

export default OverviewAllWazeAlertIncident;
