import {
    mapSceneDataClearFilteredDataForDataSource,
    mapSceneDataDeactivateDataSource,
    mapSceneDataMarkDataSourceAsOutdated,
    MapSceneDataSourcesActionTypes,
    mapSceneDataSourceUpdateMissed,
    mapSceneDataUpdateBaseDataSource,
    mapSceneDataUpdateDataSourceFilteredMapData,
    mapSceneDataUpdateDataSourceFromWebsocket,
    mapSceneDataUpdateGeoJSONDataSource,
    mapSceneDataUpdateSearchFeatures,
    mapSceneDataUpdateVectorLayerDataMapSource
} from '../actions/reducers/data';
import {MapLayer} from '../../../interfaces/MapLayer';
import {getType} from 'typesafe-actions';
import {
    DATA_SOURCE_STATUS_ACTIVE,
    DATA_SOURCE_STATUS_LOADING_DATA,
    DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC,
    DataSource,
    DataSourceGeoJson,
    DataSourceVectorTiles
} from '../../../interfaces/DataSource';
import _ from 'lodash';
import {Reducer} from 'redux';
import {
    mapSceneMapboxLayersActivateDataSource,
    mapSceneMapboxLayersUpdateMapLayerConfiguration
} from '../actions/reducers/mapboxLayers';
import {Feature, GeoJsonProperties, Geometry} from 'geojson';

export interface LayersState {
    [key: string]: MapLayer;
}

export interface DataSourceState {
    [key: string]: DataSource;
}

export interface MapSceneDataSourcesReducerState {
    sources: DataSourceState;
}

const initialState: MapSceneDataSourcesReducerState = {
    sources: {}
};

const dataReducer: Reducer<MapSceneDataSourcesReducerState, MapSceneDataSourcesActionTypes> =
    (state: MapSceneDataSourcesReducerState = initialState, action: MapSceneDataSourcesActionTypes): MapSceneDataSourcesReducerState => {
        switch (action.type) {

            case getType(mapSceneMapboxLayersActivateDataSource):
                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.mapSource.id]: {
                            ...state.sources[action.payload.mapSource.id],
                            isActive: true,
                            status: state.sources[action.payload.mapSource.id].loadBaseData
                                ? DATA_SOURCE_STATUS_LOADING_DATA : DATA_SOURCE_STATUS_ACTIVE
                        }
                    }
                };
            case getType(mapSceneDataClearFilteredDataForDataSource):
                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.dataSourceId]: {
                            ...state.sources[action.payload.dataSourceId],
                            currentIdentifier: Date.now(),
                            layerFilteredMapData: null
                        }
                    }
                };
            case getType(mapSceneDataDeactivateDataSource):
                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.dataSource.id]: {
                            ...state.sources[action.payload.dataSource.id],
                            isActive: false
                        }
                    }
                };
            case getType(mapSceneMapboxLayersUpdateMapLayerConfiguration):
                return {
                    ...state,
                    sources: action.payload.dataSources
                };
            case getType(mapSceneDataUpdateBaseDataSource):
                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.dataSource.id]: {
                            ...state.sources[action.payload.dataSource.id] as DataSourceGeoJson,
                            currentIdentifier: Date.now(),
                            lastUpdateReceived: Date.now(),
                            lastSnapshotUpdateTimestamp: action.payload.data.identifier,
                            layerMapData: action.payload.data,
                            sourceUpdateIdentifier: null,
                            status: DATA_SOURCE_STATUS_ACTIVE
                        }
                    }
                };
            case getType(mapSceneDataUpdateGeoJSONDataSource):
                const {data, dataSource: actionPayloadDataSource} = action.payload;
                const dataSource = state.sources[actionPayloadDataSource.id];

                if (dataSource.dataLoadMethod !== 'GeoJSON' || !dataSource.layerMapData) {
                    return {
                        ...state
                    };
                }

                if (data.created && data.created.length) {
                    dataSource.layerMapData.features = _.values(
                        _.merge(
                            _.keyBy(dataSource.layerMapData.features, 'properties.id'),
                            _.keyBy(data.created, 'properties.id')
                        )
                    );
                }

                if (data.removed && data.removed.length) {
                    _.remove(dataSource.layerMapData.features, (item) => item.properties && data.removed.indexOf(item.properties.id) > -1);
                }

                if (data.updated && data.updated.length) {
                    dataSource.layerMapData.features = _(dataSource.layerMapData.features)
                        .concat(data.updated)
                        .groupBy('properties.id')
                        .map(toCurrentFeatureWithIncrementApplied)
                        .value();
                }
                const updatedSourceState: DataSourceGeoJson = {
                    ...state.sources[actionPayloadDataSource.id] as DataSourceGeoJson,
                    currentIdentifier: Date.now(),
                    lastUpdateReceived: Date.now(),
                    layerMapData: dataSource.layerMapData,
                    sourceUpdateIdentifier: data.identifier,
                    status: DATA_SOURCE_STATUS_ACTIVE
                };

                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [actionPayloadDataSource.id]: updatedSourceState
                    }
                };
            case getType(mapSceneDataUpdateDataSourceFromWebsocket):
                if (!state.sources.hasOwnProperty(action.payload.dataSource)) {
                    return {
                        ...state
                    };
                }

                const mapSourceWebsocket = state.sources[action.payload.dataSource];
                if (!mapSourceWebsocket.layerMapData) {
                    return {
                        ...state
                    };
                }

                if (mapSourceWebsocket.dataLoadMethod !== 'GeoJSON') {
                    return {
                        ...state
                    };
                }

                if (action.payload.websocketUpdateMessage.created && action.payload.websocketUpdateMessage.created.length) {
                    mapSourceWebsocket.layerMapData.features = _.values(
                        _.merge(
                            _.keyBy(mapSourceWebsocket.layerMapData.features, 'properties.id'),
                            _.keyBy(action.payload.websocketUpdateMessage.created, 'properties.id')
                        )
                    );
                }

                if (action.payload.websocketUpdateMessage.removed && action.payload.websocketUpdateMessage.removed.length) {
                    mapSourceWebsocket.layerMapData.features = mapSourceWebsocket.layerMapData.features
                        .filter(feature => !action.payload.websocketUpdateMessage.removed.includes(feature.id as string));
                }

                if (action.payload.websocketUpdateMessage.updated && action.payload.websocketUpdateMessage.updated.length) {
                    mapSourceWebsocket.layerMapData.features = _(mapSourceWebsocket.layerMapData.features)
                        .concat(action.payload.websocketUpdateMessage.updated)
                        .groupBy('properties.id')
                        .map(toCurrentFeatureWithIncrementApplied)
                        .value();
                }

                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.dataSource]: {
                            ...state.sources[action.payload.dataSource] as DataSourceGeoJson,
                            currentIdentifier: Date.now(),
                            lastUpdateReceived: Date.now(),
                            layerMapData: mapSourceWebsocket.layerMapData,
                            status: DATA_SOURCE_STATUS_ACTIVE
                        }
                    }
                };
            case getType(mapSceneDataSourceUpdateMissed):
                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.dataSource.id]: {
                            ...state.sources[action.payload.dataSource.id],
                            status: DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC
                        }
                    }
                };
            case getType(mapSceneDataMarkDataSourceAsOutdated):
                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.sourceId]: {
                            ...state.sources[action.payload.sourceId],
                            status: DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC
                        }
                    }
                };
            case getType(mapSceneDataUpdateVectorLayerDataMapSource):
                const {data: updatedVectorLayerData, dataSource: dataSourcePayload} = action.payload;
                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [dataSourcePayload.id]: {
                            ...state.sources[dataSourcePayload.id] as DataSourceVectorTiles,
                            currentIdentifier: Date.now(),
                            lastUpdateReceived: Date.now(),
                            layerMapData: updatedVectorLayerData,
                            status: DATA_SOURCE_STATUS_ACTIVE
                        }
                    }
                };
            case getType(mapSceneDataUpdateSearchFeatures):
                if (!state.sources[action.payload.dataSourceID]) {
                    return state;
                }

                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.dataSourceID]: {
                            ...state.sources[action.payload.dataSourceID],
                            searchFeatures: action.payload.searchFeatures
                        }
                    }
                };
            case getType(mapSceneDataUpdateDataSourceFilteredMapData):
                return {
                    ...state,
                    sources: {
                        ...state.sources,
                        [action.payload.dataSourceID]: {
                            ...state.sources[action.payload.dataSourceID],
                            currentIdentifier: Date.now(),
                            layerFilteredMapData: action.payload.mapFilteredData
                        }
                    }
                };
            default:
                return state;
        }
    };


const toCurrentFeatureWithIncrementApplied: (data: Feature<Geometry, GeoJsonProperties>[]) => Feature<Geometry, GeoJsonProperties>
    = ([feature, update]) => _.mergeWith(feature, update, (_f, u) => (_.isArray(u) ? u : undefined));

export default dataReducer;
