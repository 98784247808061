import React, {ReactElement} from 'react';
import {Props} from './Props';
import {css} from 'aphrodite';
import styles from './styles';
import StyledButton from '../../../../../../components/StyledButton';
import {AreaChart} from 'react-chartkick';

class TrajectorySummedPane extends React.Component<Props> {

    clickedOnDeleteButton = (id: string): void => {
        const props: Readonly<Props> = this.props;
        props.removeTrajectoryFromSelection(id);
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {trajectorySummedData, trajectorySummedSelection, trajectorySummedSelectionEnabled} = props;
        const trajectories = trajectorySummedSelection.join(', ');

        return <React.Fragment>

            <p>Selecteer meerdere FCD trajecten om zo een gemiddelde reistijd en snelheid van de door u gekozen
                trajecten in te zien.</p>

            <StyledButton
                onPress={props.toggleTrajectorySummedSelectionEnabled}
                title={trajectorySummedSelectionEnabled ? 'Voeg trajecten toe aan grafiek' : 'Open trajecten in popups'}
            />

            {trajectorySummedSelection.length > 0 && <React.Fragment>
                <p className={css(styles.title)}>Geselecteerde trajecten</p>
                <table className={css(styles.selectedTrajectoriesTable)}>
                    <tbody>
                        {trajectorySummedSelection.map((item: string | number, index: number) =>
                            <tr key={index} className={css(styles.selectedTrajectoriesTableRow)}>
                                <td>{item}</td>
                                <td>
                                    <span className={css(styles.trashButton)} onClick={() => this.clickedOnDeleteButton(item as string)}>
                                        <i className="fas fa-trash"/>
                                    </span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </React.Fragment>}

            {trajectorySummedSelection.length === 0 &&
            <p className={css(styles.noTrajectoriesSelected)}>Er zijn geen trajecten geselecteerd</p>}

            {trajectorySummedData && <React.Fragment>
                <hr/>
                <p className={css(styles.title)}>Reistijd</p>
                <AreaChart
                    colors={['#F8981D']}
                    data={trajectorySummedData.travelTime}
                    download={`Reistijden (${trajectories}).png`}
                />

                <p className={css(styles.title)}>Snelheid</p>
                <AreaChart
                    colors={['#F8981D']}
                    data={trajectorySummedData.velocity}
                    download={`Snelheid (${trajectories}).png`}
                />
            </React.Fragment>}

        </React.Fragment>;
    }

}

export default TrajectorySummedPane;
