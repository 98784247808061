import React, {useMemo} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {OtmConstraint} from './components/OtmConstraint';

export const DialogRoadWorkPlannedOtm: React.FunctionComponent<Props> = ({properties}) => {

    const relatedSituationRecordIds: string[] | null = useMemo<string[] | null>(() => {
        if (!properties.relatedSituationRecordIds) {
            return null;
        }

        return JSON.parse(properties.relatedSituationRecordIds as unknown as string);
    }, [properties.relatedSituationRecordIds]);

    return <>
        <table>
            <tbody>
                <tr>
                    <th>ID</th>
                    <td>{properties.id}</td>
                </tr>
                <tr>
                    <th>Situatie Record ID</th>
                    <td>{properties.situationRecordId}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{properties.type}</td>
                </tr>
                <tr>
                    <th>Tijdstempel aangemaakt</th>
                    <td>
                        {properties.creationDate && <DateTimeFormatted dateTime={properties.creationDate}/>}
                        {!properties.creationDate && <div>Tijdstempel aangemaakt niet aanwezig</div>}
                    </td>
                </tr>
                <tr>
                    <th>Tijdstempel laatst gewijzigd</th>
                    <td>
                        {properties.lastModified && <DateTimeFormatted dateTime={properties.lastModified}/>}
                        {!properties.lastModified && <span>Tijdstempel laatst gewijzigd niet aanwezig</span>}
                    </td>
                </tr>
            </tbody>
        </table>

        <br/>

        <h3>Gerelateerde situatie record IDs</h3>
        {!relatedSituationRecordIds && <p>Gerelateerde situatie record IDs waarde niet in bron data</p>}
        {relatedSituationRecordIds && relatedSituationRecordIds.length === 0 &&
            <p>Gerelateerde situatie record IDs bevat geen waardes</p>}
        <ul>
            {(relatedSituationRecordIds || []).map((relatedSituationRecordId: string) =>
                <li key={relatedSituationRecordId}>{relatedSituationRecordId}</li>)}
        </ul>

        <br/>

        <h3>Constraints</h3>
        {properties.otmConstraint && <OtmConstraint
            constraint={JSON.parse(properties.otmConstraint)}
            level={0}
        />}
        {!properties.otmConstraint && <p>Geen constraints</p>}
    </>;
};
