import {StyleSheet} from 'aphrodite';

export const colorBlindnessStyles = StyleSheet.create({
    highlightedRow: {
        backgroundColor: '#ff7f00',
        color: 'white'
    }
});

export const styles = StyleSheet.create({
    extraDetails: {
        fontSize: '14px',
        fontWeight: 'bold'
    },
    highlightedRow: {
        backgroundColor: 'rgba(248, 152, 29, 0.2)'
    },
    rowContainer: {
        padding: '10px'
    },
    rowContainerClickAvailable: {
        ':hover': {
            backgroundColor: 'rgba(248, 152, 29, 1)',
            color: 'white'
        },
        'cursor': 'pointer'
    },
    timestamp: {
        fontSize: '12px'
    },
    titleRow: {
        fontSize: '14px',
        fontWeight: 'bold'
    }
});
