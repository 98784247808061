import {FeatureCollection} from 'geojson';
import MapLayerModel from './models/MapLayerModel';

export const mapLayer: MapLayerModel = new MapLayerModel();

export type StreamableSnapshot = GeoJsonSnapshotEvent

export interface HttpSnapshot {
    data: GeoJsonSnapshotEvent
}

interface GeoJsonSnapshotEvent {
    timestamp: number;
    data: FeatureCollection;
    type: 'GeoJsonSnapshotEvent';
}