import {createAction} from 'typesafe-actions';

export const activatedTrafficMessagesPane = createAction(
    'ACTIVATED_TRAFFIC_MESSAGE_PANE',
    (action) => () => action({})
);

export const deactivatedTrafficMessagesPane = createAction(
    'DEACTIVATED_TRAFFIC_MESSAGES_PANE',
    (action) => () => action({})
);
