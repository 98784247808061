import React from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {Props} from './Props';

const NavbarItemCollection: React.FunctionComponent<Props> = ({alignNavbar, children}) => {

    return <ul className={css(styles.navigationBar, alignNavbar === 'RIGHT' && styles.navigationBarRight)}>
        {children}
    </ul>;
};

export default NavbarItemCollection;
