import {format} from 'date-fns';
import {nl} from 'date-fns/locale';

export const formatDateTime = (date: Date, showSeconds: boolean = true): string => {
    return format(date, showSeconds ? 'HH:mm:ss yyyy-MM-dd': 'HH:mm dd-MM-yyyy', {locale: nl});
};

export const formatTime = (date: Date, showSeconds: boolean = true): string => {
    return format(date, showSeconds ? 'HH:mm:ss' : 'HH:mm', {locale: nl});
};

export const formatDateTimeWithDayOfWeek = (date: Date): string => {
    return format(date, 'eeee dd MMMM yyyy', {locale: nl});
};
