import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {FlowSpeedLaneTableItem, FlowSpeedTab, State} from './State';
import TabBar from '../../../../../../components/TabBar';
import {TabBarItem} from '../../../../../../components/TabBar/Props';
import BearingCompass from '../../../../../../components/BearingCompass';
import StyledButton from '../../../../../../components/StyledButton';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {chartColors} from '../../../../../../styles';
import SimpleGraph from '../../../../../../components/SimpleGraph';
import {extractOriginalFlowSpeedIdFromRegex} from '../../../../../../services/Utilities';

class DialogFlowSpeed extends React.Component<Props, State> {

    tabBarItems: TabBarItem<FlowSpeedTab>[];

    constructor(props: Props) {
        super(props);

        this.tabBarItems = [
            {
                id: FlowSpeedTab.speed,
                name: 'Snelheid (km/u)'
            },
            {
                id: FlowSpeedTab.flow,
                name: 'Voertuigen (per uur)'
            }
        ];

        this.state = {
            currentTab: FlowSpeedTab.speed,
            graphColors: [],
            laneTable: {
                flow: [],
                lanes: 0,
                speed: []
            },
            lanes: {},
            tabTimestamp: 3600
        };
    }

    componentDidMount(): void {
        const props: Readonly<Props> = this.props;
        const {properties} = props;

        this.setState({
            lanes: JSON.parse(properties.lanes)
        }, () => this.parseLanesForRendering());

        this.triggerOpenDialog();
    }

    parseLanesForRendering = (): void => {
        const state: Readonly<State> = this.state;
        const {lanes} = state;

        const laneItemFlow: { [key: string]: FlowSpeedLaneTableItem } = {};
        const laneItemSpeed: { [key: string]: FlowSpeedLaneTableItem } = {};
        let index = 0;
        const graphColors: string[] = [];

        for (const laneName in lanes) {
            if (!lanes.hasOwnProperty(laneName)) {
                continue;
            }

            const laneCategories = lanes[laneName];

            for (const laneCategoryName in laneCategories) {
                if (!laneCategories.hasOwnProperty(laneCategoryName)) {
                    continue;
                }

                const laneCategoryData = laneCategories[laneCategoryName];

                if (!laneItemSpeed.hasOwnProperty(laneCategoryName)) {
                    laneItemSpeed[laneCategoryName] = {
                        laneData: {},
                        name: laneCategoryName
                    };
                }
                if (!laneItemFlow.hasOwnProperty(laneCategoryName)) {
                    laneItemFlow[laneCategoryName] = {
                        laneData: {},
                        name: laneCategoryName
                    };
                }

                laneItemSpeed[laneCategoryName].laneData[laneName] = {
                    color: chartColors[index],
                    value: laneCategoryData.speed
                };
                laneItemFlow[laneCategoryName].laneData[laneName] = {
                    color: chartColors[index],
                    value: laneCategoryData.flow
                };
                graphColors.push(chartColors[index]);

                index++;
            }

        }

        const flow = Object.values(laneItemFlow);
        const speed = Object.values(laneItemSpeed);

        this.setState({
            graphColors: graphColors,
            laneTable: {
                flow: flow,
                lanes: Object.keys(lanes).length,
                speed: speed
            }
        });
    };

    changeCurrentActiveTab = (newTab: FlowSpeedTab): void => {
        this.setState({
            currentTab: newTab
        });
    };

    triggerOpenDialog = (): void => {
        const props: Readonly<Props> = this.props;
        const {properties} = props;
        const state: Readonly<State> = this.state;
        const {tabTimestamp} = state;

        props.openGraphDialog(
            'flowSpeed',
            properties.id,
            properties.id,
            'flowSpeed',
            tabTimestamp
        );
    };

    changeTimestamp = (): void => {
        this.setState({
            tabTimestamp: this.state.tabTimestamp === 3600 ? 86400 : 3600
        }, () => this.triggerOpenDialog());
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, graphData, showRecordIds} = props;
        const state: Readonly<State> = this.state;
        const {currentTab, laneTable, tabTimestamp} = state;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Naam</th>
                        <td>{properties.name}</td>
                    </tr>
                    <tr>
                        <th>Tijdstempel</th>
                        <td><DateTimeFormatted dateTime={properties.timestamp}/></td>
                    </tr>
                    <tr>
                        <th>Richting</th>
                        <td>
                            <BearingCompass
                                bearing={properties.bearing}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <StyledButton
                onPress={() => this.openInDexter()}
                title={'Historische snelheid en intensiteit in Dexter bekijken'}
            />

            <br/>

            <TabBar<FlowSpeedTab>
                tabBarItems={this.tabBarItems}
                activeId={currentTab}
                onClickedOnTab={(id) => this.changeCurrentActiveTab(id)}
            />

            <div>
                <div style={{display: currentTab === FlowSpeedTab.speed ? 'block' : 'none'}}>
                    {this.renderLaneTable(laneTable.speed, 'speed', laneTable.lanes)}

                    <SimpleGraph
                        data={graphData?.speed || []}
                        title={'Snelheid overzicht'}
                        width={357}
                        revision={graphData?.timeStamp || 0}
                    />

                    <br/>
                    <p>Weergeef data in de grafiek van de afgelopen:</p>
                    <StyledButton
                        onPress={this.changeTimestamp}
                        title={tabTimestamp === 3600 ? '1-uur' : '24-uur'}
                    />
                </div>

                <div style={{display: currentTab === FlowSpeedTab.flow ? 'block' : 'none'}}>
                    {this.renderLaneTable(laneTable.flow, 'flow', laneTable.lanes)}

                    <SimpleGraph
                        data={graphData?.flow || []}
                        title={'Voertuigen overzicht'}
                        width={357}
                        revision={graphData?.timeStamp || 0}
                    />

                    <br/>
                    <p>Weergeef data in de grafiek van de afgelopen:</p>
                    <StyledButton
                        onPress={this.changeTimestamp}
                        title={tabTimestamp === 3600 ? '1-uur' : '24-uur'}
                    />
                </div>
            </div>
        </React.Fragment>;
    }

    private renderLaneTable = (items: FlowSpeedLaneTableItem[], type: string, lanesCount: number) => {
        return <table className={'only-first-column-left'}>
            <thead>
                <tr>
                    <th>Voertuig type</th>
                    {this.createTableHeader(lanesCount)}
                </tr>
            </thead>
            <tbody>
                {items.map((item: FlowSpeedLaneTableItem) => <tr key={`${type}-${item.name}`}>
                    <td>{this.translateLaneCategoryName(item.name)}</td>
                    {Object.keys(item.laneData).map((lane: string) =>
                        <td
                            key={`${type}-${item.name}-${lane}`}
                            className={css(styles.tableFlowSpeedRightBorder)}
                            style={{borderRightColor: item.laneData[lane].color}}
                        >
                            {item.laneData[lane].value}
                        </td>)}
                </tr>)}
            </tbody>
        </table>;
    };

    private createTableHeader = (count: number) => {
        const items: JSX.Element[] = [];

        for (let i = 1; i <= count; i++) {
            items.push(<th key={`lane-${i}`}>{i}</th>);
        }

        return items;
    };

    private translateLaneCategoryName = (name: string): string => {
        if (name === 'anyVehicle') {
            return 'Alle Voertuigen';
        }

        return name;
    };

    private openInDexter(): void {
        const props: Readonly<Props> = this.props;
        const {properties} = props;

        window.open(`https://dexter.ndw.nu/deeplink/explorer/avg?id=${extractOriginalFlowSpeedIdFromRegex(properties.id)}&type=site-record`, '_blank')?.focus();
    }
}

export default DialogFlowSpeed;
