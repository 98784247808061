import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    noTrajectoriesSelected: {
        fontSize: 14,
        fontStyle: 'italic',
        marginTop: 10
    },
    selectedTrajectoriesTable: {
        width: '100%'
    },
    selectedTrajectoriesTableRow: {
        borderBottomWidth: 1,
        borderColor: '#ccc',
        borderStyle: 'solid'
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '10px',
        marginTop: '10px'
    },
    trashButton: {
        color: '#dc3545',
        cursor: 'pointer'
    }

});

export default styles;
