import {ActionType, createAction} from 'typesafe-actions';
import {DataSource} from '../../../../interfaces/DataSource';
import {ViewerWebsocketUpdateMessage} from '../../../../interfaces/ViewerWebsocket';
import {Feature, FeatureCollection} from 'geojson';
import {SearchFeature} from '../../../../interfaces/SearchFeature';
import {viewerApplicationTestAction} from '../../../../actions';
import {
    mapSceneMapboxLayersActivateMapLayer,
    mapSceneMapboxLayersActivateDataSource,
    mapSceneMapboxLayersDeactivateMapLayer,
    mapSceneMapboxLayersUpdateMapLayerConfiguration,
    mapSceneMapboxLayersUpdatePrefixData
} from './mapboxLayers';

export const mapSceneDataSourceUpdateMissed = createAction(
    'MAP_SCENE_DATA_SOURCE_UPDATE_MISSED',
    (action) => (dataSource: DataSource) => action({dataSource})
);

export const mapSceneDataUpdateDataSourceFilteredMapData = createAction(
    'MAP_SCENE_DATA_UPDATE_DATA_SOURCE_FILTERED_MAP_DATA',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (action) => (dataSourceID: string, mapFilteredData: any) => action({dataSourceID, mapFilteredData})
);

export const mapSceneDataMarkDataSourceAsOutdated = createAction(
    'MAP_SCENE_DATA_MARK_DATA_SOURCE_AS_OUTDATED',
    (action) => (sourceId: string) => action({sourceId})
);

export const mapSceneDataUpdateDataSourceFromWebsocket = createAction(
    'MAP_SCENE_DATA_UPDATE_DATA_SOURCE_FROM_WEBSOCKET',
    (action) => (dataSource: string, websocketUpdateMessage: ViewerWebsocketUpdateMessage<Feature>) => action({
        dataSource,
        websocketUpdateMessage
    })
);

export const mapSceneDataUpdateVectorLayerDataMapSource = createAction(
    'MAP_SCENE_DATA_UPDATE_VECTOR_LAYER_DATA_MAP_SOURCE',
    (action) => (data: Record<string, unknown>, dataSource: DataSource) => action({data, dataSource})
);

export const mapSceneDataUpdateSearchFeatures = createAction(
    'MAP_SCENE_DATA_UPDATE_SEARCH_FEATURES',
    (action) => (dataSourceID: string, searchFeatures: SearchFeature[]) => action({dataSourceID, searchFeatures})
);

export const mapSceneDataUpdateGeoJSONDataSource = createAction(
    'MAP_SCENE_DATA_UPDATE_GEO_JSON_DATA_SOURCE',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (action) => (data: any, dataSource: DataSource) => action({data, dataSource})
);

export const mapSceneDataUpdateDataSource = createAction(
    'MAP_SCENE_DATA_UPDATE_DATA_SOURCE',
    (action) => (dataSource: DataSource) => action({dataSource})
);

export const mapSceneDataUpdateBaseDataSource = createAction(
    'MAP_SCENE_DATA_UPDATE_BASE_DATA_SOURCE',
    (action) => (data: FeatureCollection & {identifier: number}, dataSource: DataSource) => action({data, dataSource})
);

export const mapSceneDataLoadDataSource = createAction(
    'MAP_SCENE_DATA_LOAD_DATA_SOURCE',
    (action) => (dataSource: DataSource) => action({dataSource})
);

export const mapSceneDataDeactivateDataSource = createAction(
    'MAP_SCENE_DATA_DEACTIVATE_DATA_SOURCE',
    (action) => (dataSource: DataSource) => action({dataSource})
);

export const mapSceneDataClearMapFilteredData = createAction(
    'MAP_SCENE_DATA_CLEAR_MAP_FILTERED_DATA',
    (action) => () => action({})
);

export const mapSceneDataClearFilteredDataForDataSource = createAction(
    'MAP_SCENE_DATA_CLEAR_FILTERED_DATA_FOR_DATA_SOURCE',
    (action) => (dataSourceId: string) => action({dataSourceId})
);

export type MapSceneDataSourcesActionTypes =
    ActionType<typeof mapSceneMapboxLayersActivateMapLayer> |
    ActionType<typeof mapSceneMapboxLayersActivateDataSource> |
    ActionType<typeof mapSceneDataClearFilteredDataForDataSource> |
    ActionType<typeof mapSceneMapboxLayersDeactivateMapLayer> |
    ActionType<typeof mapSceneDataDeactivateDataSource> |
    ActionType<typeof mapSceneDataUpdateBaseDataSource> |
    ActionType<typeof mapSceneDataUpdateGeoJSONDataSource> |
    ActionType<typeof mapSceneDataUpdateSearchFeatures> |
    ActionType<typeof mapSceneDataUpdateVectorLayerDataMapSource> |
    ActionType<typeof mapSceneDataUpdateDataSourceFilteredMapData> |
    ActionType<typeof mapSceneMapboxLayersUpdateMapLayerConfiguration> |
    ActionType<typeof mapSceneDataSourceUpdateMissed> |
    ActionType<typeof mapSceneDataMarkDataSourceAsOutdated> |
    ActionType<typeof mapSceneMapboxLayersUpdatePrefixData> |
    ActionType<typeof mapSceneDataUpdateDataSourceFromWebsocket> |
    ActionType<typeof viewerApplicationTestAction>;
