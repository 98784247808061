import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    firstColumn: {
        width: '25%'
    },
    heading: {
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: '20px'
    },
    lineFill: {
        height: '100%',
        width: '80%'
    },
    table: {
        marginBottom: '2em'
    }
});

export default styles;
