import {StyleSheet} from 'aphrodite/no-important';

export const darkStyles = StyleSheet.create({
    buttonItem: {
        backgroundColor: 'rgba(51, 51, 51, 0.8)',
        color: 'white'
    }
});

export const styles = StyleSheet.create({
    buttonItem: {
        ':hover': {
            textDecoration: 'underline'
        },
        'animation': 'all 0.5s ease-in',
        'backgroundColor': 'rgba(255, 255, 255, 0.8)',
        'borderTopLeftRadius': 4,
        'borderTopRightRadius': 4,
        'cursor': 'pointer',
        'display': 'inline-block',
        'padding': 10,
        'marginLeft': 10
    },
    buttonItemActive: {
        backgroundColor: '#F8981D',
        color: 'white'
    }
});

