import React, {ChangeEvent, KeyboardEvent, ReactElement} from 'react';
import {css} from 'aphrodite/no-important';
import styles, {styledInput} from './styles';
import {Props} from './Props';
import {Slider} from 'primereact/slider';

class StyledInput extends React.Component<Props> {
    private static readonly MIN_RANGE = 0;
    private static readonly MAX_RANGE = 100;

    handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const {value} = e.target;

        this.props.changeInput(value);
    };

    handleSliderChange = (value: number): void => {
        this.props.changeInput(String(value));
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {
            disabled,
            type,
            value,
            placeholder,
            maxLength,
            minValue,
            maxValue,
            inputMode,
            pattern
        } = props;

        return <React.Fragment>
            <div className={css(styles.container)}>
                {type !== 'range' &&
                    <input
                        disabled={disabled}
                        className={css(styles.input)}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        type={type}
                        value={value}
                        inputMode={inputMode}
                        pattern={pattern}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleInputChange(e)}
                        onKeyDown={(e) => this.handleKeyDown(e)}
                        min={minValue}
                        max={maxValue}
                    />
                }
                {type === 'range' &&
                    <div>
                        <Slider pt={{handle: {style: styledInput.handle}, root: {style: styledInput.root}}}
                            min={minValue || StyledInput.MIN_RANGE}
                            max={maxValue || StyledInput.MAX_RANGE}
                            value={Number(value)} onChange={(e) => this.handleSliderChange(e.value as number)}/>
                        <strong className={css(styles.rangeLabel)}>{value}</strong>
                    </div>
                }
            </div>
        </React.Fragment>;
    }

    private handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const props: Readonly<Props> = this.props;

        if (e.key === 'Enter' && props.submitInput) {
            props.submitInput(props.value as string);
        }
    };
}

export default StyledInput;
