import {TrajectoriesHistoricalSummedData} from '../../../interfaces/TrajectoriesHistoricalSummedData';
import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {
    appendNewTrajectoriesToSummedTrajectoriesPaneList,
    clearSummedHistoricalTrajectoriesData,
    MapSceneSummedTrajectoriesGraphActionType,
    removeTrajectoryFromSelection,
    storeNewTrajectoriesSummedGraphData,
    toggleTrajectorySummedSelection
} from '../actions/reducers/summedTrajactoriesGraph';
import {MapFeature} from '../../../interfaces/MapFeature';

export interface MapSceneSummedTrajectoriesGraphReducerState {
    trajectorySummedData: TrajectoriesHistoricalSummedData | null;
    trajectorySummedSelection: (string | number)[];
    trajectorySummedSelectionEnabled: boolean;
}

const initialState: MapSceneSummedTrajectoriesGraphReducerState = {
    trajectorySummedData: null,
    trajectorySummedSelection: [],
    trajectorySummedSelectionEnabled: false
};

const summedTrajectoriesGraphReducer: Reducer<MapSceneSummedTrajectoriesGraphReducerState, MapSceneSummedTrajectoriesGraphActionType> =
    (state: MapSceneSummedTrajectoriesGraphReducerState = initialState, action): MapSceneSummedTrajectoriesGraphReducerState => {
        switch (action.type) {
            case getType(appendNewTrajectoriesToSummedTrajectoriesPaneList):
                const ids: (string | number)[] = [];
                action.payload.features.forEach((feature: MapFeature) => {
                    if (feature.source !== 'travelTimeFcd') {
                        return;
                    }

                    const parsed = JSON.parse(feature.featurePropertiesData.locations as string);
                    if (state.trajectorySummedSelection.indexOf(parsed[0]) > -1) {
                        return;
                    }

                    ids.push(parsed[0]);
                });
                return {
                    ...state,
                    trajectorySummedSelection: [
                        ...state.trajectorySummedSelection,
                        ...ids
                    ]
                };
            case getType(clearSummedHistoricalTrajectoriesData):
                return {
                    ...state,
                    trajectorySummedData: null
                };
            case getType(removeTrajectoryFromSelection):
                return {
                    ...state,
                    trajectorySummedSelection: state.trajectorySummedSelection.filter((item: string | number) => item !== action.payload.id)
                };
            case getType(storeNewTrajectoriesSummedGraphData):
                const trajectoriesSummedData: TrajectoriesHistoricalSummedData = {
                    travelTime: {},
                    velocity: {}
                };

                for (const dataKey in action.payload.data) {
                    if (!action.payload.data.hasOwnProperty(dataKey)) {
                        continue;
                    }

                    const item = action.payload.data[dataKey];

                    trajectoriesSummedData.travelTime[dataKey] = item.travelTime as number;
                    trajectoriesSummedData.velocity[dataKey] = item.velocity as number;
                }

                return {
                    ...state,
                    trajectorySummedData: trajectoriesSummedData
                };
            case getType(toggleTrajectorySummedSelection):
                return {
                    ...state,
                    trajectorySummedSelectionEnabled: !state.trajectorySummedSelectionEnabled
                };
            default:
                return {
                    ...state
                };
        }
    };

export default summedTrajectoriesGraphReducer;
