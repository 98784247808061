import {timeToMessageAgeString} from '../../../../services/Utilities';
import {ICellRendererParams} from '@ag-grid-community/core';

export const MessageAgeCellRenderer = (data: ICellRendererParams): string => {
    if (!data.value || !data.value.length) {
        return '-';
    }

    return timeToMessageAgeString(data.value);
};
