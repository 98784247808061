import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    flexContainer: {
        display: 'flex'
    },
    flexItemLeft: {
        flex: 1,
        paddingRight: 5,
        paddingLeft: 10
    },
    flexItemRight: {
        flex: 1,
        paddingRight: 10,
        paddingLeft: 5
    }
});

export default styles;
