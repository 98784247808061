import React, {ReactElement} from 'react';
import StyledInput from '../../../../../../components/StyledInput';
import StyledButton from '../../../../../../components/StyledButton';
import {Props} from './Props';
import {State} from './State';

class NotificationPane extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            message: ''
        };
    }

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const state: Readonly<State> = this.state;

        return <React.Fragment>
            <p>Met het onderstaand veld is het mogelijk om een notificatie naar de gebruikers van de NDW Viewer te
                sturen. Wanneer het veld
                leeggemaakt is en je op opslaan drukt zal de laatste notificatie verborgen worden.</p>

            <br/>

            <StyledInput
                type={'text'}
                value={state.message}
                changeInput={(value) => this.handleMessageInputChange(value)}
                placeholder={'Notificatie bericht'}
                submitInput={() => props.onSaveNewNotificationMessage(state.message)}
            />

            <StyledButton
                onPress={() => props.onSaveNewNotificationMessage(state.message)}
                aria-label="Opslaan"
                title={<React.Fragment><span className="fas fa-save"/> Opslaan</React.Fragment>}
            />
        </React.Fragment>;
    }

    private handleMessageInputChange = (message: string) => {
        this.setState({
            message
        });
    };

}

export default NotificationPane;
