import {combineEpics, Epic} from 'redux-observable';
import {isActionOf} from 'typesafe-actions';
import {filter, switchMap} from 'rxjs/operators';
import {activatedTrafficMessagesPane} from '../../../scenes/MapScene/actions/trafficMessagesPane';
import {enableMapLayer} from '../../../scenes/MapScene/actions/mapBoxComponent';
import {trafficInformationResources} from '../../../constants';

const loadTrafficMessagesOnActivatedTrafficMessagesPane: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(activatedTrafficMessagesPane)),
        switchMap(() => trafficInformationResources.map(source => enableMapLayer(source.mapLayer)))
    );

const trafficInformationPaneEpics: Epic = combineEpics(
    loadTrafficMessagesOnActivatedTrafficMessagesPane,
);

export default trafficInformationPaneEpics;
