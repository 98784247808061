import {isActionOf} from 'typesafe-actions';
import {changeCurrentRoute, layoutComponentRequestedLogoutCurrentUser} from '../../components/Layout/actions';
import {combineEpics, Epic} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {push} from 'connected-react-router';
import {userLogoutRequested} from '@ndw/react-keycloak-authentication';

const pushNewRouteOnChangeCurrentRoute: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(changeCurrentRoute)),
        map((action) => push(action.payload.route))
    );

const requestCloseSessionOnLogoutRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(layoutComponentRequestedLogoutCurrentUser)),
        map(() => userLogoutRequested())
    );

const layoutEpics: Epic = combineEpics(
    pushNewRouteOnChangeCurrentRoute,
    requestCloseSessionOnLogoutRequested
);

export default layoutEpics;
