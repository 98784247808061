import {ActionType, createAction} from 'typesafe-actions';
import {MapFeature} from '../../../../interfaces/MapFeature';
import {HistoricalSummedDataDTO} from '../../../../generated/ViewerApiClient';

export const appendNewTrajectoriesToSummedTrajectoriesPaneList = createAction(
    'APPEND_NEW_TRAJECTORIES_TO_SUMMED_TRAJECTORIES_PANE_LIST',
    (action) => (features: MapFeature[]) => action({features})
);

export const clearSummedHistoricalTrajectoriesData = createAction(
    'CLEAR_SUMMED_HISTORICAL_TRAJECTORIES_DATA',
    (action) => () => action({})
);

export const removeTrajectoryFromSelection = createAction(
    'REMOVE_TRAJECTORY_FROM_SELECTION',
    (action) => (id: string) => action({id})
);

export const toggleTrajectorySummedSelection = createAction(
    'TOGGLE_TRAJECTORY_SUMMED_SELECTION',
    (action) => () => action({})
);

export const storeNewTrajectoriesSummedGraphData = createAction(
    'STORE_NEW_TRAJECTORIES_GRAPH_DATA',
    (action) => (data: { [key: string]: HistoricalSummedDataDTO }) => action({data})
);

export type MapSceneSummedTrajectoriesGraphActionType = ActionType<typeof appendNewTrajectoriesToSummedTrajectoriesPaneList> |
    ActionType<typeof clearSummedHistoricalTrajectoriesData> |
    ActionType<typeof removeTrajectoryFromSelection> |
    ActionType<typeof toggleTrajectorySummedSelection> |
    ActionType<typeof storeNewTrajectoriesSummedGraphData>;
