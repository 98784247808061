/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactElement} from 'react';
import mapboxGL, {GeoJSONSource, MapboxGeoJSONFeature, MapLayerMouseEvent, MapMouseEvent, Point} from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {Log} from '../../utils/log';
import {Props} from './Props';
import {LayersState} from '../../scenes/MapScene/reducers/mapboxLayersReducer';
import {DataSource} from '../../interfaces/DataSource';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {MapFeature} from '../../interfaces/MapFeature';
import {MapFlyTo} from '../../interfaces/MapFlyTo';
import {MapView} from '../../interfaces/Mapbox';
import {HighlightFeatures, MapSettings} from '../../scenes/MapScene/reducers/generalReducer';
import {MapFilters} from '../../scenes/MapScene/reducers/filterAndSearchReducer';
import {Feature, FeatureCollection} from 'geojson';
import {DataSourceState} from '../../scenes/MapScene/reducers/dataReducer';

class MapboxGL extends React.Component<Props> {

    activeLayerIDs: string[];
    mapLayerToLayer: { [key: string]: string };
    mapContainer: HTMLDivElement | null;
    mapHasFinishedLoading: boolean;
    mapGeoCoderContainer: HTMLDivElement | null;
    mapInstance: mapboxGL.Map | null;
    mapNavigationControl: mapboxGL.NavigationControl | null;
    mapGeoCoderControl: MapboxGeocoder | null;
    mapLayerBaseFilters: { [key: string]: any };
    hoverTimeoutHolder?: NodeJS.Timeout;
    hoverTimeoutMS = 300;
    mapClickMargin = 15;
    numberOfFeaturesCalculationDelayMs = 2500;
    previousVisibleFeaturesCount = -1;

    constructor(props: Props) {
        super(props);

        this.activeLayerIDs = [];
        this.mapLayerToLayer = {};
        this.mapContainer = null;
        this.mapHasFinishedLoading = false;
        this.mapGeoCoderContainer = null;
        this.mapInstance = null;
        this.mapNavigationControl = null;
        this.mapGeoCoderControl = null;
        this.mapLayerBaseFilters = {};
    }

    componentDidMount(): void {

        // Extract the props
        const props: Readonly<Props> = this.props;
        const {styleUrl, viewport} = props;

        if (!this.mapContainer || !this.mapGeoCoderContainer) {
            return;
        }

        this.mapInstance = new mapboxGL.Map({
            center: [viewport.longitude, viewport.latitude],
            container: this.mapContainer,
            style: styleUrl,
            zoom: viewport.zoom
        });

        this.mapNavigationControl = new mapboxGL.NavigationControl();
        this.mapInstance.addControl(this.mapNavigationControl, 'top-left');

        this.mapGeoCoderControl = new MapboxGeocoder({
            accessToken: mapboxGL.accessToken,
            country: 'nl',
            language: 'nl',
            mapboxgl: mapboxGL
        });
        this.mapGeoCoderContainer.appendChild(this.mapGeoCoderControl.onAdd(this.mapInstance));

        this.mapInstance.on('load', this.onMapLoad);
        this.mapInstance.on('move', this.onMapMoved);
        this.mapInstance.on('click', this.onMapClicked);
        this.mapInstance.on('contextmenu', this.onUserMapRightClicked);

        // Resize the map after 200ms
        setTimeout(() => {
            if (this.mapInstance) {
                this.mapInstance.resize();
            }
        }, 200);
    }

    componentWillUnmount(): void {

        if (this.mapInstance) {
            this.mapInstance.off('load', this.onMapLoad);
            this.mapInstance.off('move', this.onMapMoved);
            this.mapInstance.off('click', this.onMapClicked);
            this.mapInstance.remove();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {

        this.handleDataSourcesUpdate(this.props.dataSources, prevProps.dataSources);
        this.handleMapLayersUpdate(this.props.mapLayers, prevProps.mapLayers);
        this.handleStyleUrlUpdate(this.props.styleUrl, prevProps.styleUrl);
        this.handleFlyToLocationUpdate(this.props.mapFlyToLocation, prevProps.mapFlyToLocation);
        this.handleMapFilterUpdates(this.props.mapFilter, prevProps.mapFilter, this.props.showTrafficJamIcons, prevProps.showTrafficJamIcons);
        this.handleMapSettingsUpdate(this.props.mapSettings, prevProps.mapSettings);
        this.handleHighlightFeatures(this.props.highlightFeatures, prevProps.highlightFeatures);
        this.notifyNumberOfFeaturesVisibleOnMap();
    }

    render(): ReactElement {
        return <React.Fragment>
            <div className={css(styles.mapContainer)} ref={(el) => this.mapContainer = el}/>
            <div className={css(styles.geoCoderWrapper)} ref={(el) => this.mapGeoCoderContainer = el}/>
        </React.Fragment>;
    }

    private disableAllCurrentDataSourcesAndLayers = () => {
        const props: Readonly<Props> = this.props;
        const {mapLayers, dataSources} = props;

        for (const mapLayersKey in mapLayers) {
            if (!mapLayers.hasOwnProperty(mapLayersKey)) {
                continue;
            }

            const mapLayer = mapLayers[mapLayersKey];
            const dataSource = dataSources[mapLayer.source];

            if (mapLayer.isActive) {
                mapLayer.layers.forEach((layerConfiguration) => this.removeLayerFromMap(layerConfiguration, dataSource));
            }
        }

        for (const dataSourceKey in dataSources) {
            if (!dataSources.hasOwnProperty(dataSourceKey)) {
                continue;
            }

            const dataSource = dataSources[dataSourceKey];

            if (dataSource.isActive) {
                this.removeSourceFromMap(dataSource);
            }
        }
    };

    private enableAllCurrentDataSourcesAndLayers = () => {

        // Extract the props
        const props: Readonly<Props> = this.props;
        const {mapLayers, dataSources} = props;

        for (const dataSourceKey in dataSources) {
            if (!dataSources.hasOwnProperty(dataSourceKey)) {
                continue;
            }

            const dataSource = dataSources[dataSourceKey];

            if (dataSource.isActive) {
                this.addNewSourceToMap(dataSource);
                if (dataSource.dataLoadMethod === 'GeoJSON') {
                    this.setDataGeoJsonSource(dataSource);
                } else if (dataSource.dataLoadMethod === 'VectorTiles') {
                    this.setDataVectorTilesSource(dataSource);
                }
            }
        }

        for (const mapLayersKey in mapLayers) {
            if (!mapLayers.hasOwnProperty(mapLayersKey)) {
                continue;
            }

            const mapLayer = mapLayers[mapLayersKey];
            const dataSource = dataSources[mapLayer.source];

            if (mapLayer.isActive) {
                mapLayer.layers.forEach((layerConfiguration) => this.addNewLayerToMap(layerConfiguration, dataSource));
            }
        }
    };

    private handleDataSourcesUpdate = (newDataSources: DataSourceState, oldDataSources: DataSourceState) => {
        if (!this.mapInstance) {
            return;
        }

        for (const newDataSourceKey in newDataSources) {
            if (!newDataSources.hasOwnProperty(newDataSourceKey)) {
                continue;
            }

            const dataSource = newDataSources[newDataSourceKey];
            const oldDataSource = oldDataSources[newDataSourceKey];

            if (!dataSource && oldDataSource) {

                // The map source has been removed from the reducer, remove it from the map
                this.removeSourceFromMap(oldDataSource);
            } else if (dataSource.isActive && !oldDataSource.isActive) {

                // The dataSource new state is active, and it was not, add it to the map
                this.addNewSourceToMap(dataSource);
            } else if (!dataSource.isActive && oldDataSource && oldDataSource.isActive) {
                this.removeSourceFromMap(dataSource);
            } else if (
                dataSource.isActive && oldDataSource.isActive
                && dataSource.currentIdentifier !== oldDataSource.currentIdentifier
            ) {

                // The dataSource has received a new data package
                if (dataSource.dataLoadMethod === 'GeoJSON') {
                    this.setDataGeoJsonSource(dataSource);
                } else if (dataSource.dataLoadMethod === 'VectorTiles') {
                    this.setDataVectorTilesSource(dataSource);
                }
            }
        }
    };

    private setDataGeoJsonSource = (dataSource: DataSource) => {
        if (!this.mapInstance) {
            return;
        }

        const source: GeoJSONSource | undefined = this.mapInstance.getSource(dataSource.id) as unknown as GeoJSONSource | undefined;

        if (source && dataSource.dataLoadMethod === 'GeoJSON' && dataSource.sourceConfiguration.type === 'geojson') {
            const mapData = dataSource.layerFilteredMapData || dataSource.layerMapData;
            if (mapData) {
                const enrichedMapData = this.enrichedDataGeoJsonSource(dataSource, mapData);
                source.setData(enrichedMapData || dataSource.sourceConfiguration.data!);
            } else {
                source.setData(dataSource.sourceConfiguration.data!);
            }
        }
    };

    private enrichedDataGeoJsonSource = (
        dataSource: DataSource,
        featureCollection: FeatureCollection) => {
        switch (dataSource.id) {
            case 'fd':
                return this.featureCollectionWithPointsAddedForFdRecords(featureCollection);
            case 'situationV2':
                return this.featureCollectionWithIconPointsAddedForOtisRecords(featureCollection);
            default:
                return featureCollection;
        }
    };

    private featureCollectionWithPointsAddedForFdRecords = (featureCollection: FeatureCollection) => {
        return {
            ...featureCollection,
            features: this.lineStringsWithPoints(featureCollection.features, true)
        } as FeatureCollection;
    };

    private featureCollectionWithIconPointsAddedForOtisRecords = (featureCollection: FeatureCollection) => {
        const otisLineStringFeatures =
            featureCollection.features
                .filter(feature => feature.properties?.dataType === 'situationTrafficMessageOtis');
        const otherLineStringFeatures =
            featureCollection.features
                .filter(feature => feature.properties?.dataType !== 'situationTrafficMessageOtis');
        return {
            ...featureCollection,
            features: [...otherLineStringFeatures, ...this.lineStringsWithPoints(otisLineStringFeatures, true)]
        } as FeatureCollection;
    };

    private lineStringsWithPoints = (lineStringFeatures: Feature[], useLastCoordinateAsPoint: boolean = false) => {
        return lineStringFeatures
            .map(feature => {
                try {
                    const pointFeature = this.pointFromLineString(feature, useLastCoordinateAsPoint);
                    return [feature, pointFeature];
                } catch (error){
                    Log.warn(error);
                    return [feature];
                }
            })
            .flatMap(lineAndPoint => lineAndPoint);
    };

    private pointFromLineString = (lineStringFeature: Feature, useLastCoordinateAsPoint: boolean) => {
        if (lineStringFeature.geometry && 'coordinates' in lineStringFeature.geometry) {
            const coordinatesToUseForPoint = useLastCoordinateAsPoint ?
                lineStringFeature.geometry.coordinates[lineStringFeature.geometry.coordinates.length - 1]
                : lineStringFeature.geometry.coordinates[0];
            return {
                ...lineStringFeature,
                geometry: {
                    type: 'Point',
                    coordinates: coordinatesToUseForPoint
                },
                id: `${lineStringFeature.id}`,
                properties: {
                    ...lineStringFeature.properties,
                    id: `${lineStringFeature.id}`
                }
            };
        } else {
            throw new Error(`No coordinates could be found on LineStringFeature: ${lineStringFeature.id}, geometry: ${lineStringFeature.geometry}`);
        }
    };

    private setDataVectorTilesSource = (dataSource: DataSource) => {
        if (!this.mapInstance) {
            return;
        }

        if (!dataSource.sourceLayer.length || !dataSource.layerMapData || dataSource.dataLoadMethod !== 'VectorTiles') {
            return;
        }

        // Clear the current feature state
        this.mapInstance.removeFeatureState({
            source: dataSource.id,
            sourceLayer: dataSource.sourceLayer
        });

        const mapFeatureStateData = dataSource.layerFilteredMapData || dataSource.layerMapData;

        Object.keys(mapFeatureStateData).forEach((index) => {
            const feature = mapFeatureStateData[index];
            if (!this.mapInstance) {
                return;
            }

            this.mapInstance.setFeatureState(
                {
                    id: index,
                    source: dataSource.id,
                    sourceLayer: dataSource.sourceLayer
                },
                feature as never
            );
        });
    };

    private handleMapLayersUpdate = (newMapLayers: LayersState, oldMapLayers: LayersState) => {
        if (!this.mapInstance) {
            return;
        }

        for (const newMapLayersKey in newMapLayers) {
            if (!newMapLayers.hasOwnProperty(newMapLayersKey)) {
                continue;
            }

            const mapLayer = newMapLayers[newMapLayersKey];
            const oldMapLayer = oldMapLayers[newMapLayersKey];
            const dataSource = this.props.dataSources[mapLayer.source];

            if (mapLayer && !oldMapLayer) {
                for (const layersKey in mapLayer.layers) {
                    if (!mapLayer.layers.hasOwnProperty(layersKey)) {
                        continue;
                    }

                    const layer = mapLayer.layers[layersKey];
                    this.mapLayerToLayer[layer.id] = mapLayer.id;
                }
            } else if (!mapLayer && oldMapLayer) {
                for (const layersKey in oldMapLayer.layers) {
                    if (!oldMapLayer.layers.hasOwnProperty(layersKey)) {
                        continue;
                    }

                    const layer = oldMapLayer.layers[layersKey];
                    delete this.mapLayerToLayer[layer.id];
                }
            }

            if (mapLayer.isActive && !oldMapLayer.isActive) {
                mapLayer.layers.forEach((layer) => {
                    if (!this.mapInstance) {
                        return;
                    }

                    this.addNewLayerToMap(layer, dataSource);
                });
                this.setFilters(this.props.mapFilter, this.props.showTrafficJamIcons);
            } else if (!mapLayer.isActive && (oldMapLayer && oldMapLayer.isActive)) {
                mapLayer.layers.forEach((layer) => {
                    if (!this.mapInstance) {
                        return;
                    }

                    this.removeLayerFromMap(layer, dataSource);
                });
            }
        }
    };

    private handleStyleUrlUpdate = (currentUrl: string, previousUrl: string) => {
        if (!this.mapInstance) {
            return;
        }
        if (!this.mapHasFinishedLoading) {
            return;
        }

        if (currentUrl !== previousUrl) {
            this.disableAllCurrentDataSourcesAndLayers();

            this.mapInstance.setStyle(currentUrl);

            setTimeout(() => {
                this.enableAllCurrentDataSourcesAndLayers();
                this.setFilters(this.props.mapFilter, this.props.showTrafficJamIcons);
            }, 200);
        }
    };

    private handleFlyToLocationUpdate = (currentFlyToLocation: MapFlyTo | null, previousMapFlyToLocation: MapFlyTo | null) => {
        if (!currentFlyToLocation) {
            return;
        }

        if (!previousMapFlyToLocation) {
            this.mapFlyToLocation(currentFlyToLocation);
            return;
        }

        if (
            currentFlyToLocation.center[0] !== previousMapFlyToLocation.center[0]
            || currentFlyToLocation.center[1] !== previousMapFlyToLocation.center[1]
            || currentFlyToLocation.offset[0] !== previousMapFlyToLocation.offset[0]
            || currentFlyToLocation.offset[1] !== previousMapFlyToLocation.offset[1]
            || currentFlyToLocation.zoom !== previousMapFlyToLocation.zoom
        ) {
            this.mapFlyToLocation(currentFlyToLocation);
        }
    };

    private handleMapFilterUpdates = (currentMapFilters: MapFilters, previousMapFilters: MapFilters | null, showTrafficJamIcons: boolean, prevShowTrafficJamIcons: boolean) => {

        if (!previousMapFilters || currentMapFilters.filterTimeStamp !== previousMapFilters.filterTimeStamp || showTrafficJamIcons !== prevShowTrafficJamIcons) {
            this.setFilters(currentMapFilters, showTrafficJamIcons);
        }
    };

    private handleMapSettingsUpdate = (currentMapSettings: MapSettings, previousMapSettings: MapSettings) => {
        if (currentMapSettings.identifier !== previousMapSettings.identifier) {
            this.setLineOffsetTravelTimeLayerIfZoomLevelReached(currentMapSettings.travelTimeFcdOffset);
        }
    };

    private handleHighlightFeatures = (currentHighlightFeatures: HighlightFeatures | null, previousHighlightFeatures: HighlightFeatures | null) => {
        if (!!currentHighlightFeatures && !previousHighlightFeatures) {
            this.setHighlightSourceFeatures(currentHighlightFeatures.features);
        } else if (!!currentHighlightFeatures && !!previousHighlightFeatures && currentHighlightFeatures.identifier !== previousHighlightFeatures.identifier) {
            this.setHighlightSourceFeatures(currentHighlightFeatures.features);
        } else if (!currentHighlightFeatures && !!previousHighlightFeatures) {
            this.setHighlightSourceFeatures([]);
        }
    };

    private onMapLoad = () => {
        const props: Readonly<Props> = this.props;
        props.mapHasFinishedLoading();
        this.mapHasFinishedLoading = true;

        this.setupHighLightLayer();
    };

    private onMapMoved = () => {
        if (!this.mapInstance) {
            return;
        }

        const {lat, lng} = this.mapInstance.getCenter();
        const zoom = this.mapInstance.getZoom();

        const viewport: MapView = {
            latitude: lat,
            longitude: lng,
            zoom: zoom
        };

        this.props.updateMapPosition(viewport);
    };

    private onMouseEnter = (e: MapLayerMouseEvent, dataSource: DataSource) => {
        if (!this.mapInstance) {
            return;
        }

        let enablePointer = false;

        if (dataSource.dataLoadMethod === 'VectorTiles') {
            if (e.features) {
                e.features.forEach((feature) => {
                    if (feature.state && feature.state.color) {
                        enablePointer = true;
                    }
                });
            }
        } else {
            enablePointer = true;
        }

        if (enablePointer) {
            this.mapInstance.getCanvas().style.cursor = 'pointer';
            this.hoverTimeoutHolder = setTimeout(() => this.props.onUserHoverOverFeature(e.features!.map(feature => this.toMapClickedFeature(feature)), e.point.x, e.point.y), this.hoverTimeoutMS);

        }
    };

    private onMouseLeave = () => {
        if (!this.mapInstance) {
            return;
        }

        this.mapInstance.getCanvas().style.cursor = '';
        this.props.onUserExistsHoverOverFeature();
        if (this.hoverTimeoutHolder) {
            clearTimeout(this.hoverTimeoutHolder);
        }
    };

    private addNewSourceToMap = (dataSource: DataSource) => {
        if (!this.mapInstance) {
            return;
        }

        this.mapInstance.addSource(dataSource.id, dataSource.sourceConfiguration);
    };

    private addNewLayerToMap = (layerConfiguration: any, dataSource: DataSource) => {
        if (!this.mapInstance) {
            return;
        }
        const props: Readonly<Props> = this.props;
        const {mapSettings} = props;

        this.mapInstance.addLayer(layerConfiguration);
        this.mapInstance.on('mouseenter', layerConfiguration.id, (e: MapLayerMouseEvent) => this.onMouseEnter(e, dataSource));
        this.mapInstance.on('mouseleave', layerConfiguration.id, this.onMouseLeave);

        this.activeLayerIDs = [
            ...this.activeLayerIDs,
            layerConfiguration.id
        ];

        if (layerConfiguration.filter) {
            this.mapLayerBaseFilters[layerConfiguration.id] = layerConfiguration.filter;
        }

        if (
            (layerConfiguration.id === 'travelTimesFcdLine' || layerConfiguration.id === 'travelTimesOther')
            && mapSettings.travelTimeFcdOffset > 0
        ) {
            this.setLineOffsetTravelTimeLayerIfZoomLevelReached(mapSettings.travelTimeFcdOffset);
        }

        this.updateLayerSequence();
    };

    private removeSourceFromMap = (dataSource: DataSource) => {
        if (!this.mapInstance) {
            return;
        }
        if (!this.mapInstance.getSource(dataSource.id)) {
            return;
        }

        // Extract the props
        const props: Readonly<Props> = this.props;
        const {mapLayers} = props;

        for (const mapLayersKey in mapLayers) {
            if (!mapLayers.hasOwnProperty(mapLayersKey)) {
                continue;
            }

            const item = mapLayers[mapLayersKey];

            if (item.source === dataSource.id && item.isActive) {
                item.layers.forEach((layer: any) => this.removeLayerFromMap(layer, dataSource));
            }
        }

        this.mapInstance.removeSource(dataSource.id);
    };

    private removeLayerFromMap = (layerConfiguration: any, dataSource: DataSource) => {
        if (!this.mapInstance) {
            return;
        }

        if (!this.mapInstance.getLayer(layerConfiguration.id)) {
            return;
        }

        this.mapInstance.off('mouseenter', layerConfiguration.id, (e: MapLayerMouseEvent) => this.onMouseEnter(e, dataSource));
        this.mapInstance.off('mouseleave', layerConfiguration.id, this.onMouseLeave);
        this.mapInstance.removeLayer(layerConfiguration.id);

        this.activeLayerIDs = this.activeLayerIDs.filter((item: string) => item !== layerConfiguration.id);

        if (this.mapLayerBaseFilters[layerConfiguration.id]) {
            delete this.mapLayerBaseFilters[layerConfiguration.id];
        }

        this.updateLayerSequence();
    };

    private onMapClicked = (e: MapMouseEvent) => {
        if (!this.mapInstance) {
            return;
        }

        const foundFeatures: { [key: string]: MapFeature } = {};
        const leftUpPoint = new Point(e.point.x - this.mapClickMargin, e.point.y - this.mapClickMargin);
        const rightDownPoint = new Point(e.point.x + this.mapClickMargin, e.point.y + this.mapClickMargin);
        const box: [Point, Point] = [leftUpPoint, rightDownPoint];

        const queriedFeatures = this.mapInstance.queryRenderedFeatures(
            box,
            {
                layers: this.activeLayerIDs
            }
        );

        queriedFeatures.forEach((feature: MapboxGeoJSONFeature) => {
            if (feature.sourceLayer && !feature.state.color) {
                return;
            }

            foundFeatures[feature.properties!.id] = this.toMapClickedFeature(feature);
        });

        this.props.onUserMapClick(Object.values(foundFeatures), e.point.x, e.point.y);
    };

    private toMapClickedFeature(feature: MapboxGeoJSONFeature): MapFeature {
        return {
            featurePropertiesData: feature.properties || {},
            featureStateData: feature.state || {},
            id: feature.properties!.id,
            layer: this.mapLayerToLayer[feature.layer.id],
            mapLayer: feature.layer.id,
            source: feature.source
        };
    }

    private onUserMapRightClicked = (mapMouseEvent: MapMouseEvent) => {
        if (!this.mapInstance) {
            return;
        }

        const foundFeatures: { [key: string]: MapFeature } = {};
        const queriedFeatures = this.mapInstance.queryRenderedFeatures(
            mapMouseEvent.point,
            {
                layers: this.activeLayerIDs
            }
        );

        queriedFeatures.forEach((feature: MapboxGeoJSONFeature) => {
            if (feature.sourceLayer && !feature.state.color) {
                return;
            }

            const sourceFeature = (this.props.dataSources[feature.source].layerMapData as FeatureCollection).features
                .find((sourceFeature) => sourceFeature.properties?.id === feature.properties?.id);

            foundFeatures[feature.properties!.id] = {
                featurePropertiesData: feature.properties || {},
                featureStateData: feature.state || {},
                id: feature.properties!.id,
                layer: this.mapLayerToLayer[feature.layer.id],
                mapLayer: feature.layer.id,
                source: feature.source,
                geometry: sourceFeature?.geometry
            };
        });

        this.props.onUserMapRightClick(Object.values(foundFeatures), mapMouseEvent.point.x, mapMouseEvent.point.y);
    };

    private updateLayerSequence = () => {
        if (!this.mapInstance) {
            return;
        }

        const props: Readonly<Props> = this.props;
        const {mapLayers} = props;

        const mapLayersToSort: { [key: number]: string[] } = {};

        for (const mapLayersKey in mapLayers) {
            if (!mapLayers.hasOwnProperty(mapLayersKey)) {
                continue;
            }

            const item = mapLayers[mapLayersKey];

            const mapLayerIDs: string[] = [];

            for (const layersKey in item.layers) {
                if (!item.layers.hasOwnProperty(layersKey)) {
                    continue;
                }

                const mapLayer = item.layers[layersKey];

                if (this.mapInstance.getLayer(mapLayer.id)) {
                    mapLayerIDs.push(mapLayer.id);
                }
            }

            if (item.isActive) {
                if (mapLayersToSort.hasOwnProperty(item.zIndex)) {
                    mapLayersToSort[item.zIndex] = [
                        ...mapLayersToSort[item.zIndex],
                        ...mapLayerIDs
                    ];
                } else {
                    mapLayersToSort[item.zIndex] = mapLayerIDs;
                }
            }
        }

        let lastMapLayerID: string | undefined;

        for (const mapLayersToSortKey in mapLayersToSort) {
            if (!mapLayersToSort.hasOwnProperty(mapLayersToSortKey)) {
                continue;
            }

            const mapLayerIDs = mapLayersToSort[mapLayersToSortKey];

            for (const mapLayerIDsKey in mapLayerIDs) {
                if (!mapLayerIDs.hasOwnProperty(mapLayerIDsKey)) {
                    continue;
                }

                const mapLayerID = mapLayerIDs[mapLayerIDsKey];

                // noinspection JSUnusedAssignment
                this.mapInstance.moveLayer(mapLayerID, lastMapLayerID);
                lastMapLayerID = mapLayerID;
            }
        }

        this.mapInstance.moveLayer('highlightLayerLine');
        this.mapInstance.moveLayer('highlightLayerSymbol');
    };

    private mapFlyToLocation = (newLocation: MapFlyTo) => {
        if (!this.mapInstance) {
            return;
        }

        this.mapInstance.flyTo(newLocation);
    };

    private setFilters = (filters: MapFilters, showTrafficJamIcons: boolean) => {
        if (!this.mapInstance) {
            return;
        }

        if (this.mapInstance.getLayer('wazeAlertsOther')) {
            const wazeAlertsOtherFilter = [
                'all',
                this.mapLayerBaseFilters.wazeAlertsOther
            ];
            if (filters.wazeAlertNdwKnown) {
                wazeAlertsOtherFilter.push(['==', 'ndw', false]);
            }
            if (filters.currentTrafficCenter) {
                wazeAlertsOtherFilter.push(['==', 'trafficCenter', filters.currentTrafficCenter]);
            }
            this.mapInstance.setFilter('wazeAlertsOther', wazeAlertsOtherFilter);
        }

        this.filterWazeAlertIncidentsLayer('wazeAlertsText', filters);
        this.filterWazeAlertIncidentsLayer('wazeAlertsSymbol', filters);
        this.filterSituationOndaLayer('ondaLine', filters);
        this.filterSituationOndaLayer('ondaSymbol', filters);
        this.filterDfineLayer(filters);
        this.filterPrefixes(filters);
        this.filterFdLayer(filters);
        this.filterSituationOndaLayer('ondaLine', filters);
        this.filterSituationOndaLayer('ondaSymbol', filters);
        this.filterSituationOtisLayer('trafficMessageOtis', filters, showTrafficJamIcons);
        this.filterSituationFlisvisLayer(filters);
        this.filterSituationRoadWorkActualLayer('roadWorkActualLine', filters);
        this.filterSituationRoadWorkActualLayer('roadWorkActualSymbol', filters);
        this.filterSituationRushHourLaneLayer(filters);
    };

    private filterSituationOtisLayer = (layerNamePrefix: string, filters: MapFilters, showTrafficJamIcons: boolean) => {
        if (!this.mapInstance) {
            return;
        }
        (this.mapInstance.getStyle().layers || [])
            .filter(layer => layer.id.startsWith(layerNamePrefix))
            .map(layer => layer.id)
            .forEach(layerName => {
                if (this.mapInstance!.getLayer(layerName)) {
                    const otisFilter: (string | (string | boolean)[])[] = [...this.mapLayerBaseFilters[layerName]];
                    filters.situationTypeFilters.forEach(filter => {
                        filter.value.split(';')
                            .forEach(value => otisFilter.push(['!=', 'type', value]));

                    });
                    if (filters.currentTrafficCenter) {
                        otisFilter.push(['==', 'trafficCenter', filters.currentTrafficCenter]);
                    }

                    if (!showTrafficJamIcons) {
                        if (layerName === 'trafficMessageOtisAbnormalTraffic') {
                            otisFilter.push(['!=', 'type', 'AbnormalTraffic']);
                        }

                        if (layerName === 'trafficMessageOtisDefaultIcon') {
                            otisFilter.push(['!=', 'type', 'AbnormalTraffic']);
                        }
                    }
                    this.mapInstance!.setFilter(layerName, otisFilter);
                }
            });

    };

    private filterSituationFlisvisLayer = (filters: MapFilters) => {
        if (!this.mapInstance) {
            return;
        }
        if (this.mapInstance.getLayer('trafficMessageFlisvis')) {
            const layerFilter: (string | (string | boolean)[])[] = [
                'all',
                this.mapLayerBaseFilters['trafficMessageFlisvis']

            ];
            if (filters.currentTrafficCenter) {
                layerFilter.push(['==', 'trafficCenter', filters.currentTrafficCenter]);
            }
            this.mapInstance.setFilter('trafficMessageFlisvisLine', layerFilter);
            this.mapInstance.setFilter('trafficMessageFlisvis', layerFilter);
        }
    };

    private filterSituationRoadWorkActualLayer = (layerName: string, filters: MapFilters) => {
        if (!this.mapInstance) {
            return;
        }
        if (this.mapInstance.getLayer(layerName)) {
            const layerFilter: (string | (string | boolean)[])[] = [
                'all',
                this.mapLayerBaseFilters[layerName]

            ];
            if (filters.currentTrafficCenter) {
                layerFilter.push(['==', 'trafficCenter', filters.currentTrafficCenter]);
            }
            this.mapInstance.setFilter(layerName, layerFilter);
        }
    };

    private filterSituationRushHourLaneLayer = (filters: MapFilters) => {
        if (!this.mapInstance) {
            return;
        }
        if (this.mapInstance.getLayer('rushHourLane')) {
            const layerFilter: (string | (string | boolean)[])[] = [
                'all',
                this.mapLayerBaseFilters['rushHourLane']
            ];
            if (filters.currentTrafficCenter) {
                layerFilter.push(['==', 'trafficCenter', filters.currentTrafficCenter]);
            }
            this.mapInstance.setFilter('rushHourLane', layerFilter);
        }
    };

    private setLineOffsetTravelTimeLayerIfZoomLevelReached = (lineOffset: number) => {
        if (!this.mapInstance) {
            return;
        }

        if (this.mapInstance.getLayer('travelTimesFcdLine')) {
            this.mapInstance.setPaintProperty('travelTimesFcdLine', 'line-offset', lineOffset || 0);
        }

        if (this.mapInstance.getLayer('travelTimesOther')) {
            this.mapInstance.setPaintProperty('travelTimesOther', 'line-offset', lineOffset ? lineOffset / 2 : 0);
        }
    };

    private filterWazeAlertIncidentsLayer = (layerName: string, filters: MapFilters) => {
        if (!this.mapInstance) {
            return;
        }

        if (!this.mapInstance.getLayer(layerName)) {
            return;
        }

        const wazeAlertsFilter = [
            'all',
            this.mapLayerBaseFilters[layerName]
        ];
        if (filters.wazeAlertNdwKnown) {
            wazeAlertsFilter.push(['==', 'ndw', false]);
        }
        if (filters.wazeAlertStatusSet) {
            wazeAlertsFilter.push(['==', 'newData', true]);
        }
        if (filters.currentTrafficCenter) {
            wazeAlertsFilter.push(['==', 'trafficCenter', filters.currentTrafficCenter]);
        }
        this.mapInstance.setFilter(layerName, wazeAlertsFilter);
    };

    private filterSituationOndaLayer = (layerName: 'ondaLine' | 'ondaSymbol', filters: MapFilters) => {
        if (!this.mapInstance) {
            return;
        }

        if (this.mapInstance.getLayer(layerName)) {
            const ondaFilters = [
                'all',
                ['==', 'dataType', 'situationOnda']
            ];

            if (filters.currentTrafficCenter) {
                ondaFilters.push(['==', 'trafficCenter', filters.currentTrafficCenter]);
            }
            this.mapInstance.setFilter(layerName, ondaFilters);
        }
    };

    private filterDfineLayer = (filters: MapFilters) => {
        if (!this.mapInstance) {
            return;
        }

        if (this.mapInstance.getLayer('dfineSymbol')) {
            const dfineFilters: (string | (string | boolean)[])[] = [
                'all'
            ];

            if (filters.dFineOnlyRvmNetwork) {
                dfineFilters.push(['==', 'onRvmNetwork', true]);
            }

            this.mapInstance.setFilter('dfineSymbol', dfineFilters);
        }
    };

    private filterFdLayer = (filters: MapFilters) => {
        if (!this.mapInstance) {
            return;
        }
        if (this.mapInstance.getLayer('fdLine')) {
            const fdFilter: (string | (string | boolean)[])[] = [
                'all'
            ];
            if (filters.fdVerifiedTrafficJams) {
                fdFilter.push(['==', 'isVerified', false]);
            }
            this.mapInstance.setFilter('fdLine', fdFilter);
            this.mapInstance.setFilter('fdHeadIcon', [...fdFilter, this.mapLayerBaseFilters['fdHeadIcon']]);
        }
    };

    private filterPrefixes = ({prefixFilter}: MapFilters) => {
        if (!this.mapInstance) {
            return;
        }

        const flowSpeedPrefixes = prefixFilter.flowSpeed.prefixes;
        const travelTimeFcdPrefixes = prefixFilter.travelTimeFcd.prefixes;
        for (const mapLayerId of prefixFilter.flowSpeed.mapLayerIDs) {
            if (this.mapInstance.getLayer(mapLayerId)) {
                const flowSpeedMapFilter: any = [
                    'all'
                ];

                if (flowSpeedPrefixes.length) {
                    flowSpeedMapFilter.push(['in', 'prefix', ...flowSpeedPrefixes]);
                }
                this.mapInstance.setFilter(mapLayerId, flowSpeedMapFilter);
            }
        }

        for (const mapLayerId of prefixFilter.travelTimeFcd.mapLayerIDs) {
            if (this.mapInstance.getLayer(mapLayerId)) {
                const travelTimeFcdMapFilter: any = [
                    'all'
                ];

                if (travelTimeFcdPrefixes.length) {
                    travelTimeFcdMapFilter.push(['in', 'prefix', ...travelTimeFcdPrefixes]);
                }

                this.mapInstance.setFilter(mapLayerId, travelTimeFcdMapFilter);
            }
        }
    };

    private setHighlightSourceFeatures = (features: MapFeature[]) => {
        if (!this.mapInstance) {
            return;
        }

        const dataSource: GeoJSONSource = this.mapInstance.getSource('highlightSource') as GeoJSONSource;

        if (!dataSource) {
            return;
        }

        dataSource.setData({
            type: 'FeatureCollection',
            features: features.map<Feature>((feature: MapFeature) => ({
                type: 'Feature',
                geometry: feature.geometry || {
                    type: 'Point',
                    coordinates: [1, 1]
                },
                id: feature.id,
                properties: {}
            }))
        });
    };

    private setupHighLightLayer = (): void => {
        if (!this.mapInstance) {
            return;
        }

        this.mapInstance.addSource('highlightSource', {
            'type': 'geojson',
            'data': {'type': 'FeatureCollection', 'features': []}
        });

        this.mapInstance.addLayer({
            'source': 'highlightSource',
            'id': 'highlightLayerSymbol',
            'type': 'symbol',
            'layout': {
                'icon-image': 'ndw-other-25',
                'icon-allow-overlap': true,
                'icon-offset': [0, -5]
            }
        });
        this.mapInstance.addLayer({
            'source': 'highlightSource',
            'id': 'highlightLayerLine',
            'type': 'line',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-width': 2,
                'line-color': '#F7893C',
                'line-offset': 5
            }
        });
    };

    private notifyNumberOfFeaturesVisibleOnMap() {
        if (!this.mapInstance) {
            return;
        }

        const amountOfVisibleFeatures = this.mapInstance.queryRenderedFeatures(undefined, {layers: this.activeLayerIDs}).length;

        const shouldNotify = amountOfVisibleFeatures !== this.previousVisibleFeaturesCount;

        if (shouldNotify || (amountOfVisibleFeatures === 0 && this.previousVisibleFeaturesCount === -1)) {
            const notifyNumberOfFeatures = () => {
                if (!this.mapInstance) {
                    return;
                }
                const delayedAmountOfVisibleFeatures = this.mapInstance.queryRenderedFeatures(undefined, {layers: this.activeLayerIDs}).length;

                if (this.previousVisibleFeaturesCount !== delayedAmountOfVisibleFeatures) {
                    this.props.numberOfFeaturesVisibleOnMap(delayedAmountOfVisibleFeatures);
                    this.previousVisibleFeaturesCount = delayedAmountOfVisibleFeatures;
                }
            };

            if (shouldNotify) {
                notifyNumberOfFeatures();
            } else {
                setTimeout(notifyNumberOfFeatures, this.numberOfFeaturesCalculationDelayMs);
            }
        }
    }
}

export default MapboxGL;
