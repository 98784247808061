import React from 'react';
import {Props} from './Props';
import {LevelDash} from '../LevelDash';
import DateTimeFormatted from '../../../../../../../../components/DateTimeFormatted';

export const OtmConstraintTimeWindow: React.FunctionComponent<Props> = ({constraint, level}) => {

    return <>
        <p style={{fontWeight: 'bold'}}>
            <LevelDash level={level}/>

            Time Window Constraint:
        </p>

        <p>Start tijd: <DateTimeFormatted dateTime={constraint.startTime}/></p>
        <p>Eind tijd: <DateTimeFormatted dateTime={constraint.endTime}/></p>

        <br/>
    </>;
};
