import {StyleSheet} from 'aphrodite/no-important';

export const darkStyles = StyleSheet.create({
    label: {}
});

const styles = StyleSheet.create({
    label: {
        display: 'block',
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: 5,
        marginTop: 10
    }
});

export default styles;
