import {StyleSheet} from 'aphrodite';

export const styledInput = {
    handle: {
        zIndex: 0,
        borderRadius: '10px'
    },
    root: {
        height: '0.4rem'
    }
};

const styles = StyleSheet.create({
    container: {
        width: 'calc(100% - 2px)'
    },
    input: {
        '::placeholder': {
            fontStyle: 'italic'
        },
        ':disabled': {
            color: '#ccc'
        },
        'border': '1px solid #0a244d',
        'borderRadius': '.25rem',
        'color': '#495057',
        'padding': '.75rem',
        'width': 'calc(100% - .75rem - .75rem)'
    },
    rangeLabel: {
        'display': 'block',
        'margin-top': '5px'
    }
});

export default styles;
