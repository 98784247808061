import {isActionOf} from 'typesafe-actions';
import {filter, map} from 'rxjs/operators';
import {changeCurrentTheme, changeCurrentTrafficCenter} from '../../../modules/UserSettingsReducer/actions';
import {combineEpics, Epic} from 'redux-observable';
import {mapSceneSettingsPaneSelectedNewTheme} from '../../../scenes/MapScene/actions/settingsPane';
import {selectedNewTrafficCenter} from '../../../scenes/MapScene/actions/reducers/filterAndSearch';

const changeCurrentTrafficCenterOnSelectedNewTrafficCenter: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(selectedNewTrafficCenter)),
        map(({payload: {newTrafficCenter}}) => changeCurrentTrafficCenter(newTrafficCenter))
    );

const changeCurrentThemeOnSelectedNewTheme: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(mapSceneSettingsPaneSelectedNewTheme)),
        map(({payload: {newThemeID}}) => changeCurrentTheme(newThemeID))
    );

const userSettingsPanelEpics: Epic = combineEpics(
    changeCurrentTrafficCenterOnSelectedNewTrafficCenter,
    changeCurrentThemeOnSelectedNewTheme
);

export default userSettingsPanelEpics;
