import {Feature} from 'geojson';

export const handleRowClick = (props: TableClickProps, geoJsonFeature?: Feature): void => {
    if (geoJsonFeature) {
        if (geoJsonFeature.geometry.type === 'Point') {
            props.clickedOnTableRow(geoJsonFeature.geometry.coordinates[1],
                geoJsonFeature.geometry.coordinates[0]);
        } else if (geoJsonFeature.geometry.type === 'LineString') {
            props.clickedOnTableRow(geoJsonFeature.geometry.coordinates[0][1],
                geoJsonFeature.geometry.coordinates[0][0]);
        }
    }
};

export interface TableClickProps {
    clickedOnTableRow: (latitude: number, longitude: number) => void;
}
