/* tslint:disable:import-spacing */
import React, {MouseEvent as ReactMouseEvent, ReactElement, ReactNode} from 'react';
import {Props} from './Props';
import {css} from 'aphrodite/no-important';
import styles, {darkStyles} from './styles';
import {State} from './State';
import OverviewAllSituationOnda from '../../../../components/OverviewAllTables/components/OverviewAllSituationOnda';
import OverviewAllWazeAlertIncident
    from '../../../../components/OverviewAllTables/components/OverviewAllWazeAlertIncident';
import {OverviewAllTable, OverviewTableSource, OverviewTableSourceType} from '../../../../interfaces/OverviewAllTable';
import OverviewAllRoadWork from '../../../../components/OverviewAllTables/components/OverviewAllRoadWork';
import OverviewAllSituationBridge from '../../../../components/OverviewAllTables/components/OverviewAllSituationBridge';
import OverviewSituationAllTrafficMessage
    from '../../../../components/OverviewAllTables/components/OverviewAllTrafficMessage';
import OverviewAllWazeAlertOther from '../../../../components/OverviewAllTables/components/OverviewAllWazeAlertOther';
import {wazeTypesOptions} from '../../../../constants';
import ThemeContext from '../../../../contexts/ThemeContext';
import OverviewTableSourceButton from './components/OverviewTableSourceButton';
import {DataSourceState} from '../../reducers/dataReducer';
import {DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC, DataSourceGeoJson} from '../../../../interfaces/DataSource';
import {SituationFeature} from '../../../../interfaces/SituationFeature';
import {WazeFeature} from '../../../../interfaces/WazeFeature';
import MessageAgeInMinutes from '../../../../components/MessageAgeInMinutes';

class OverviewTable extends React.Component<Props, State> {

    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;

    constructor(props: Props) {
        super(props);

        this.state = {
            dragIsEnabled: false
        };
    }

    componentDidMount(): void {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    }

    onMouseDownDragHandle = (event: ReactMouseEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            dragIsEnabled: true
        });
    };

    onMouseUp = (event: MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        if (!this.state.dragIsEnabled) {
            return;
        }

        this.setState({
            dragIsEnabled: false
        });
    };

    onMouseMove = (event: MouseEvent): void => {
        const props: Readonly<Props> = this.props;
        const state: Readonly<State> = this.state;
        const {dragIsEnabled} = state;

        if (!dragIsEnabled) {
            return;
        }

        props.updateOverviewTableHeight(event.clientY);
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {
            currentVisibleTable,
            currentVisibleTableSource,
            overviewTablesVisible,
            overviewAllData
        } = props;
        const state: Readonly<State> = this.state;
        const {dragIsEnabled} = state;

        if (!overviewTablesVisible) {
            return <React.Fragment/>;
        }

        return <React.Fragment>
            <div className={css(styles.container)}>
                {currentVisibleTable && <>
                    <ul className={css(styles.sourceContainer)}>
                        {currentVisibleTable.sources.map((source: OverviewTableSource) => <OverviewTableSourceButton
                            active={(currentVisibleTableSource && currentVisibleTableSource.sourceType === source.sourceType) || false}
                            source={source}
                            onOverviewTableChangeOverviewTableSource={props.onOverviewTableChangeOverviewTableSource}
                            key={source.sourceType}
                        />)}
                    </ul>
                </>}
                <div
                    className={css(
                        styles.dragHandle,
                        this.context.currentColorSchemeIsDark && darkStyles.dragHandle
                    )}
                    onMouseDown={this.onMouseDownDragHandle}
                    style={{cursor: dragIsEnabled ? 'grabbing' : 'row-resize'}}
                >
                    <div className={css(styles.dragHandleLine)}/>
                    <div
                        className={css(
                            styles.dragHandleDots,
                            this.context.currentColorSchemeIsDark && darkStyles.dragHandleDots
                        )}
                    >
                        •••
                    </div>
                </div>

                <div className={css(styles.tableContainer)}>
                    {currentVisibleTableSource && this.renderTable(currentVisibleTableSource, overviewAllData)}
                </div>

                {
                    this.renderLastSyncTimestamp(overviewAllData, currentVisibleTable, currentVisibleTableSource)
                }
            </div>
        </React.Fragment>;
    }

    private renderTable = (overviewTableSource: OverviewTableSource, overviewAllData: DataSourceState | null): ReactNode => {
        const props: Readonly<Props> = this.props;
        const {mapFilter, showRecordIds} = props;
        switch (overviewTableSource.sourceType) {
            case OverviewTableSourceType.WazeAlertIncident:
                overviewAllData = overviewAllData as DataSourceState;
                const wazeIncidents = this.getFeatureTableData(overviewAllData, overviewTableSource, ['wazeAlertIncident']) as WazeFeature[];
                return <OverviewAllWazeAlertIncident
                    data={wazeIncidents}
                    wazeDataTypes={wazeTypesOptions}
                    mapFilter={mapFilter}
                    showRecordIds={showRecordIds}
                    suppressRowClickSelection={!props.mapMovementAvailable}
                    clickedOnTableRow={(latitude: number, longitude: number) =>
                        props.clickedOnTableRow(latitude, longitude)}
                    onSaveWazeAlertData={props.onSaveWazeAlertData}
                />;
            case OverviewTableSourceType.WazeAlertOther:
                overviewAllData = overviewAllData as DataSourceState;
                const wazeOthers = this.getFeatureTableData(overviewAllData, overviewTableSource, ['wazeAlertOther']) as WazeFeature[];

                return <OverviewAllWazeAlertOther
                    data={wazeOthers}
                    mapFilter={mapFilter}
                    showRecordIds={showRecordIds}
                    suppressRowClickSelection={!props.mapMovementAvailable}
                    clickedOnTableRow={(latitude: number, longitude: number) =>
                        props.clickedOnTableRow(latitude, longitude)}
                />;
            case OverviewTableSourceType.SituationOnda:
                overviewAllData = overviewAllData as DataSourceState;
                const ondaData = this.getFeatureTableData(overviewAllData, overviewTableSource, ['situationOnda']) as SituationFeature[];
                return <OverviewAllSituationOnda
                    data={ondaData}
                    showRecordIds={showRecordIds}
                    suppressRowClickSelection={!props.mapMovementAvailable}
                    clickedOnTableRow={(latitude: number, longitude: number) =>
                        props.clickedOnTableRow(latitude, longitude)}
                />;
            case OverviewTableSourceType.SituationTrafficMessages:
                overviewAllData = overviewAllData as DataSourceState;
                const trafficData = this.getFeatureTableData(overviewAllData, overviewTableSource, ['situationTrafficMessageOtis', 'situationTrafficMessageFlisvis']) as SituationFeature[];
                return <OverviewSituationAllTrafficMessage
                    data={trafficData}
                    showRecordIds={showRecordIds}
                    suppressRowClickSelection={!props.mapMovementAvailable}
                    clickedOnTableRow={(latitude: number, longitude: number) =>
                        props.clickedOnTableRow(latitude, longitude)}
                />;
            case OverviewTableSourceType.SituationBridge:
                overviewAllData = overviewAllData as DataSourceState;
                const bridgeData = this.getFeatureTableData(overviewAllData, overviewTableSource, ['situationBridge']) as SituationFeature[];
                return <OverviewAllSituationBridge
                    data={bridgeData}
                    showRecordIds={showRecordIds}
                    suppressRowClickSelection={!props.mapMovementAvailable}
                    clickedOnTableRow={(latitude: number, longitude: number) =>
                        props.clickedOnTableRow(latitude, longitude)}
                />;
            case OverviewTableSourceType.SituationRoadWorkActual:
                overviewAllData = overviewAllData as DataSourceState;
                const roadWorkData = this.getFeatureTableData(overviewAllData, overviewTableSource, ['situationRoadWork']) as SituationFeature[];
                return <OverviewAllRoadWork
                    data={roadWorkData}
                    showRecordIds={showRecordIds}
                    suppressRowClickSelection={!props.mapMovementAvailable}
                    clickedOnTableRow={(latitude: number, longitude: number) =>
                        props.clickedOnTableRow(latitude, longitude)}
                />;
            default:
                return <React.Fragment/>;
        }
    };

    private getFeatureTableData(overviewAllData: DataSourceState, overviewTableSource: OverviewTableSource, dataTypes: string[]) {
        const dataSource = overviewAllData[overviewTableSource.dataSource] as DataSourceGeoJson;
        return (dataSource && dataSource.layerMapData) ? (dataSource.layerMapData.features as unknown as {properties: {dataType: string, trafficCenter: string}}[])
            .filter(situationFeature => dataTypes.includes(situationFeature.properties.dataType))
            .filter(situationFeature => !this.props.currentTrafficCenter || situationFeature.properties.trafficCenter === this.props.currentTrafficCenter.key): [];
    }

    private renderLastSyncTimestamp = (
        dataSourceState: DataSourceState | null,
        currentVisibleTable: OverviewAllTable | null,
        currentVisibleTableSource: OverviewTableSource | null
    ): ReactNode => {
        if (currentVisibleTableSource && dataSourceState) {
            const dataSource = dataSourceState[currentVisibleTableSource.dataSource];
            const tableName = `${currentVisibleTable?.name || '-'} / ${currentVisibleTableSource?.name || '-'}`;
            if (dataSource && dataSource.status === DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC && dataSource.lastUpdateReceived) {
                return <div
                    className={css(styles.outOfSync, this.context.currentColorSchemeIsDark && darkStyles.outOfSync)}>
                    {`Leeftijd van ${tableName}: `}<MessageAgeInMinutes time={dataSource.lastUpdateReceived}
                        seconds={true}/>
                </div>;
            }
        }

        return <React.Fragment/>;
    };

}

export default OverviewTable;
