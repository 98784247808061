import {
    mapSceneMapboxLayersActivateMapLayer,
    mapSceneMapboxLayersDeactivateMapLayer,
    mapSceneMapboxLayersUpdateMapLayerConfiguration,
    mapSceneMapboxLayersUpdatePrefixData,
    MapSceneMapboxLayersActionTypes
} from '../actions/reducers/mapboxLayers';
import {MapLayer} from '../../../interfaces/MapLayer';
import {getType} from 'typesafe-actions';
import {PrefixFilterOptions} from '../../../interfaces/PrefixFilterOptions';
import {Reducer} from 'redux';

export interface LayersState {
    [key: string]: MapLayer;
}

export interface MapSceneMapboxLayersReducerState {
    layers: LayersState;
    prefixes: PrefixFilterOptions;
}

const initialState: MapSceneMapboxLayersReducerState = {
    layers: {},
    prefixes: {
        flowSpeed: [],
        travelTimeFcd: []
    }
};

const mapboxLayersReducer: Reducer<MapSceneMapboxLayersReducerState, MapSceneMapboxLayersActionTypes> =
    (state: MapSceneMapboxLayersReducerState = initialState, action: MapSceneMapboxLayersActionTypes): MapSceneMapboxLayersReducerState => {
        switch (action.type) {
            case getType(mapSceneMapboxLayersActivateMapLayer):
                return {
                    ...state,
                    layers: {
                        ...state.layers,
                        [action.payload.mapLayer.id]: {
                            ...action.payload.mapLayer,
                            isActive: true
                        }
                    }
                };
            case getType(mapSceneMapboxLayersDeactivateMapLayer):
                return {
                    ...state,
                    layers: {
                        ...state.layers,
                        [action.payload.mapLayer.id]: {
                            ...state.layers[action.payload.mapLayer.id],
                            isActive: false
                        }
                    }
                };
            case getType(mapSceneMapboxLayersUpdateMapLayerConfiguration):
                return {
                    ...state,
                    layers: action.payload.mapLayers
                };
            case getType(mapSceneMapboxLayersUpdatePrefixData):
                return {
                    ...state,
                    prefixes: {
                        flowSpeed: action.payload.prefixes
                            .filter((prefix) => prefix.type === 'FLOW_SPEED')
                            .map((prefix) => ({value: prefix.prefix, label: prefix.prefix}))
                            .sort((a, b) => (a.value > b.value ? 1 : -1)),
                        travelTimeFcd: action.payload.prefixes
                            .filter((prefix) => prefix.type === 'TRAVEL_TIME_FCD')
                            .map((prefix) => ({value: prefix.prefix, label: prefix.prefix}))
                            .sort((a, b) => (a.value > b.value ? 1 : -1))
                    }
                };
            default:
                return state;
        }
    };

export default mapboxLayersReducer;
