import {Props} from './Props';
import React from 'react';

export const DialogTruckParking: React.FunctionComponent<Props> = ({properties}) => {

    return <>
        <table>
            <tbody>
                <tr>
                    <th>ID</th>
                    <td>{properties.id}</td>
                </tr>
                <tr>
                    <th>Aantal parkeerplaatsen</th>
                    <td>{properties.numberOfParkingSpaces}</td>
                </tr>
                <tr>
                    <th>Vrije parkeerplaatsen</th>
                    <td>{properties.numberOfVacantSpaces}</td>
                </tr>
                <tr>
                    <th>Bezette parkeerplaatsen</th>
                    <td>{properties.numberOfOccupiedSpaces}</td>
                </tr>
                <tr>
                    <th>Contactpersoon</th>
                    <td>{properties.operatorName}</td>
                </tr>
                <tr>
                    <th>Telefoonnummer</th>
                    <td>{properties.operaterContactDetailsTelephoneNumber}</td>
                </tr>
            </tbody>
        </table>
    </>;
};
