import React, {ReactElement} from 'react';
import {Props} from './Props';
import styles, {darkStyles} from './styles';
import {css} from 'aphrodite';
import ThemeContext from '../../contexts/ThemeContext';

class StyledSelect extends React.Component<Props> {

    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {disabled, value, onChange} = props;

        return <select
            className={css(
                styles.select,
                this.context.currentColorSchemeIsDark && darkStyles.select
            )}
            disabled={disabled}
            value={value}
            onChange={onChange}
        >
            {this.props.children}
        </select>;
    }

}

export default StyledSelect;
