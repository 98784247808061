import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    button: {
        'all': 'unset',
        'display': 'inline-block',
        'padding': '.5rem',
        'cursor': 'pointer'
    },
    hover: {
        ':hover': {
            color: '#007bff'
        },
        ':focus': {
            color: '#007bff'
        }
    }
});

export default styles;
