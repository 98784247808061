import React, {useMemo} from 'react';
import {Props} from './Props';
import {formatDateTime} from '../../utils/DateUtil';

const DateTimeFormatted: React.FunctionComponent<Props> = ({dateTime}) => {
    const dateFormatted = useMemo(() => {
        const date = new Date(dateTime);
        if (isNaN(date.getTime())) {
            return <React.Fragment>-</React.Fragment>;
        }

        return formatDateTime(date);
    }, [dateTime]);

    return <span>{dateFormatted}</span>;
};

export default DateTimeFormatted;
