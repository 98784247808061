import {combineEpics, Epic} from 'redux-observable';
import {filter, ignoreElements, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {mapSceneUpdateNotificationMessage} from '../../../scenes/MapScene/actions/notificationPane';
import {updateNotificationMessage} from '../../../services/ViewerApiService';
import {viewerApiServiceSavedNotificationMessage} from '../../../services/ViewerApiService/actions/notificationPane';
import {toast} from 'react-toastify';

const showToastOnViewerApiServiceSavedNotificationMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceSavedNotificationMessage)),
        tap(() => {
            toast('De notificatie is opgeslagen.', {
                type: 'success'
            });
        }),
        ignoreElements()
    );

const updateNotificationMessageOnMapSceneUpdateNotificationMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneUpdateNotificationMessage)),
        mergeMap(({payload: {message}}) => updateNotificationMessage(message))
    );

const notificationPaneEpics: Epic = combineEpics(
    showToastOnViewerApiServiceSavedNotificationMessage,
    updateNotificationMessageOnMapSceneUpdateNotificationMessage
);

export default notificationPaneEpics;
