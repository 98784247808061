import {ActionType, createAction} from 'typesafe-actions';
import {FrontOfficeEventStatus} from '../../../../constants';
import {BridgeHistoryDTO, SegmentHeatMapDTO} from '../../../../generated/ViewerApiClient';
import {
    ActiveDialogGeoJsonFeatureFlowSpeedGraphData,
    ActiveDialogGeoJsonFeatureWazeAlertIncident,
    ActiveDialogVectorLayerFeatureTravelTimeGraphData,
    DIALOG_INTERFACES,
    SavingStatus
} from '../../../../interfaces/ActiveDialog';
import {WAZE_ALERT_ITEM_SUB_TYPES, WAZE_ALERT_ITEM_TYPES} from '../../../../interfaces/WazeAlertItemStatus';

export const mapSceneActiveDialogAddNewDialog = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_ADD_NEW_DIALOG',
    (action) => (dialog: DIALOG_INTERFACES) => action({dialog})
);

export const openNewDialog = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_OPEN_NEW_DIALOG',
    (action) => (dialog: DIALOG_INTERFACES) => action({dialog})
);

export const closeCurrentDialog = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_CLOSE_CURRENT_DIALOG',
    (action) => (id: string | number) => action({id})
);

export const closeWazeDialogAfterTimeout = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_CLOSE_WAZE_DIALOG_AFTER_TIMEOUT',
    (action) => (id: string) => action({id})
);

export const closeHighestIndexDialog = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_CLOSE_HIGHEST_INDEX_DIALOG',
    (action) => () => action({})
);

export const changeWazeAlertIncidentStatusValue = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_CHANGE_WAZE_ALERT_INCIDENT_STATUS_VALUE',
    (action) => (
        id: string,
        newStatus: FrontOfficeEventStatus | undefined,
        hectometrePost: string | undefined,
        type: WAZE_ALERT_ITEM_TYPES | undefined,
        subType: WAZE_ALERT_ITEM_SUB_TYPES | undefined
    ) =>
        action({id, newStatus, hectometrePost, type, subType})
);

export const openNewWazeDialog = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_OPEN_NEW_WAZE_DIALOG',
    (action) => (dialog: ActiveDialogGeoJsonFeatureWazeAlertIncident) => action({dialog})
);

export const makeDialogProminent = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_MAKE_DIALOG_PROMINENT',
    (action) => (index: number, preview: boolean) => action({index, preview})
);

export const removePopupsWithMapSource = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_REMOVE_POPUPS_WITH_MAP_SOURCE',
    (action) => (mapSourceID: string) => action({mapSourceID})
);

export const removeUnpinnedActiveDialogs = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_REMOVE_UNPINNED_ACTIVE_DIALOGS',
    (action) => () => action({})
);

export const setNewWazeAlertSavingStatus = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_SET_NEW_WAZE_ALERT_SAVING_STATUS',
    (action) => (id: string, savingStatus: SavingStatus | null) => action({id, savingStatus})
);

export const storeNewBridgeHistoricalGraphData = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_STORE_NEW_BRIDGE_HISTORICAL_GRAPH_DATA',
    (action) => (risIndex: string, vild: string, bridgeHistoryData: BridgeHistoryDTO) => action({
        bridgeHistoryData,
        risIndex,
        vild
    })
);

export const storeNewGraphData = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_STORE_NEW_GRAPH_DATA',
    (action) => (
        mapSourceID: string,
        featureID: number | string,
        graphData: ActiveDialogVectorLayerFeatureTravelTimeGraphData | ActiveDialogGeoJsonFeatureFlowSpeedGraphData
    ) =>
        action({mapSourceID, featureID, graphData})
);

export const mapSceneActiveDialogUpdateSegmentHeatMapData = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_UPDATE_SEGMENT_HEAT_MAP_DATA',
    (action) => (featureId: string, featureKey: number, segmentHeatMapData: SegmentHeatMapDTO) => action({
        featureId,
        featureKey,
        segmentHeatMapData
    })
);

export const updateActiveDialogCountUnpinned = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_UPDATE_ACTIVE_DIALOG_COUNT_UNPINNED',
    (action) => () => action({})
);

export const updateActiveDialogPinStatus = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_UPDATE_ACTIVE_DIALOG_PIN_STATUS',
    (action) => (index: number, newStatus: boolean) => action({index, newStatus})
);

export const updateWazeAlertDialogTimeRemaining = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_UPDATE_WAZE_ALERT_DIALOG_TIME_REMAINING',
    (action) => (id: string, newValue: number) => action({id, newValue})
);

export const updateWazeAlertIncidentDialogStatusData = createAction(
    'MAP_SCENE_ACTIVE_DIALOG_UPDATE_WAZE_ALERT_INCIDENT_DIALOG_STATUS_DATA',
    (action) =>
        (
            id: string,
            hasLoadedData: boolean,
            loadingDataHasFailed: boolean,
            status: FrontOfficeEventStatus | null,
            isOpen: boolean,
            hectometrePost: string | null
        ) => action({id, hasLoadedData, loadingDataHasFailed, status, isOpen, hectometrePost})
);

export type MapSceneActiveDialogReducerActionTypes = ActionType<typeof mapSceneActiveDialogAddNewDialog> |
    ActionType<typeof closeCurrentDialog> |
    ActionType<typeof closeHighestIndexDialog> |
    ActionType<typeof changeWazeAlertIncidentStatusValue> |
    ActionType<typeof makeDialogProminent> |
    ActionType<typeof removePopupsWithMapSource> |
    ActionType<typeof removeUnpinnedActiveDialogs> |
    ActionType<typeof setNewWazeAlertSavingStatus> |
    ActionType<typeof storeNewBridgeHistoricalGraphData> |
    ActionType<typeof storeNewGraphData> |
    ActionType<typeof updateActiveDialogCountUnpinned> |
    ActionType<typeof updateWazeAlertDialogTimeRemaining> |
    ActionType<typeof updateActiveDialogPinStatus> |
    ActionType<typeof updateWazeAlertIncidentDialogStatusData> |
    ActionType<typeof mapSceneActiveDialogUpdateSegmentHeatMapData>;
