import React, {ReactElement} from 'react';
import {Props} from './Props';
import TimeFormatted from '../../../../../../components/TimeFormatted';

class DialogDfine extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Tijdstip</th>
                        <td><TimeFormatted dateTime={properties.creationTime}/></td>
                    </tr>
                    <tr>
                        <th>Versie tijd</th>
                        <td><TimeFormatted dateTime={properties.versionTime}/></td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td>{properties.vehicleObstructionType === 'vehicleInDifficulty' ? 'Voertuig in moeilijkheden' : 'Pechgeval'}</td>
                    </tr>
                    <tr>
                        <th>Locatie</th>
                        <td>{
                            properties.hectometrePostName !== '' ?
                                properties.hectometrePostLetter + properties.hectometrePostNumber + ' - ' + properties.hectometrePostName
                                : 'Geen hectometrering'
                        }</td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>;
    }
}

export default DialogDfine;
