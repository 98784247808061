import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    closedText: {
        color: '#28a745',
        marginBottom: '0.5rem'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputFlex: {
        flex: 1
    },
    loadingDataText: {
        color: '#ffc107'
    },
    openText: {
        color: '#dc3545',
        fontWeight: 'bold'
    },
    saveButtonFlex: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    },
    unusedText: {
        color: '#dc3545',
        fontWeight: 'bold',
        marginBottom: '1rem',
        marginTop: '1rem'
    }
});

export default styles;
