import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    gridContainer: {
        flex: 1,
        maxHeight: 600,
        width: '100%'
    }
});

export default styles;
