import React, {ReactElement} from 'react';
import {Props} from './Props';
import {State, TravelTimeTab} from './State';
import TabBar from '../../../../../../components/TabBar';
import {TabBarItem} from '../../../../../../components/TabBar/Props';
import StyledButton from '../../../../../../components/StyledButton';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import SimpleGraph from '../../../../../../components/SimpleGraph';
import Plot from 'react-plotly.js';

class DialogTravelTime extends React.Component<Props, State> {

    tabBarItems: TabBarItem<TravelTimeTab>[];
    chartColors: { actual: string, freeFlow: string };
    locationID: string | null;

    constructor(props: Props) {
        super(props);

        this.state = {
            currentTab: TravelTimeTab.travelTime,
            tabTimestamp: 3600
        };

        this.tabBarItems = [
            {
                id: TravelTimeTab.travelTime,
                name: 'Reistijd'
            },
            {
                id: TravelTimeTab.velocity,
                name: 'Snelheid'
            }
        ];

        // if (props.mapSourceID === 'travelTimeFcd') {
        //     this.tabBarItems.push({
        //         id: TravelTimeTab.heatMap,
        //         name: 'Heatmap'
        //     });
        // }

        this.chartColors = {
            actual: '#ac183d',
            freeFlow: '#3cb44b'
        };

        this.locationID = null;
    }

    componentDidMount(): void {
        const props: Readonly<Props> = this.props;
        const {properties} = props;

        try {
            const parsed = JSON.parse(properties.locations);
            this.locationID = parsed[0];
            this.triggerOpenDialog();
        } catch (e) {
            return;
        }
    }

    componentWillUnmount(): void {
        const props: Readonly<Props> = this.props;
        const {properties, mapSourceID} = props;
        props.closeDialog(mapSourceID, properties.id);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const props: Readonly<Props> = this.props;
        const {properties} = props;

        if ((properties.locations && !prevProps) || (properties.locations !== prevProps.properties.locations)) {
            try {
                const parsed = JSON.parse(properties.locations);
                this.locationID = parsed[0];
            } catch (e) {
                return;
            }
        }
    }

    changeCurrentActiveTab = (currentTab: TravelTimeTab): void => {

        this.setState({
            currentTab
        });

        if (currentTab === TravelTimeTab.heatMap) {
            const props: Readonly<Props> = this.props;
            const {properties} = props;

            try {
                const parsed = JSON.parse(properties.locations);
                const locationID = parsed[0];
                this.props.onOpenHeatmapTravelTimeFcd(locationID, properties.id);
            } catch (e) {
                return;
            }
        }
    };

    changeTimestamp = (): void => {
        this.setState({
            tabTimestamp: this.state.tabTimestamp === 3600 ? 86400 : 3600
        }, () => this.triggerOpenDialog());
    };

    triggerOpenDialog = (): void => {
        const props: Readonly<Props> = this.props;
        const {properties, mapSourceID} = props;
        const state: Readonly<State> = this.state;
        const {tabTimestamp} = state;

        props.openGraphDialog(
            mapSourceID,
            properties.id,
            this.locationID!,
            'travelTime',
            tabTimestamp
        );
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, featureState, graphData, showRecordIds, segmentHeatMapData} = props;
        const state: Readonly<State> = this.state;
        const {currentTab, tabTimestamp} = state;

        const {name, length} = properties;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{this.getTrajectoryID()}</td>
                    </tr>}
                    <tr>
                        <th>Naam</th>
                        <td>{name}</td>
                    </tr>
                    <tr>
                        <th>Lengte in meters</th>
                        <td>{length}</td>
                    </tr>
                    <tr>
                        <th>Tijdstempel</th>
                        <td>
                            <DateTimeFormatted dateTime={featureState.timestamp}/>
                        </td>
                    </tr>
                </tbody>
            </table>

            <StyledButton
                onPress={() => this.openInDexterAvg()}
                title={'Historische reistijden en  snelheden bekijken in Dexter'}
            />

            <StyledButton
                onPress={() => this.openInDexterFcd()}
                title={'Historische reistijden en snelheden bekijken in FCD explorer'}
            />

            <br/>

            <TabBar<TravelTimeTab>
                tabBarItems={this.tabBarItems}
                activeId={currentTab}
                onClickedOnTab={(id) => this.changeCurrentActiveTab(id)}
            />

            <div className={css(styles.tabContent)}>
                <div style={{display: currentTab === TravelTimeTab.travelTime ? 'block' : 'none'}}>
                    <table className={'last-column-right'}>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Seconden</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Actueel</td>
                                <td
                                    className={css(styles.tableTravelTimeVelocityRightBorder)}
                                    style={{borderRightColor: this.chartColors.actual}}
                                >
                                    {featureState.travelTime}
                                </td>
                            </tr>
                            <tr>
                                <td>Free flow</td>
                                <td
                                    className={css(styles.tableTravelTimeVelocityRightBorder)}
                                    style={{borderRightColor: this.chartColors.freeFlow}}
                                >
                                    {featureState.freeFlow}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <SimpleGraph
                        data={graphData?.travelTime || []}
                        title={'Voertuigen overzicht'}
                        width={357}
                        revision={graphData?.timeStamp || 0}
                    />

                    <br/>
                    <p>Weergeef data in de grafiek van de afgelopen:</p>
                    <StyledButton
                        onPress={this.changeTimestamp}
                        title={tabTimestamp === 3600 ? '1-uur' : '24-uur'}
                    />
                </div>

                <div style={{display: currentTab === TravelTimeTab.velocity ? 'block' : 'none'}}>
                    <table className={'last-column-right'}>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>KM/h</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Actueel</td>
                                <td
                                    className={css(styles.tableTravelTimeVelocityRightBorder)}
                                    style={{borderRightColor: this.chartColors.actual}}
                                >
                                    {featureState.velocity}
                                </td>
                            </tr>
                            <tr>
                                <td>Free flow</td>
                                <td
                                    className={css(styles.tableTravelTimeVelocityRightBorder)}
                                    style={{borderRightColor: this.chartColors.freeFlow}}
                                >
                                    {featureState.freeFlowSpeed}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <SimpleGraph
                        data={graphData?.velocity || []}
                        title={'Voertuigen overzicht'}
                        width={357}
                        revision={graphData?.timeStamp || 0}
                    />

                    <br/>
                    <p>Weergeef data in de grafiek van de afgelopen:</p>
                    <StyledButton
                        onPress={this.changeTimestamp}
                        title={tabTimestamp === 3600 ? '1-uur' : '24-uur'}
                    />
                </div>

                <div style={{display: currentTab === TravelTimeTab.heatMap ? 'block' : 'none'}}>
                    {segmentHeatMapData && <Plot
                        data={[
                            {
                                x: segmentHeatMapData.x,
                                y: segmentHeatMapData.y,
                                z: segmentHeatMapData.z,
                                type: 'heatmap',
                                colorscale: [
                                    [0, '#FF0000'],
                                    [0.5, '#FFFF00'],
                                    [1, '#3f9c3c']
                                ]
                            }
                        ]}

                        layout={{
                            width: 357,
                            title: 'Tijdwegdiagram',
                            xaxis: {
                                range: segmentHeatMapData.rangeX,
                                autorange: false,
                                showgrid: true,
                                zeroline: false,
                                tickformat: '%H:%M'
                            },
                            yaxis: {
                                range: segmentHeatMapData.rangeY,
                                autorange: false,
                                showgrid: true,
                                zeroline: false,
                                ticks: ''
                            },
                            showlegend: false,
                            autosize: true
                        }}
                    />}
                </div>
            </div>
        </React.Fragment>;
    }

    private getTrajectoryID(): string {
        const props: Readonly<Props> = this.props;
        const {properties} = props;

        try {
            const parsed = JSON.parse(properties.locations);
            return parsed[0];
        } catch (e) {
            return properties.id.toString();
        }
    }

    private openInDexterAvg(): void {
        window.open(`https://dexter.ndw.nu/deeplink/explorer/avg?id=${this.getTrajectoryID()}&type=trajectory`, '_blank')?.focus();
    }

    private openInDexterFcd(): void {
        window.open(`https://dexter.ndw.nu/deeplink/explorer/fcd?id=${this.getTrajectoryID()}`, '_blank')?.focus();
    }
}

export default DialogTravelTime;
