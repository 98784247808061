import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    tabContent: {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderBottomWidth: 1,
        borderColor: '#e78f08',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderTopWidth: 0,
        marginTop: -4,
        padding: 15
    },
    tableFlowSpeedRightBorder: {
        borderRightColor: 'transparent',
        borderRightStyle: 'solid',
        borderRightWidth: 5,
        paddingRight: 5
    }
});

export default styles;
