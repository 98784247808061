import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import MessageAgeInMinutes from '../../../../../../components/MessageAgeInMinutes';

class DialogRoadworkActual extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Type</th>
                        <td>{properties.type}</td>
                    </tr>
                    <tr>
                        <th>Subtype</th>
                        <td>{properties.subType}</td>
                    </tr>
                    <tr>
                        <th>Tijdstip eerste melding</th>
                        <td>
                            <DateTimeFormatted
                                dateTime={properties.overallStartTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Tijd sinds eerste melding</th>
                        <td>
                            <MessageAgeInMinutes
                                time={properties.overallStartTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Tijdstip laatste aanpassing</th>
                        <td>
                            <DateTimeFormatted
                                dateTime={properties.versionTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Tijd sinds laatste aanpassing</th>
                        <td>
                            <MessageAgeInMinutes
                                time={properties.versionTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Bron</th>
                        <td>{properties.source}</td>
                    </tr>
                    <tr>
                        <th>Reden</th>
                        <td>{properties.cause}</td>
                    </tr>
                    <tr>
                        <th>Commentaar</th>
                        <td>{properties.comment}</td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>;
    }
}

export default DialogRoadworkActual;
