import React from 'react';
import {Props} from './Props';
import {css} from 'aphrodite/no-important';
import styles from './styles';

const NavbarItem: React.FunctionComponent<Props> = ({active, description, title, onClick}) => {

    return <li
        className={css(styles.item, active && styles.itemActive)} onClick={onClick}
        title={description}
    >
        <span className={css(styles.text, active && styles.textActive)}>{title}</span>
    </li>;
};

export default NavbarItem;
