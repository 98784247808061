import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    heading: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: '1em'
    }
});

export default styles;
