import {StyleSheet} from 'aphrodite';

export const darkStyles = StyleSheet.create({
    dragHandle: {
        backgroundColor: '#444',
        color: 'white'
    },
    dragHandleDots: {
        backgroundColor: '#444',
        color: 'white'
    },
    outOfSync: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white'
    }
});

const styles = StyleSheet.create({
    container: {
        height: '100%',
        position: 'relative'
    },
    dragHandle: {
        cursor: 'row-resize',
        height: 15,
        position: 'relative',
        width: '100%'
    },
    dragHandleDots: {
        backgroundColor: 'white',
        color: 'lightgrey',
        fontSize: 20,
        left: '50%',
        lineHeight: 0.5,
        paddingLeft: 5,
        paddingRight: 5,
        position: 'absolute',
        top: 3,
        transform: 'translateX(-50%)'
    },
    dragHandleLine: {
        backgroundColor: 'lightgrey',
        height: 1,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '100%'
    },
    outOfSync: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderTopLeftRadius: 5,
        bottom: 0,
        padding: 15,
        position: 'absolute'
    },
    tableContainer: {
        height: 'calc(100% - 15px)',
        display: 'flex'
    },
    sourceContainer: {
        position: 'absolute',
        top: -40,
        left: 10,
        zIndex: 100
    }
});

export default styles;
