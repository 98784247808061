import {StyleSheet} from 'aphrodite/no-important';

export const colorBlindnessStyles = StyleSheet.create({
    iconActive: {
        ':hover': {
            backgroundColor: '#ff7f00'
        },
        'backgroundColor': '#ff7f00',
        'color': '#fff'
    },
    iconWrapper: {
        ':hover': {
            backgroundColor: '#7570b3'
        },
        'color': 'white'
    }
});

export const darkStyles = StyleSheet.create({
    iconWrapper: {
        ':hover': {
            backgroundColor: '#555'
        },
        'color': 'white'
    }
});

export const styles = StyleSheet.create({
    iconActive: {
        ':hover': {
            backgroundColor: '#F8981D'
        },
        'backgroundColor': '#F8981D',
        'color': '#fff'
    },
    iconContent: {
        lineHeight: '40px'
    },
    iconWrapper: {
        ':hover': {
            backgroundColor: '#eee'
        },
        'color': '#333',
        'cursor': 'pointer',
        'height': '40px',
        'transition': 'all 200ms',
        'width': '100%',
        'display': 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    }
});
