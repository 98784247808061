import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    item: {
        cursor: 'pointer'
    },
    itemActive: {
        cursor: 'not-allowed',
        fontWeight: 'bold'
    },
    text: {
        ':hover': {
            color: '#F7893C'
        },
        'color': '#6c757d',
        'paddingLeft': '1rem',
        'paddingRight': '1rem',
        'transition': 'color 0.3s'
    },
    textActive: {
        color: '#F7893C'
    }
});

export default styles;
