import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    iconContainer: {
        backgroundColor: '#f8d7da',
        border: 0,
        borderBottomLeftRadius: '50%',
        borderColor: '#721c24',
        borderRightWidth: '0.5px',
        borderStyle: 'solid',
        borderTopLeftRadius: '50%',
        color: '#721c24',
        cursor: 'pointer',
        fontSize: '12px',
        padding: '8px',
        transition: 'all 0.3s'
    },
    iconContainerActive: {
        backgroundColor: '#d4edda',
        borderColor: '#155724',
        color: '#155724'
    },
    iconContainerLoadingData: {
        backgroundColor: '#fff3cd',
        borderColor: '#856404',
        color: '#856404'
    },
    indentedRow: {
        marginLeft: '11px'
    },
    mapLayerDescription: {
        color: '#b3b3b3',
        display: 'block',
        fontSize: '13px',
        lineHeight: '11px',
        paddingBottom: '1px',
        paddingLeft: '41px'
    },
    mapLayerName: {
        borderBottomColor: '#721c24',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        cursor: 'pointer',
        paddingLeft: '10px',
        paddingRight: '10px',
        transition: 'all 0.3s'
    },
    mapLayerNameActive: {
        borderBottomColor: '#155724'
    },
    mapLayerNameLoadingData: {
        borderBottomColor: '#856404'
    },
    mapLayerRowWrapper: {
        marginBottom: '.5rem'
    },
    mapLayerButtonsWrapper: {
        display: 'flex',
        alignItems: 'baseline'
    },
    mapLayerRowContainer: {
        all: 'unset',
        cursor: 'pointer',
        lineHeight: '30px'
    },
    outdatedText: {
        fontStyle: 'italic'
    },
    explanationBox: {
        'marginBlock': '.5rem',
        'borderRadius': '.5rem',
        'padding': '.5rem',
        'backgroundColor': '#eee',
        'display': 'inline-block',
        'position': 'relative',
        '::after': {
            display: 'inline-block',
            content: '""',
            position: 'absolute',
            bottom: '100%',
            borderWidth: '.5rem',
            borderStyle: 'solid',
            borderColor: 'transparent transparent #eee transparent'
        }
    },
    explanationWrapper: {
        width: '100%'
    }
});

export default styles;
