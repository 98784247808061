import React from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';

const DialogSituationVehicleObstructionEmergencyVehicle: React.FunctionComponent<Props> = ({properties, showRecordIds}) => {
    const {id, type, subType, overallStartTime, mobilityOfObstruction, comment, primaryLocationDescription, trafficCenter} = properties;

    const convertMobilityOfObstruction = (value: string): string => {
        if (value === 'stationary') {
            return 'Stilstaand';
        } else {
            return value;
        }
    };

    return <table>
        <tbody>
            {showRecordIds && <tr>
                <th>ID</th>
                <td>{id}</td>
            </tr>}
            <tr>
                <th>Type</th>
                <td>{type}</td>
            </tr>
            <tr>
                <th>Subtype</th>
                <td>{subType}</td>
            </tr>
            <tr>
                <th>Tijd melding</th>
                <td><DateTimeFormatted dateTime={overallStartTime}/></td>
            </tr>
            <tr>
                <th>Mobiliteit</th>
                <td>{convertMobilityOfObstruction(mobilityOfObstruction)}</td>
            </tr>
            <tr>
                <th>Opmerking</th>
                <td>{comment}</td>
            </tr>
            <tr>
                <th>Locatie</th>
                <td>
                    {primaryLocationDescription}
                </td>
            </tr>
            <tr>
                <th>Verkeerscentrale</th>
                <td>{trafficCenter}</td>
            </tr>
        </tbody>
    </table>;


};

export default DialogSituationVehicleObstructionEmergencyVehicle;
