import {createAction} from 'typesafe-actions';

export const startReloadingInterval = createAction(
    'START_RELOADING_INTERVAL',
    (action) => (milliseconds: number) => action({milliseconds})
);

export const stopReloadingInterval = createAction(
    'STOP_RELOADING_INTERVAL',
    (action) => (milliseconds: number) => action({milliseconds})
);

export const triggerReloadWithTime = createAction(
    'TRIGGER_RELOAD_WITH_TIME',
    (action) => (milliseconds: number) => action({milliseconds})
);
