import {ActionType, createAction} from 'typesafe-actions';
import {AccessDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedAccessData = createAction(
    'VIEWER_API_SERVICE_RECEIVED_ACCESS_DATA',
    (action) => (data: AccessDTO) => action({data})
);

export const viewerApiServiceReceivingAccessDataFailed = createAction(
    'VIEWER_API_SERVICE_RECEIVING_ACCESS_DATA_FAILED',
    (action) => () => action({})
);

export type AccessDataActionTypes = ActionType<typeof viewerApiServiceReceivedAccessData> |
    ActionType<typeof viewerApiServiceReceivingAccessDataFailed>;
