import React from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {Props} from './Props';

const ButtonIcon: React.FunctionComponent<Props> = ({iconClassName, addHoverStyle, ...props}) => {
    return <button
        type="button"
        className={css(styles.button, addHoverStyle && styles.hover)}
        {...props}
    >
        <span className={iconClassName} />
    </button>;
};

export default ButtonIcon;
