import {StyleSheet} from 'aphrodite/no-important';
import {IS_ACCEPTANCE} from '../../../../applicationContext';
import {ACCEPTANCE_STYLE, ACCEPTANCE_STYLE_DARK, NAV_BAR_HEIGHT} from '../../../../styles';

export const darkStyles = StyleSheet.create({
    navigationContainer: {
        backgroundColor: !IS_ACCEPTANCE ? '#444444' : ACCEPTANCE_STYLE_DARK.primary,
        color: 'white'
    }
});

const styles = StyleSheet.create({
    logo: {
        height: '20px',
        width: 'auto'
    },
    logoContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: 60
    },
    navigationContainer: {
        alignItems: 'center',
        backgroundColor: !IS_ACCEPTANCE ? 'white' : ACCEPTANCE_STYLE.primary,
        boxShadow: '0 4px 8px -5px grey',
        display: 'flex',
        height: NAV_BAR_HEIGHT,
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    versionText: {
        color: '#aaa',
        fontSize: '10px',
        paddingTop: '4px',
        width: 64
    }
});

export default styles;
