import {applyMiddleware, compose, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import {createEpicMiddleware} from 'redux-observable';
import rootReducer from './reducer';
import {routerMiddleware} from 'connected-react-router';
import rootEpics from './epics';
import {viewerApplicationBooted} from './actions';
import {composeWithDevTools} from 'redux-devtools-extension';

const reduxDevToolsEnabled = true;
const composeEnhancers = reduxDevToolsEnabled ? composeWithDevTools({
    name: 'NDW Viewer'
}) : compose;

const epicMiddleware = createEpicMiddleware();
export const browserHistory = createBrowserHistory({});

export const store = createStore(
    rootReducer(browserHistory),
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(browserHistory),
            epicMiddleware
        )
    )
);

epicMiddleware.run(rootEpics);
store.dispatch(viewerApplicationBooted());
