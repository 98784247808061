import React, {ReactElement} from 'react';
import {Props} from './Props';
import {css} from 'aphrodite';
import ThemeContext from '../../../../../../contexts/ThemeContext';
import {colorBlindnessStyles, darkStyles, styles} from './styles';
import ButtonIcon from '../../../../../../components/ButtonIcon';

class SidebarButton extends React.Component<Props> {

    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {active, uniqueId, pressedItem, iconClassName, label, ...other} = props;
        const actionLabel = active ? `Verberg ${label}` : `Toon ${label}`;

        return <div
            className={css(
                styles.iconWrapper,
                this.context.currentColorSchemeIsSuitableForColorBlindness && colorBlindnessStyles.iconWrapper,
                active && styles.iconActive,
                this.context.currentColorSchemeIsDark && darkStyles.iconWrapper,
                active && this.context.currentColorSchemeIsSuitableForColorBlindness && colorBlindnessStyles.iconActive
            )}
            data-cy={uniqueId}
            onClick={pressedItem}
            title={actionLabel}
        >
            <ButtonIcon
                iconClassName={iconClassName}
                aria-expanded={active}
                aria-label={actionLabel}
                {...other}
            />
        </div>;
    }

}

export default SidebarButton;
