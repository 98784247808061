import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    listItem: {
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: 1,
        cursor: 'pointer',
        float: 'left',
        transition: 'all 0.3s'
    },
    listItemActive: {
        backgroundColor: 'white',
        borderColor: '#ccc',
        borderRadius: 4,
        color: '#eb8f00'
    },
    listItemContainer: {
        backgroundColor: '#f6a828',
        borderColor: '#e78f08',
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        color: 'white',
        margin: 0,
        overflow: 'hidden',
        padding: 3
    },
    listItemContent: {
        display: 'block',
        padding: '.5em 1em'
    }
});

export default styles;
