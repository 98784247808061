import React from 'react';
import {Props} from './Props';
import {css} from 'aphrodite/no-important';
import styles from './styles';

const StyledBoolean: React.FunctionComponent<Props> = ({value}) => {
    if (value) {
        return <span className={css(styles.trueValue)}>Ja</span>;
    }

    return <span className={css(styles.falseValue)}>Nee</span>;
};

export default StyledBoolean;
