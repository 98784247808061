import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {RootState} from '../../reducer';
import {filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    startReloadingInterval,
    stopReloadingInterval,
    triggerReloadWithTime
} from '../../modules/ReloadingReducer/actions';
import {interval, of} from 'rxjs';
import {DataSource} from '../../interfaces/DataSource';
import {mapSceneDataUpdateDataSource} from '../../scenes/MapScene/actions/reducers/data';
import {RELOADING_INTERVALS} from '../../applicationContext';

const startIntervalOnStartReloadingInterval = (intervalValue: number): Epic => (action$) => action$
    .pipe(
        filter(isActionOf(startReloadingInterval)),
        filter(({payload: {milliseconds}}) => milliseconds === intervalValue),
        switchMap(() => interval(intervalValue)
            .pipe(
                takeUntil(action$.pipe(
                    filter(isActionOf(stopReloadingInterval)),
                    filter(({payload: {milliseconds}}) => milliseconds === intervalValue)
                )),
                map(() => triggerReloadWithTime(intervalValue))
            )
        )
    );

const reloadMapSourceDataOnTriggerReload: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(triggerReloadWithTime)),
        mergeMap(({payload: {milliseconds}}) => {
            const mapSourcesNeedingRefresh: DataSource[] = [];
            Object.keys(state$.value.mapScene.data.sources).forEach((key: string) => {
                const mapSource = state$.value.mapScene.data.sources[key];
                if (mapSource.isActive && mapSource.refreshUpdate && mapSource.updateInterval !== 0 && mapSource.updateInterval === milliseconds) {
                    mapSourcesNeedingRefresh.push(mapSource);
                }
            });
            return of(...mapSourcesNeedingRefresh)
                .pipe(
                    map((dataSource) => mapSceneDataUpdateDataSource(dataSource))
                );
        })
    );

const reloadingEpics: Epic = combineEpics(
    ...RELOADING_INTERVALS.map((value) => startIntervalOnStartReloadingInterval(value)),
    reloadMapSourceDataOnTriggerReload
);

export default reloadingEpics;
