import activeDialogReducer, {MapSceneActiveDialogsReducerState} from './reducers/activeDialogReducer';
import {combineReducers, Reducer} from 'redux';
import generalReducer, {MapSceneGeneralReducerState} from './reducers/generalReducer';
import filterAndSearchReducer, {MapSceneFilterAndSearchReducerState} from './reducers/filterAndSearchReducer';
import summedTrajectoriesGraphReducer, {
    MapSceneSummedTrajectoriesGraphReducerState
} from './reducers/summedTrajectoriesGraphReducer';
import trafficInformationReducer, {MapSceneTrafficInformationReducerState} from './reducers/trafficInformationReducer';
import mapboxLayersReducer, {
    MapSceneMapboxLayersReducerState
} from './reducers/mapboxLayersReducer';
import dataReducer, {MapSceneDataSourcesReducerState} from './reducers/dataReducer';

export interface MapSceneCombinedReducerState {
    activeDialogs: MapSceneActiveDialogsReducerState;
    filterAndSearch: MapSceneFilterAndSearchReducerState;
    general: MapSceneGeneralReducerState;
    mapboxLayers: MapSceneMapboxLayersReducerState;
    data: MapSceneDataSourcesReducerState;
    summedTrajectoriesGraph: MapSceneSummedTrajectoriesGraphReducerState;
    trafficInformation: MapSceneTrafficInformationReducerState;
}

const mapSceneCombinedReducer: Reducer<MapSceneCombinedReducerState> =
    combineReducers<MapSceneCombinedReducerState>({
        activeDialogs: activeDialogReducer,
        filterAndSearch: filterAndSearchReducer,
        general: generalReducer,
        mapboxLayers: mapboxLayersReducer,
        data: dataReducer,
        summedTrajectoriesGraph: summedTrajectoriesGraphReducer,
        trafficInformation: trafficInformationReducer
    });

export default mapSceneCombinedReducer;
