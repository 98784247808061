import {StyleSheet} from 'aphrodite/no-important';

export const colorBlindnessStyles = StyleSheet.create({
    sidebarTabsWrapper: {
        backgroundColor: '#434348',
        color: 'white'
    }
});

export const darkStyles = StyleSheet.create({
    sidebarContainer: {
        backgroundColor: 'rgba(55, 55, 55, 0.95)',
        color: 'white'
    },
    sidebarTabsWrapper: {
        backgroundColor: '#444'
    }
});

export const styles = StyleSheet.create({
    sidebarContainer: {
        '@media (min-width: 768px)': {
            bottom: '30px',
            right: '10px',
            top: '10px',
            width: '480px'
        },
        'backgroundColor': 'rgba(255, 255, 255, 0.95)',
        'bottom': 0,
        'boxShadow': '0 1px 5px rgba(0, 0, 0, 0.65)',
        'position': 'absolute',
        'right': 0,
        'top': 0,
        'transition': 'width 500ms, background-color 500ms, background-color 500ms',
        'width': '100%',
        'zIndex': 30
    },
    sidebarContainerCollapsed: {
        '@media (min-width: 768px)': {
            width: '40px'
        },
        'width': '40px'
    },
    sidebarContentWrapper: {
        bottom: 0,
        left: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'absolute',
        right: '40px',
        top: 0
    },
    sidebarTabsBottom: {
        bottom: 0,
        position: 'absolute',
        right: 0,
        width: '40px'
    },
    sidebarTabsWrapper: {
        backgroundColor: 'white',
        bottom: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '40px'
    }
});
