import React, {useMemo} from 'react';
import {Props} from './Props';
import {OtmConstraint} from '../OtmConstraint';
import {LevelDash} from '../LevelDash';

export const OtmConstraintOr: React.FunctionComponent<Props> = ({constraint, level}) => {

    return <>
        <p style={{fontWeight: 'bold'}}>
            <LevelDash level={level}/>
            Or Constraint:
        </p>

        {useMemo(() => constraint.or.map((orConstraint, index) =>
            <OtmConstraint
                key={index}
                constraint={orConstraint}
                level={level + 1}
            />
        ), [constraint, level])}
    </>;
};
