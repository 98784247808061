import {combineReducers, Reducer} from 'redux';
import {connectRouter, RouterState} from 'connected-react-router';
import {History} from 'history';
import mapSceneReducer, {MapSceneCombinedReducerState} from './scenes/MapScene/reducer';
import userSettingsReducer, {UserSettingsReducerState} from './modules/UserSettingsReducer/reducer';
import {keycloakAuthenticationReducer, KeycloakAuthenticationState} from '@ndw/react-keycloak-authentication';
import trafficCenterReducer, {TrafficCenterState} from './modules/TrafficCenterReducer/reducer';
import notificationModuleReducer, {NotificationReducerState} from './modules/NotificationReducer/reducer';

export interface RootState {
    keycloakAuthentication: KeycloakAuthenticationState;
    mapScene: MapSceneCombinedReducerState;
    notification: NotificationReducerState;
    router: RouterState;
    trafficCenter: TrafficCenterState;
    userSettings: UserSettingsReducerState;
}

const rootReducer = (history: History): Reducer<RootState> => combineReducers<RootState>({
    keycloakAuthentication: keycloakAuthenticationReducer,
    mapScene: mapSceneReducer,
    notification: notificationModuleReducer,
    router: connectRouter(history),
    trafficCenter: trafficCenterReducer,
    userSettings: userSettingsReducer
});

export default rootReducer;
