import {ActionType, createAction} from 'typesafe-actions';
import {SearchFeatureDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedSearchFeaturesData = createAction(
    'VIEWER_API_SERVICE_RECEIVED_SEARCH_FEATURES_DATA',
    (action) => (mapSourceId: string, searchFeatures: SearchFeatureDTO[]) => action({mapSourceId, searchFeatures})
);

export const viewerApiServiceReceivingSearchFeaturesDataFailedWithBadRequestError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_SEARCH_FEATURES_DATA_FAILED_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingSearchFeaturesDataFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_SEARCH_FEATURES_DATA_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingSearchFeaturesDataFailedWithNotFoundError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_SEARCH_FEATURES_DATA_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingSearchFeaturesDataFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_SEARCH_FEATURES_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type LoadSearchFeaturesDataActionTypes = ActionType<typeof viewerApiServiceReceivedSearchFeaturesData> |
    ActionType<typeof viewerApiServiceReceivingSearchFeaturesDataFailedWithBadRequestError> |
    ActionType<typeof viewerApiServiceReceivingSearchFeaturesDataFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceReceivingSearchFeaturesDataFailedWithNotFoundError> |
    ActionType<typeof viewerApiServiceReceivingSearchFeaturesDataFailedWithUnexpectedError>;
