import {
    AbnormalTrafficSituationFeatureProperties,
    RoadOrCarriagewayOrLaneManagementSituationFeatureProperties
} from '../../interfaces/SituationFeature';
import {formatDateTimeWithDayOfWeek, formatTime} from '../../utils/DateUtil';
import {GeoJsonPropertiesSituation} from '../../interfaces/GeoJsonSources/GeoJsonPropertiesSituation';

const generateAccidentMessage = (properties: GeoJsonPropertiesSituation) => {
    switch (properties.subType) {
        case 'accident':
        case 'accidentInvolvingBicycles':
        case 'accidentInvolvingMopeds':
        case 'accidentInvolvingMotorcycles':
        case 'vehicleOffRoad':
            return 'Ongeval';
        case 'accidentInvolvingBuses':
            return 'Ongeval met bus';
        case 'accidentInvolvingHazardousMaterials':
            return 'Ongeval met gevaarlijke stoffen';
        case 'accidentInvolvingHeavyLorries':
            return 'Ongeval met vrachtwagen';
        case 'accidentInvolvingMassTransitVehicle':
            return 'Ongeval met trein of bus';
        case 'accidentInvolvingRadioactiveMaterial':
            return 'Ongeval met gevaarlijke stoffen';
        case 'accidentInvolvingTrain':
            return 'Ongeval met trein';
        case 'chemicalSpillageAccident':
            return 'Ongeval met weglekkende chemische stoffen';
        case 'collision':
            return 'Aanrijding';
        case 'collisionWithAnimal':
            return 'Aanrijding met dier';
        case 'collisionWithObstruction':
            return 'Aanrijding met object';
        case 'collisionWithPerson':
            return 'collisionWithPerson';
        case 'earlierAccident':
            return 'Eerder ongeval';
        case 'fuelSpillageAccident':
            return 'Ongeval met weglekkende brandstof';
        case 'jackknifedArticulatedLorry':
            return 'Geschaarde trekker met oplegger';
        case 'jackknifedCaravan':
            return 'Geschaarde caravan';
        case 'jackknifedTrailer':
            return 'Geschaarde aanhanger';
        case 'multipleVehicleCollision':
            return 'Kettingbotsing';
        case 'multivehicleAccident':
            return 'Ongeval met meerdere voertuigen';
        case 'oilSpillageAccident':
            return 'Ongeval met olielekkage';
        case 'overturnedHeavyLorry':
            return 'Gekantelde vrachtwagen';
        case 'overturnedTrailer':
            return 'Gekantelde aanhangwagen';
        case 'overturnedVehicle':
            return 'Gekanteld voertuig';
        case 'seriousAccident':
            return 'Ernstig ongeval';
        case 'vehicleSpunAround':
            return 'Gesplitste voertuigen op de rijbaan';
        case 'other':
            return 'Ongeval (overig)';
    }

    return generateUnknownMessage(properties);
};
const vehicleObstructionMessage = (properties: GeoJsonPropertiesSituation): string => {

    switch (properties.subType) {
        case 'abandonedVehicle':
        case 'brokenDownVehicle':
            return 'Pechgeval voertuig';
        case 'brokenDownBus':
            return 'Pechgeval bus';
        case 'brokenDownHeavyLorry':
            return 'Pechgeval vrachtwagen';
        case 'convoy':
            return 'Konvooi';
        case 'damagedVehicle':
            return 'Beschadigd voertuig';
        case 'emergencyVehicle':
            return 'Hulpdienstvoertuig';
        case 'overheightVehicle':
            return 'Te hoog voertuig';
        case 'prohibitedVehicleOnTheRoadway':
            return 'Verboden voertuig';
        case 'slowVehicle':
            return 'Langzaam voertuig';
        case 'snowplough':
            return 'Sneeuwploeg';
        case 'unlitVehicleOnTheRoad':
            return 'Onverlicht voertuig';
        case 'vehicleOnFire':
            return 'Brandend voertuig';
        case 'vehicleCarryingHazardousMaterials':
            return 'Voertuig met gevaarlijke stoffen';
        case 'vehicleStuckUnderBridge':
            return 'Voertuig klemgereden';
        case 'vehicleWithOverwideLoad':
        case 'vehicleOnWrongCarriageway':
        case 'other':
            return 'Voertuig obstructie (overig)';
    }

    return generateUnknownMessage(properties);
};

const generateReroutingManagementMessage = (properties: GeoJsonPropertiesSituation): string => {
    const comment = properties.comment || '';
    switch (properties.subType) {
        case 'followDiversionSigns':
            return `Volg de omleidingsborden ${comment}`;
        case 'followLocalDiversion':
            return `Volg de lokale omleiding ${comment}`;
        case 'followSpecialMarkers':
            return `Volg de speciale borden ${comment}`;
        case 'useEntry':
            return `Gebruik de oprit ${comment}`;
        case 'useExit':
            return `Gebruik de afrit ${comment}`;
        case 'useIntersectionOrJunction':
            return `Omleiding ingesteld ${comment}`;
    }
    if (properties.comment) {
        return properties.comment;
    }
    return generateUnknownMessage(properties);
};

const generateAbnormalTrafficMessage = (properties: AbnormalTrafficSituationFeatureProperties): string => {
    switch (properties.subType) {
        case 'stationaryTraffic':
            return `File: ${properties.trafficJamQueueLength / 1000} km stilstaand verkeer`;
        case 'queuingTraffic':
            return `File: ${properties.trafficJamQueueLength / 1000} km langzaam rijdend en stilstaand verkeer`;
        case 'slowTraffic':
            return `File: ${properties.trafficJamQueueLength / 1000} km langzaam rijdend verkeer`;
    }
    return generateUnknownMessage(properties);
};

const generateRoadOrCarriagewayOrLaneManagementMessage = (properties: RoadOrCarriagewayOrLaneManagementSituationFeatureProperties): string => {
    if (properties.subType === 'useSpecifiedLanesOrCarriageways') {
        if (properties.affectedCarriageway === 'slipRoads') {
            return 'Verkeer wordt omgeleid via af/toerit.';
        } else {
            return 'Verkeer wordt omgeleid via de vluchtstrook.';
        }
    } else if (properties.affectedCarriageway === 'exitSlipRoad') {
        return 'De afrit is afgesloten';
    } else if (properties.affectedCarriageway === 'entrySlipRoad') {
        return 'De toerit is afgesloten';
    } else if (properties.affectedCarriageway === 'slipRoads') {
        return 'De af- en toerit is afgesloten';
    } else if (properties.subType === 'connectingCarriageway') {
        return 'De verbindingsweg is afgesloten';
    } else if (properties.affectedLane === 'leftLane') {
        return 'De linkerrijstrook is afgesloten';
    } else if (properties.affectedLane === 'rightLane') {
        return 'De rechterrijstrook is afgesloten';
    } else if (properties.subType === 'laneClosures') {
        if(properties.affectedLane === 'rushHourLane') {
            return 'De spitstrook is afgesloten';
        } else {
            return `Er zijn ${properties.numberOfLanesRestricted} rijstroken afgesloten`;
        }
    } else if (properties.affectedCarriageway === 'mainCarriageway') {
        return 'De hoofdrijbaan is afgesloten';
    } else if (properties.affectedCarriageway === 'parallelCarriageway') {
        return 'De parallelbaan is afgesloten';
    } else if (properties.subType === 'carriagewayClosures') {
        return 'Rijbaan afgesloten';
    } else if (properties.subType === 'connectingCarriagewayClosure') {
        return 'Verbindingsweg afgesloten';
    } else if (properties.comment) {
        return properties.comment;
    }
    return generateUnknownMessage(properties);
};

const generateGeneralObstructionMessage = (properties: GeoJsonPropertiesSituation): string => {
    switch (properties.subType) {
        case 'objectOnTheRoad':
            return 'Object op de weg';
        case 'peopleOnRoadway':
            return 'Personen op de rijbaan';
        case 'shedLoad':
            return 'Afgevallen lading';
        case 'spillageOnTheRoad':
            return 'Rommel op de weg';
        case 'clearanceWork':
            return 'Opruimwerkzaamheden';
        case 'incident':
            return 'Incident';
    }
    return generateUnknownMessage(properties);
};

const generateGeneralNetworkManagementMessage = (properties: GeoJsonPropertiesSituation): string => {
    if (properties.subType === 'bridgeSwingInOperation') {
        return 'Brugopening gaande';
    }
    return generateUnknownMessage(properties);
};

function poorEnvironmentConditionsMessage(properties: GeoJsonPropertiesSituation) {
    switch (properties.subType) {
        case 'blizzard':
            return 'Verminderd zicht door sneeuw';
        case 'blowingDust':
            return 'Verminderd zicht door stof';
        case 'blowingSnow':
            return 'Verminderd zicht door sneeuw';
        case 'crosswinds':
            return 'Verminderd zicht door wind';
        case 'damagingHail':
            return 'Hevige hagel';
        case 'denseFog':
            return 'Verminderd zicht door mist';
        case 'extremeHeat':
            return 'Extreme hitte';
        case 'fog':
            return 'Verminderd zicht door mist';
        case 'freezingFog':
            return 'Verminderd zicht door mist';
        case 'frost':
            return 'Hevige vorst';
        case 'gales':
            return 'Windvlagen';
        case 'gustyWinds':
            return 'Windvlagen';
        case 'hail':
            return 'Hevige hagel';
        case 'heavyFrost':
            return 'Hevige vorst';
        case 'heavyRain':
            return 'Hevige regen';
        case 'heavySnowfall':
            return 'Hevige sneeuwval';
        case 'hurricaneForceWinds':
            return 'Hevige wind';
        case 'lowSunGlare':
            return 'Laagstaande zon';
        case 'patchyFog':
            return 'Verminderd zicht door mist';
        case 'rain':
            return 'Verminderd zicht door regen';
        case 'sandstorms':
            return 'Zandstorm';
        case 'severeExhaustPollution':
            return 'Verminderd zicht (overig)';
        case 'severeSmog':
            return 'Verminderd zicht door smog';
        case 'showers':
            return 'Verminderd zicht door regen';
        case 'sleet':
            return 'Sneeuw of ijsresten';
        case 'smogAlert':
            return 'Smog';
        case 'smokeHazard':
            return 'Rook';
        case 'snowfall':
            return 'Hevige sneeuwval';
        case 'sprayHazard':
            return 'Verminderd zicht (overig)';
        case 'strongGustsOfWind':
            return 'Windvlagen';
        case 'strongWinds':
            return 'Windvlagen';
        case 'swarmsOfInsects':
            return 'Verminderd zicht (overig)';
        case 'temperatureFalling':
            return 'Overig';
        case 'thunderstorms':
            return 'Hevig onweer';
        case 'tornadoes':
            return 'Tornado';
        case 'veryStrongGustsOfWind':
            return 'Hevige windvlagen';
        case 'visibilityReduced':
            return 'Verminder zicht (overig)';
        case 'whiteout':
            return 'Verminderd zicht door sneeuw';
        case 'winterStorm':
            return 'Storm';
    }
    return generateUnknownMessage(properties);
}

function nonWeatherRelatedRoadConditionsMessage(properties: GeoJsonPropertiesSituation) {
    switch (properties.subType) {
        case 'dieselOnRoad':
            return 'Brandstof op de weg';
        case 'leavesOnRoad':
            return 'Bladeren op de weg';
        case 'looseChippings':
        case 'looseSandOnRoad':
            return 'Rommel op de weg';
        case 'mudOnRoad':
            return 'Modder op de weg';
        case 'oilOnRoad':
            return 'Olie op de weg';
        case 'petrolOnRoad':
            return 'Brandstof op de weg';
        case 'roadSurfaceInPoorCondition':
            return 'Slecht wegdek';
        case 'slipperyRoad':
            return 'Glad wegdek';
        case 'other':
            return 'Wegdek waarschuwing (overig)';
    }
    return generateUnknownMessage(properties);
}

function weatherRelatedRoadConditionsMessage(properties: GeoJsonPropertiesSituation) {
    switch (properties.subType) {
        case 'blackIce':
            return 'Ijzige weggedeelten (onzichtbaar)';
        case 'deepSnow':
            return 'Sneeuw op de weg';
        case 'dry':
            return 'Droog';
        case 'freezingOfWetRoads':
            return 'Bevriezing van natte weggedeelten';
        case 'freezingRain':
            return 'Ijsvorming';
        case 'freshSnow':
            return 'Sneeuw op de weg';
        case 'ice':
            return 'Ijsvorming';
        case 'iceBuildUp':
            return 'Ijsvorming';
        case 'icyPatches':
            return 'Natte en opgevroren weggedeelten';
        case 'looseSnow':
            return 'Stuifsneeuw';
        case 'packedSnow':
            return 'Sneeuw op de weg';
        case 'roadSurfaceMelting':
            return 'Smeltend asfalt';
        case 'slipperyRoad':
        case 'slushOnRoad':
        case 'slushStrings':
        case 'snowDrifts':
            return 'Glad wegdek';
        case 'snowOnTheRoad':
            return 'Sneeuw op de weg';
        case 'surfaceWater':
            return 'Gevaar voor aquaplaning';
        case 'wet':
            return 'Gevaar voor aquaplaning';
        case 'wetAndIcyRoad':
            return 'Glad wegdek';
        case 'other':
            return 'Glad wegdek (overig)';
    }
    return generateUnknownMessage(properties);
}

function animalPresenceObstructionMessage(properties: GeoJsonPropertiesSituation) {
    switch (properties.subType) {
        case 'animalsOnTheRoad':
            return 'Dier op de weg';
        case 'herdOfAnimalsOnTheRoad':
            return 'Kudde dieren op de weg';
        case 'largeAnimalsOnTheRoad':
            return 'Grote dieren op de weg';
    }
    return generateUnknownMessage(properties);
}

function environmentalObstructionMessage(properties: GeoJsonPropertiesSituation) {
    switch (properties.subType) {
        case 'earthquakeDamage':
            return 'Aardbevingschade';
        case 'fallenTrees':
            return 'Omgewaaide bomen';
        case 'flashFloods':
        case 'flooding':
            return 'Overstroming';
        case 'forestFire':
        case 'grassFire':
        case 'seriousFire':
            return 'Brand in de buurt van de weg';
        case 'landslips':
        case 'mudSlide':
            return 'Grondverschuiving';
        case 'sewerOverflow':
            return 'Overstroming';
        case 'smokeOrFumes':
            return 'Rook';
        case 'stormDamage':
            return 'Stormschade';
        case 'fallingIce':
        case 'other':
            return 'Obstructie (overig)';
    }
    return generateUnknownMessage(properties);
}

const generateUnknownMessage = (properties: GeoJsonPropertiesSituation): string => {
    return `Geen informatie bekend voor: ${properties.type}, ${properties.subType}`;
};

const detailedMessageTopic = (properties: GeoJsonPropertiesSituation): string => {
    switch (properties.type) {
        case 'AbnormalTraffic':
            return generateAbnormalTrafficMessage(properties as AbnormalTrafficSituationFeatureProperties);
        case 'RoadOrCarriagewayOrLaneManagement':
            return generateRoadOrCarriagewayOrLaneManagementMessage(properties as RoadOrCarriagewayOrLaneManagementSituationFeatureProperties);
        case 'ReroutingManagement':
            return generateReroutingManagementMessage(properties as GeoJsonPropertiesSituation);
        case 'VehicleObstruction':
            return vehicleObstructionMessage(properties as GeoJsonPropertiesSituation);
        case 'GeneralObstruction':
            return generateGeneralObstructionMessage(properties as GeoJsonPropertiesSituation);
        case 'Accident':
            return generateAccidentMessage(properties as GeoJsonPropertiesSituation);
        case 'GeneralNetworkManagement':
            return generateGeneralNetworkManagementMessage(properties as GeoJsonPropertiesSituation);
        case 'PoorEnvironmentConditions':
            return poorEnvironmentConditionsMessage(properties as GeoJsonPropertiesSituation);
        case 'NonWeatherRelatedRoadConditions':
            return nonWeatherRelatedRoadConditionsMessage(properties as GeoJsonPropertiesSituation);
        case 'WeatherRelatedRoadConditions':
            return weatherRelatedRoadConditionsMessage(properties as GeoJsonPropertiesSituation);
        case 'AnimalPresenceObstruction':
            return animalPresenceObstructionMessage(properties as GeoJsonPropertiesSituation);
        case 'EnvironmentalObstruction':
            return environmentalObstructionMessage(properties as GeoJsonPropertiesSituation);
        case 'InfrastructureDamageObstruction':
            return 'Schade aan infrastructuur';
    }
    return generateUnknownMessage(properties);

};

export const enrichedDescription = (properties: GeoJsonPropertiesSituation): string => {
    const watchOut = properties.safetyRelatedMessage ? 'Let op!:' : '';
    const cause = properties.cause ? `in verband met ${properties.cause}`.toLowerCase() : '';
    return `${watchOut} ${detailedMessageTopic(properties)} ${cause}`;
};

export const descriptiveTime = (properties: GeoJsonPropertiesSituation): string => {
    const startDateTime = new Date(properties.overallStartTime);
    const dateString = formatDateTimeWithDayOfWeek(startDateTime);
    const timeString = formatTime(startDateTime, false);
    return `Sinds ${dateString} om ${timeString} uur`;
};
