import {Feature} from 'geojson';
import {PlotData} from 'plotly.js';
import {FrontOfficeEventStatus} from '../constants';
import {BridgeHistoryDTO, SegmentHeatMapDTO} from '../generated/ViewerApiClient';
import {GeoJsonPropertiesWaze} from './GeoJsonSources/GeoJsonPropertiesWaze';
import {MapFeature} from './MapFeature';
import {WAZE_ALERT_ITEM_SUB_TYPES} from './WazeAlertItemStatus';

export enum DialogTypes {
    SELECT_FEATURES = 'selectFeatures',
    GEO_JSON = 'geoJSON',
    VECTOR_LAYER = 'vectorLayer'
}

export type DIALOG_TYPES = DialogTypes.SELECT_FEATURES | DialogTypes.GEO_JSON | DialogTypes.VECTOR_LAYER;

export enum SavingStatus {
    callingServer = 'CALLING_SERVER',
    changesSaved = 'CHANGES_SAVED',
    savingFailed = 'SAVING_FAILED'
}

export enum GeoJsonSubTypes {
    GENERAL = 'general',
    FLOW_SPEED = 'flowSpeed',
    BRIDGE = 'bridge',
    WAZE_ALERT_INCIDENT = 'wazeAlertIncident',
}

export type GEO_JSON_SUB_TYPES = GeoJsonSubTypes.GENERAL |
    GeoJsonSubTypes.FLOW_SPEED |
    GeoJsonSubTypes.BRIDGE |
    GeoJsonSubTypes.WAZE_ALERT_INCIDENT;

export enum VectorLayerSubTypes {
    GENERAL = 'general',
    TRAVEL_TIME_FCD = 'travelTimeFcd',
    TRAVEL_TIME_OTHER = 'travelTimeOther',
    TRAVEL_TIME_DRIPS = 'travelTimeDrips',
}

export type VECTOR_LAYER_SUB_TYPES = VectorLayerSubTypes.GENERAL
    | VectorLayerSubTypes.TRAVEL_TIME_FCD
    | VectorLayerSubTypes.TRAVEL_TIME_OTHER
    | VectorLayerSubTypes.TRAVEL_TIME_DRIPS;

export interface ActiveBaseDialog {
    type: DIALOG_TYPES;
    id: number | string;
    pinned: boolean;
    preview: boolean;
    startPosition: {
        x: number;
        y: number;
    };
    zIndex: number;
}

export interface ActiveDialogSelectFeature extends ActiveBaseDialog {
    type: DialogTypes.SELECT_FEATURES;
    features: MapFeature[];
}

export interface ActiveDialogGeoJsonFeature<P = Feature['properties']> extends ActiveBaseDialog {
    type: DialogTypes.GEO_JSON;
    subType: GEO_JSON_SUB_TYPES;
    currentProperties: P;
    mapLayer: string;
    dataSource: string;
}

export interface ActiveDialogGeoJsonFeatureGeneral extends ActiveDialogGeoJsonFeature {
    subType: GeoJsonSubTypes.GENERAL;
}

export interface ActiveDialogGeoJsonFeatureFlowSpeedGraphData {
    flow: Partial<PlotData>[];
    speed: Partial<PlotData>[];
    timeStamp: number;
}

export interface ActiveDialogGeoJsonFeatureFlowSpeed extends ActiveDialogGeoJsonFeature {
    subType: GeoJsonSubTypes.FLOW_SPEED;
    graphs: ActiveDialogGeoJsonFeatureFlowSpeedGraphData | null;
}

export interface ActiveDialogGeoJsonFeatureBridge extends ActiveDialogGeoJsonFeature {
    subType: GeoJsonSubTypes.BRIDGE;
    bridgeHistory: BridgeHistoryDTO | null;
}

export interface ActiveDialogGeoJsonFeatureWazeAlertIncident extends ActiveDialogGeoJsonFeature {
    subType: GeoJsonSubTypes.WAZE_ALERT_INCIDENT;
    currentProperties: GeoJsonPropertiesWaze;
    hasLoadedData: boolean;
    lastChangeTimestamp: number | null;
    lastSaveTimestamp: number | null;
    loadingDataFailed: boolean;
    isOpen: boolean;
    status: FrontOfficeEventStatus | null;
    timeRemainingBeforeAutomaticClosure: number | null;
    hectometrePost: string | null;
    savingStatus: SavingStatus | null;
    newSubType: WAZE_ALERT_ITEM_SUB_TYPES | null;
}

export interface ActiveDialogVectorLayerFeature extends ActiveBaseDialog {
    type: DialogTypes.VECTOR_LAYER;
    subType: VECTOR_LAYER_SUB_TYPES;
    currentProperties: Feature['properties'];
    currentState: Record<string, unknown>;
    mapLayer: string;
    dataSource: string;
}

export interface ActiveDialogVectorLayerFeatureGeneral extends ActiveDialogVectorLayerFeature {
    subType: VectorLayerSubTypes.GENERAL;
}

export interface ActiveDialogVectorLayerFeatureTravelTimeGraphData {
    travelTime: Partial<PlotData>[];
    velocity: Partial<PlotData>[];
    timeStamp: number;
}

export interface ActiveDialogVectorLayerFeatureTravelTime extends ActiveDialogVectorLayerFeature {
    subType: VectorLayerSubTypes.TRAVEL_TIME_FCD | VectorLayerSubTypes.TRAVEL_TIME_OTHER | VectorLayerSubTypes.TRAVEL_TIME_DRIPS;
    graphs: ActiveDialogVectorLayerFeatureTravelTimeGraphData | null;
    segmentHeatMap: SegmentHeatMapDTO | null;
}

export type DIALOG_INTERFACES = ActiveDialogSelectFeature |
    ActiveDialogGeoJsonFeatureGeneral |
    ActiveDialogGeoJsonFeatureFlowSpeed |
    ActiveDialogGeoJsonFeatureBridge |
    ActiveDialogGeoJsonFeatureWazeAlertIncident |
    ActiveDialogVectorLayerFeatureGeneral |
    ActiveDialogVectorLayerFeatureTravelTime;

export type DIALOG_INTERFACES_GEO_JSON = ActiveDialogGeoJsonFeatureGeneral |
    ActiveDialogGeoJsonFeatureBridge |
    ActiveDialogGeoJsonFeatureFlowSpeed |
    ActiveDialogGeoJsonFeatureWazeAlertIncident;

export type DIALOG_INTERFACES_VECTOR_LAYER = ActiveDialogVectorLayerFeatureGeneral |
    ActiveDialogVectorLayerFeatureTravelTime;
