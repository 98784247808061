import {isActionOf} from 'typesafe-actions';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {delay, filter, flatMap, ignoreElements, map, tap} from 'rxjs/operators';
import {
    mapSceneMapboxLayersActivateDataSource,
    mapSceneMapboxLayersActivateMapLayer,
    mapSceneMapboxLayersDeactivateMapLayer,
    mapSceneMapboxLayersUpdateMapLayerConfiguration,
    mapSceneMapboxLayersUpdatePrefixData
} from '../../scenes/MapScene/actions/reducers/mapboxLayers';
import {
    mapSceneDataDeactivateDataSource,
    mapSceneDataMarkDataSourceAsOutdated
} from '../../scenes/MapScene/actions/reducers/data';
import {LayersState} from '../../scenes/MapScene/reducers/mapboxLayersReducer';
import mapLayerEpics from './mapLayerEpics';
import {RootState} from '../../reducer';
import {MapLayer} from '../../interfaces/MapLayer';
import {DataSource} from '../../interfaces/DataSource';
import {toast} from 'react-toastify';
import {of} from 'rxjs';
import {disableMapLayer, enableMapLayer} from '../../scenes/MapScene/actions/mapBoxComponent';
import {
    viewerApiServiceReceivedLayerConfiguration
} from '../../services/ViewerApiService/actions/layerConfigurationData';
import {
    viewerApiServiceReceivingSourceDataFailedWithNotFoundError,
    viewerApiServiceReceivingSourceDataUnexpectedError
} from '../../services/ViewerApiService/actions/sourceDataData';
import {viewApiServiceLoadPrefixDataCompleted} from '../../services/ViewerApiService/actions/loadPrefixData';
import {CustomEvent} from '@piwikpro/react-piwik-pro';
import {DataSourceState} from '../../scenes/MapScene/reducers/dataReducer';
import {mapSceneNumberOfFeaturesVisibleOnMap} from '../../scenes/MapScene/actions';
import {noFeaturesVisibleToastId} from '../../constants';

const activateRequiredMapLayersAfterSourceBecomesActivate: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(mapSceneMapboxLayersActivateDataSource)),
        delay(100),
        map((action) => {
            const mapLayer = state$.value.mapScene.mapboxLayers.layers[action.payload.mapLayerToActivate];
            return mapSceneMapboxLayersActivateMapLayer(mapLayer);
        })
    );

const deactivateMapSourceWhenLastLayerIsRemoved: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(mapSceneMapboxLayersDeactivateMapLayer)),
        delay(100),
        filter((action) => {
            const mapSource = state$.value.mapScene.data.sources[action.payload.mapLayer.source];
            let sourceStillActive = false;
            Object.keys(state$.value.mapScene.mapboxLayers.layers).forEach(layerKey => {
                const layer = state$.value.mapScene.mapboxLayers.layers[layerKey];
                if (layer.source === mapSource.id && layer.isActive) {
                    sourceStillActive = true;
                }
            });

            return !sourceStillActive;
        }),
        map((action) => {
            const mapSource = state$.value.mapScene.data.sources[action.payload.mapLayer.source];
            return mapSceneDataDeactivateDataSource(mapSource);
        })
    );

const deactivateMapSourceOnFileNotFoundException: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(viewerApiServiceReceivingSourceDataFailedWithNotFoundError)),
        flatMap((action) => {
            const dataSource = state$.value.mapScene.data.sources[action.payload.sourceID];
            const layersToDisable: string[] = [];
            Object.keys(state$.value.mapScene.mapboxLayers.layers).forEach(layerKey => {
                const layer = state$.value.mapScene.mapboxLayers.layers[layerKey];
                if (layer.source === dataSource.id && layer.isActive) {
                    layersToDisable.push(layer.id);
                }
            });
            return of(...layersToDisable).pipe(map((val) => disableMapLayer(val)));
        })
    );

const markDataSourceAsOutdatedOnDataSourceLoadUnexpectedError: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivingSourceDataUnexpectedError)),
        map(({payload: {sourceID}}) => mapSceneDataMarkDataSourceAsOutdated(sourceID))
    );

const showToastMessageOnReceivingReceivingSourceDataUnexpectedError: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf([
            viewerApiServiceReceivingSourceDataUnexpectedError,
            viewerApiServiceReceivingSourceDataFailedWithNotFoundError
        ])),
        tap((action) => {
            let message = '';
            switch (action.payload.layerType) {
                case 'base':
                case 'data':
                    message = `De kaartgegevens van de bron '${action.payload.sourceName}'
                    kan niet geladen worden, en de lagen worden om deze reden uitgeschakeld`;
                    break;
            }

            if (message) {
                toast(message, {
                    type: 'error'
                });
            }

        }),
        ignoreElements()
    );

const showToastWhenNoFeaturesVisibleOnMap: Epic = (action$, state$: StateObservable<RootState>) => {
    const toastDurationMS = 10000;
    return action$.pipe(
        filter(isActionOf([
            mapSceneNumberOfFeaturesVisibleOnMap
        ])),
        filter(action => action.payload.numberOfFeaturesVisible === 0),
        filter(() => Object.keys(state$.value.mapScene.data.sources).filter(dataSource => state$.value.mapScene.data.sources[dataSource].isActive).length > 0),
        tap(() => {
            const message = 'Met uw huidige kaartselectie, filterinstellingen of zoomniveau zijn er geen items gevonden die getoond kunnen worden. Pas uw filter of zoomniveau aan, verplaats de kaart, of zet een andere kaartlaag aan.';
            toast(message, {
                toastId: noFeaturesVisibleToastId,
                type: 'warning',
                autoClose: toastDurationMS,
                pauseOnFocusLoss: false
            });

        }),
        ignoreElements()
    );
};

const hideToastWhenFeaturesVisibleOnMap: Epic = (action$) => {
    return action$.pipe(
        filter(isActionOf([
            mapSceneNumberOfFeaturesVisibleOnMap
        ])),
        filter(action => action.payload.numberOfFeaturesVisible > 0),
        tap(() => toast.dismiss(noFeaturesVisibleToastId)),
        ignoreElements()
    );
};

const updateMapLayerAndSourceStatusOnActivateMapLayer: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(enableMapLayer)),
        map(action => state$.value.mapScene.mapboxLayers.layers[action.payload.layerID]),
        filter(mapLayer => !!mapLayer),
        map((mapLayer) => {
            const dataSource: DataSource = state$.value.mapScene.data.sources[mapLayer.source];

            if (!dataSource.isActive) {
                return mapSceneMapboxLayersActivateDataSource(dataSource, mapLayer.id);
            }

            return mapSceneMapboxLayersActivateMapLayer(mapLayer);
        })
    );

const updateMapLayerAndSourceStatusOnDeactivateMapLayer: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(disableMapLayer)),
        map((action) => {
            const mapLayer: MapLayer = state$.value.mapScene.mapboxLayers.layers[action.payload.layerID];

            return mapSceneMapboxLayersDeactivateMapLayer(mapLayer);
        })
    );

const updateMapLayerConfigurationOnReceivedLayerConfiguration: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApiServiceReceivedLayerConfiguration)),
        map((action) => {

            const mapLayers: LayersState = {};
            const dataSources: DataSourceState = {};

            action.payload.layerConfigurations.forEach((mapLayer: MapLayer) => {
                mapLayers[mapLayer.id] = mapLayer;
            });
            action.payload.dataSourceConfigurations.forEach((dataSource: DataSource) => {
                dataSources[dataSource.id] = dataSource;
            });

            return mapSceneMapboxLayersUpdateMapLayerConfiguration(mapLayers, dataSources);
        })
    );

const trackMapLayerActivationEvent: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(mapSceneMapboxLayersDeactivateMapLayer)),
        tap((action) => CustomEvent.trackEvent('map_scene', 'map_layer_disabled', action.payload.mapLayer.name)),
        ignoreElements()
    );

const trackDeActivationTrackingEvent: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(mapSceneMapboxLayersActivateMapLayer)),
        tap((action) => CustomEvent.trackEvent('map_scene', 'map_layer_enabled', action.payload.mapLayer.name)),
        ignoreElements()
    );

const updatePrefixDataOnReceivedPrefixDataFromViewerApiClient: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewApiServiceLoadPrefixDataCompleted)),
        map(({payload: {prefixes}}) => mapSceneMapboxLayersUpdatePrefixData(prefixes))
    );

const mapLayerConfigurationEpics: Epic = combineEpics(
    activateRequiredMapLayersAfterSourceBecomesActivate,
    deactivateMapSourceWhenLastLayerIsRemoved,
    deactivateMapSourceOnFileNotFoundException,
    showToastMessageOnReceivingReceivingSourceDataUnexpectedError,
    updateMapLayerConfigurationOnReceivedLayerConfiguration,
    updateMapLayerAndSourceStatusOnActivateMapLayer,
    updateMapLayerAndSourceStatusOnDeactivateMapLayer,
    markDataSourceAsOutdatedOnDataSourceLoadUnexpectedError,
    updatePrefixDataOnReceivedPrefixDataFromViewerApiClient,
    trackMapLayerActivationEvent,
    trackDeActivationTrackingEvent,
    mapLayerEpics,
    showToastWhenNoFeaturesVisibleOnMap,
    hideToastWhenFeaturesVisibleOnMap
);

export default mapLayerConfigurationEpics;
