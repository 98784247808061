import {ActionType, createAction} from 'typesafe-actions';

export const viewerApiServiceUpdatedWazeOpen = createAction(
    'VIEWER_API_SERVICE_UPDATED_WAZE_OPEN',
    (action) => () => action({})
);

export const viewerApiServiceUpdatingWazeOpenFailedWithBadRequestExceptionError = createAction(
    'VIEWER_API_SERVICE_UPDATING_WAZE_OPEN_FAILED_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceUpdatingWazeOpenFailedWithUnauthorizedExceptionError = createAction(
    'VIEWER_API_SERVICE_UPDATING_WAZE_OPEN_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceUpdatingWazeOpenFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_UPDATING_WAZE_OPEN_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type WazeOpenActionTypes = ActionType<typeof viewerApiServiceUpdatedWazeOpen> |
    ActionType<typeof viewerApiServiceUpdatingWazeOpenFailedWithBadRequestExceptionError> |
    ActionType<typeof viewerApiServiceUpdatingWazeOpenFailedWithUnauthorizedExceptionError> |
    ActionType<typeof viewerApiServiceUpdatingWazeOpenFailedWithUnexpectedError>;
