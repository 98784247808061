import {ActionType, createAction} from 'typesafe-actions';
import {PrefixDTO} from '../../../../generated/ViewerApiClient';

export const viewApiServiceLoadPrefixDataCompleted = createAction(
    'VIEW_API_SERVICE_LOAD_PREFIX_DATA_COMPLETED',
    (action) => (prefixes: PrefixDTO[]) => action({prefixes})
);

export const viewerApiServiceLoadPrefixDataFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_LOAD_PREFIX_DATA_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceLoadPrefixDataFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_LOAD_PREFIX_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type ViewerApiServiceLoadPrefixData = ActionType<typeof viewApiServiceLoadPrefixDataCompleted> |
    ActionType<typeof viewerApiServiceLoadPrefixDataFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceLoadPrefixDataFailedWithUnexpectedError>;
