import React from 'react';
import {Props} from './Props';
import {MapLayer} from '../../../../../../interfaces/MapLayer';
import {DataSource} from '../../../../../../interfaces/DataSource';
import {css} from 'aphrodite';
import styles from './styles';
import {MapLayerRow} from './components/MapLayerRow';

const MapLayerPane: React.FunctionComponent<Props> = ({mapLayers, mapSources, onClickedOnMapLayer}) => {
    const parsedMapLayers = Object.values(mapLayers);
    let currentCategory = '';

    return <React.Fragment>
        <p className={css(styles.paneDescription)}>
            Selecteer hier de informatie stromen die u wilt weergeven in de NDW Viewer.
        </p>

        {parsedMapLayers.map((mapLayer: MapLayer, key: number) => {
            const mapSource: DataSource = mapSources[mapLayer.source];
            if (!mapSource) {
                return <React.Fragment/>;
            }

            if (currentCategory !== mapLayer.category) {
                currentCategory = mapLayer.category;
                return <React.Fragment key={key}>
                    {key !== 0 && <br/>}
                    <h3 className={css(styles.categoryTitle)}>{mapLayer.category}</h3>
                    <MapLayerRow
                        indented={mapLayer.category !== ''}
                        mapSource={mapSource}
                        mapLayer={mapLayer}
                        clickedOnLayer={(layerID) => onClickedOnMapLayer(layerID)}
                    />
                </React.Fragment>;
            }

            return <MapLayerRow
                indented={currentCategory !== ''}
                mapLayer={mapLayer}
                mapSource={mapSource}
                clickedOnLayer={(layerID) => onClickedOnMapLayer(layerID)}
                key={key}
            />;
        })}
    </React.Fragment>;
};

export default MapLayerPane;
