import {
    ActionTypes,
    changeCurrentTheme,
    changeCurrentTrafficCenter,
    storeAccessData,
    userSettingsReducerShowRecordIds,
    userSettingsReducerShowTrafficJamIcons
} from './actions';
import {Theme, themes} from '../../themes';
import {getType} from 'typesafe-actions';
import {AccessDTO, TrafficCenterDTO} from '../../generated/ViewerApiClient';
import UrlService from '../../services/UrlService';

export interface UserSettingsReducerState {
    access: AccessDTO;
    currentTrafficCenter: TrafficCenterDTO | null;
    showRecordId: boolean;
    showTrafficJamIcons: boolean;
    theme: Theme;
}

const currentTheme = UrlService.getInstance().getNumericValueFromUrl('theme', -1);
let theme = themes[0];
if (currentTheme !== -1) {
    themes.forEach((item: Theme) => {
        if (currentTheme === item.index) {
            theme = item;
        }
    });
}
const showRecordId = UrlService.getInstance().getBooleanValueFromUrl('showRecordIds', false);
const showTrafficJamIcons = UrlService.getInstance().getBooleanValueFromUrl('showTrafficJamIcons', true);
const initialState: UserSettingsReducerState = {
    access: {
        development: false,
        permissions: []
    },
    currentTrafficCenter: null,
    showRecordId: showRecordId,
    showTrafficJamIcons: showTrafficJamIcons,
    theme: theme
};

const userSettingsReducer = (state: UserSettingsReducerState = initialState, action: ActionTypes): UserSettingsReducerState => {
    switch (action.type) {
        case getType(changeCurrentTrafficCenter):
            return {
                ...state,
                currentTrafficCenter: action.payload.trafficCenter
            };
        case getType(changeCurrentTheme):

            const newTheme = themes.filter((filterTheme: Theme) => filterTheme.index === action.payload.newThemeNumber);

            if (newTheme[0]) {
                return {
                    ...state,
                    theme: newTheme[0]
                };
            }

            return {
                ...state
            };
        case getType(storeAccessData):
            return {
                ...state,
                access: action.payload.data
            };
        case getType(userSettingsReducerShowRecordIds):
            return {
                ...state,
                showRecordId: action.payload.value
            };
        case getType(userSettingsReducerShowTrafficJamIcons):
            return {
                ...state,
                showTrafficJamIcons: action.payload.value
            };
        default:
            return state;
    }
};

export default userSettingsReducer;
