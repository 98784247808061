import {combineEpics, Epic} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    storeAccessData,
    userSettingsReducerShowRecordIds,
    userSettingsReducerShowTrafficJamIcons
} from '../../modules/UserSettingsReducer/actions';
import {viewerApiServiceReceivedAccessData} from '../../services/ViewerApiService/actions/accessData';
import {
    mapSceneSettingsPanelToggleShowRecordId,
    mapSceneSettingsPanelToggleShowTrafficJamIcons
} from '../../scenes/MapScene/actions/settingsPane';

const storeAccessDataOnViewerApiServiceReceivedAccessData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedAccessData)),
        map(({payload: {data}}) => storeAccessData(data))
    );

const updateUserSettingShowRecordIdsOnMapSceneToggleShowRecordIds: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneSettingsPanelToggleShowRecordId)),
        map(({payload: {value}}) => userSettingsReducerShowRecordIds(value))
    );

const updateUserSettingShowTrafficJamIconsOnMapSceneToggleShowTrafficJamIcons: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneSettingsPanelToggleShowTrafficJamIcons)),
        map(({payload: {value}}) => userSettingsReducerShowTrafficJamIcons(value))
    );

const userSettingsEpics: Epic = combineEpics(
    storeAccessDataOnViewerApiServiceReceivedAccessData,
    updateUserSettingShowRecordIdsOnMapSceneToggleShowRecordIds,
    updateUserSettingShowTrafficJamIconsOnMapSceneToggleShowTrafficJamIcons
);

export default userSettingsEpics;
