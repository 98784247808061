import {ActionTypes, setNewTrafficCenters} from './actions';
import {TrafficCenterDTO} from '../../generated/ViewerApiClient';
import {getType} from 'typesafe-actions';

export interface TrafficCenterState {
    trafficCenters: TrafficCenterDTO[];
    trafficCentersLoaded: boolean;
}

const initialState: TrafficCenterState = {
    trafficCenters: [],
    trafficCentersLoaded: false
};

const trafficCenterReducer = (state: TrafficCenterState = initialState, action: ActionTypes): TrafficCenterState => {
    switch (action.type) {
        case getType(setNewTrafficCenters):
            return {
                ...state,
                trafficCenters: action.payload.trafficCenters,
                trafficCentersLoaded: true
            };
        default:
            return state;
    }
};

export default trafficCenterReducer;
