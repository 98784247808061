import {ActionType, createAction} from 'typesafe-actions';
import {TrafficCenterDTO} from '../../../../generated/ViewerApiClient';
import {SearchResults} from '../../../../interfaces/SearchFeature';
import {PrefixLayerFilters, SituationTypeFilter} from '../../reducers/filterAndSearchReducer';

export const setNewWazeAlertNdwKnownFilterStatus = createAction(
    'SET_NEW_WAZE_ALERT_NDW_KNOWN_FILTER_STATUS',
    (action) => (newStatus: boolean) => action({newStatus})
);

export const setNewDfineFilterStatusOnlyRvmNetwork = createAction(
    'SET_NEW_DFINE_FILTER_STATUS_ONLY_RVM_NETWORK',
    (action) => (newStatus: boolean) => action({newStatus})
);

export const setNewWazeAlertFilterStatusSet = createAction(
    'SET_NEW_WAZE_ALERT_FILTER_STATUS_SET',
    (action) => (newStatus: boolean) => action({newStatus})
);

export const setNewPrefixFilterForLayer = createAction(
    'SET_NEW_PREFIX_FILTER_FOR_LAYER',
    (action) => (layerId: keyof PrefixLayerFilters, prefixes: string[]) => action({layerId, prefixes})
);

export const selectedNewTrafficCenter = createAction(
    'SELECTED_NEW_TRAFFIC_CENTER',
    (action) => (newTrafficCenter: TrafficCenterDTO | null) => action({newTrafficCenter})
);

export const storeNewSearchValue = createAction(
    'STORE_NEW_SEARCH_VALUE',
    (action) => (searchValue: string) => action({searchValue})
);

export const storeNewSearchResult = createAction(
    'STORE_NEW_SEARCH_RESULTS',
    (action) => (searchResults: SearchResults | null) => action({searchResults})
);

export const setNewFdVerifiedTrafficJamsFilterStatus = createAction(
    'SET_NEW_FD_VERIFIED_TRAFFIC_JAMS_FILTER_STATUS',
    (action) => (newStatus: boolean) => action({newStatus})
);

export const setSituationTypeFilter = createAction(
    'SET_SITUATION_TYPE_FILTER',
    (action) => (situationTypeFilters: SituationTypeFilter[]) => action({situationTypeFilters})
);

export type MapSceneFilterAndSearchReducerActionTypes = ActionType<typeof setNewWazeAlertNdwKnownFilterStatus> |
    ActionType<typeof setNewDfineFilterStatusOnlyRvmNetwork> |
    ActionType<typeof setNewWazeAlertFilterStatusSet> |
    ActionType<typeof setNewPrefixFilterForLayer> |
    ActionType<typeof selectedNewTrafficCenter> |
    ActionType<typeof storeNewSearchValue> |
    ActionType<typeof storeNewSearchResult> |
    ActionType<typeof setNewFdVerifiedTrafficJamsFilterStatus> |
    ActionType<typeof setSituationTypeFilter>;
