import {ActionType, createAction} from 'typesafe-actions';
import {WazeStatusDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedWazeStatusData = createAction(
    'VIEWER_API_SERVICE_RECEIVED_WAZE_STATUS_DATA',
    (action) => (id: string, wazeStatusData: WazeStatusDTO) => action({
        id,
        wazeStatusData
    })
);

export const viewerApiServiceReceivingWazeStatusDataFailedWithBadRequestError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_WAZE_STATUS_DATA_FAILED_WITH_BAD_REQUEST_ERROR',
    (action) => (id: string) => action({id})
);

export const viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_WAZE_STATUS_DATA_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => (id: string) => action({id})
);

export const viewerApiServiceReceivingWazeStatusDataFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_WAZE_STATUS_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => (id: string) => action({id})
);

export const viewerApiServiceSuccessFullyUpdatedWazeStatusData = createAction(
    'VIEWER_API_SERVICE_SUCCESS_FULLY_UPDATED_WAZE_STATUS_DATA',
    (action) => (id: string, name: string) => action({id, name})
);

export const viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_UPDATING_WAZE_STATUS_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => (id: string, name: string) => action({id, name})
);

export const viewerApiServiceUpdatingWazeStatusUnexpectedError = createAction(
    'UPDATING_WAZE_STATUS_UNEXPECTED_ERROR',
    (action) => (id: string, name: string) => action({id, name})
);

export type WazeStatusDataActionTypes = ActionType<typeof viewerApiServiceReceivedWazeStatusData> |
    ActionType<typeof viewerApiServiceReceivingWazeStatusDataFailedWithBadRequestError> |
    ActionType<typeof viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceReceivingWazeStatusDataFailedWithUnexpectedError> |
    ActionType<typeof viewerApiServiceSuccessFullyUpdatedWazeStatusData> |
    ActionType<typeof viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceUpdatingWazeStatusUnexpectedError>
