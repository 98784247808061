import React from 'react';
import {Props} from './Props';
import MessageAgeInMinutes from '../../../../../../components/MessageAgeInMinutes';
import StyledBoolean from '../../../../../../components/StyledBoolean';
import {convertWazeTypeAndSubtypeToTranslatedEventType} from '../../../../../../services/Utilities';
import TimeFormatted from '../../../../../../components/TimeFormatted';

const DialogWaze: React.FunctionComponent<Props> = ({currentProperties, showRecordIds}) => {

    return <table>
        <tbody>
            {showRecordIds && <tr>
                <th>ID</th>
                <td>{currentProperties.id}</td>
            </tr>}
            <tr>
                <th>Type</th>
                <td>{convertWazeTypeAndSubtypeToTranslatedEventType(currentProperties.type, currentProperties.subType)}</td>
            </tr>
            <tr>
                <th>Starttijd</th>
                <td><TimeFormatted dateTime={currentProperties.publicationTime}/></td>
            </tr>
            <tr>
                <th>Duur incident</th>
                <td><MessageAgeInMinutes time={currentProperties.publicationTime}/></td>
            </tr>
            <tr>
                <th>Locatie</th>
                <td>
                    {currentProperties.street} {currentProperties.city}, {currentProperties.hectometrePost || 'Geen hectometrering'}
                </td>
            </tr>
            <tr>
                <th>Bevestigingen</th>
                <td>{currentProperties.thumbsUp}</td>
            </tr>
            <tr>
                <th>Laatste bevestiging</th>
                <td>
                    <MessageAgeInMinutes
                        time={currentProperties.lastConfirmedTime}
                    />
                </td>
            </tr>
            <tr>
                <th>Betrouwbaarheid melder</th>
                <td>{currentProperties.reporterRating}</td>
            </tr>
            <tr>
                <th>Al bekend bij NDW?</th>
                <td><StyledBoolean value={currentProperties.ndw}/></td>
            </tr>
        </tbody>
    </table>;
};

export default DialogWaze;
