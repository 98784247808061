import React, {ReactElement} from 'react';
import {Props} from './Props';
import MeterBar from '../../../../../../components/MeterBar';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';

class DialogFcdClass extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, state, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Segmentlengte in meters</th>
                        <td>{properties.length}</td>
                    </tr>
                    <tr>
                        <th>Gemeten snelheid</th>
                        <td>{state.averageSpeed}</td>
                    </tr>
                    <tr>
                        <th>Vertraging in seconden</th>
                        <td>{state.delay}</td>
                    </tr>
                    <tr>
                        <th>Freeflow snelheid</th>
                        <td>{properties.freeFlow}</td>
                    </tr>
                    <tr>
                        <th>Tijdstip waarneming</th>
                        <td><DateTimeFormatted dateTime={state.timestamp}/></td>
                    </tr>
                </tbody>
            </table>

            <MeterBar
                percentage={100}
                backgroundColor={'transparent'}
                fillColor={state.color}
            />

        </React.Fragment>;
    }

}

export default DialogFcdClass;
