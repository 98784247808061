import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    falseValue: {
        color: '#dc3545'
    },
    trueValue: {
        color: '#28a745'
    }
});

export default styles;
