import {ActionType, createAction} from 'typesafe-actions';
import {LegendDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedLegendDate = createAction(
    'VIEWER_API_SERVICE_RECEIVED_LEGEND_DATA',
    (action) => (data: LegendDTO) => action({data})
);

export const viewerApiServiceReceivingLegendDataUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_LEGEND_DATA_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type LegendDataActionTypes = ActionType<typeof viewerApiServiceReceivedLegendDate> |
    ActionType<typeof viewerApiServiceReceivingLegendDataUnexpectedError>
