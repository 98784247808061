import React from 'react';
import {Props} from './Props';
import {css} from 'aphrodite';
import styles from './styles';

const BearingCompass: React.FunctionComponent<Props> = ({bearing}) => {
    const label = `${bearing} graden`;
    return (
        <span aria-label={label} title={label} className={css(styles.bearingIcon)}>
            <span
                className="fas fa-location-arrow"
                style={{
                    transform: `rotate(${bearing - 45}deg)`
                }}
            />
        </span>
    );
};

export default BearingCompass;
