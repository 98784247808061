import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    notification: {
        all: 'unset',
        cursor: 'pointer',
        position: 'relative'
    },
    notificationText: {
        backgroundColor: 'white',
        borderColor: 'rgba(216, 220, 222)',
        borderRadius: 5,
        borderStyle: 'solid',
        borderWidth: 1,
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)',
        cursor: 'initial',
        fontWeight: 'bold',
        minWidth: '550px',
        padding: 8,
        position: 'absolute',
        right: 0,
        top: 25,
        transform: 'scale(1)',
        transformOrigin: 'right top',
        transition: 'transform 0.3s ease 0s',
        zIndex: 35
    },
    notificationTextHidden: {
        transform: 'scale(0)'
    },
    notificationUnread: {
        animation: 'ring 2s ease infinite',
        color: 'rgb(220, 53, 69)'
    }
});

export default styles;
