import React, {ReactElement} from 'react';
import AgGrid from '../../../AgGrid';
import {Props} from './Props';
import {DateCellRenderer} from '../../../AgGrid/components/DateCellRenderer';
import {handleRowClick} from '../TableService';
import {SituationFeature} from '../../../../interfaces/SituationFeature';
import {enrichedDescription} from '../../../../services/SituationFeatureService';
import {ICellRendererParams} from '@ag-grid-community/core';

class OverviewSituationAllTrafficMessage extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {data, showRecordIds, suppressRowClickSelection} = props;
        const dataWithEnrichedDescription =
            data.map(situationFeature => (
                {
                    ...situationFeature,
                    properties:
                        {
                            ...situationFeature.properties,
                            locationDescription: enrichedDescription(situationFeature.properties)
                        }
                })) as SituationFeature[];

        return <React.Fragment>
            <AgGrid<SituationFeature>
                columns={[
                    {
                        valueGetter: item => item.data.properties.id,
                        headerName: 'ID',
                        hide: !showRecordIds
                    },
                    {
                        valueGetter: item => item.data.properties.locationDescription,
                        headerName: 'Omschrijving'
                    },
                    {
                        valueGetter: item => item.data.properties.source,
                        headerName: 'Source'
                    },
                    {
                        cellRenderer: DateCellRenderer,
                        valueGetter: item => item.data.properties.versionTime,
                        headerName: 'Versie tijd'
                    },
                    {
                        valueGetter: item => item.data.properties.subType,
                        headerName: 'Sub type'
                    },
                    {
                        valueGetter: item => item.data.properties.mobilityOfObstruction,
                        headerName: 'mobilityOfObstruction'
                    },
                    {
                        valueGetter: item => item.data.properties.cause,
                        headerName: 'Reden'
                    },
                    {
                        valueGetter: item => item.data.properties.comment,
                        headerName: 'Comment'
                    },
                    {
                        cellRenderer: DateCellRenderer,
                        valueGetter: item => item.data.properties.overallStartTime,
                        headerName: 'Start tijd',
                        sort: 'desc'
                    },
                    {
                        cellRenderer: DateCellRenderer,
                        valueGetter: item => item.data.properties.overallEndTime,
                        headerName: 'Eind tijd'
                    },
                    {
                        cellRenderer: (columnData: ICellRendererParams) => {
                            return columnData.value === true ?
                                '<i class="fas fa-check" style="color: #28a745"/>' :
                                '<i style="color: #dc3545" class="fas fa-times"/>';
                        },
                        valueGetter: item => item.data.properties.safetyRelatedMessage,
                        headerName: 'Veiligheidsgerelateerd bericht'
                    }
                ]}
                data={dataWithEnrichedDescription}
                enableColResize={true}
                enableFilter={true}
                enableSorting={true}
                rowSelection={'single'}
                suppressScrollOnNewData={true}
                suppressRowClickSelection={suppressRowClickSelection}
                onRowClicked={(item) => handleRowClick(this.props, item)}
            />
        </React.Fragment>;
    }

}

export default OverviewSituationAllTrafficMessage;
