import React from 'react';
import {Props} from './Props';
import {LevelDash} from '../LevelDash';

export const OtmConstraintVehicleType: React.FunctionComponent<Props> = ({constraint, level}) => {

    return <>
        <p style={{fontWeight: 'bold'}}>
            <LevelDash level={level}/>

            Vehicle Type Constraint:
        </p>
        <ul>
            {constraint.vehicleTypes.map((vehicleType, index) => <li key={index}>- {vehicleType}</li>)}
        </ul>

        <br/>
    </>;
};
