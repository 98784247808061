import React from 'react';
import NavigationBar from './components/NavigationBar';
import {connect} from 'react-redux';
import {RootState} from '../../reducer';
import {ToastContainer} from 'react-toastify';
import {DispatchProps, Props, StateProps} from './Props';
import {Dispatch} from 'redux';
import {changeCurrentRoute, LayoutActionTypes, layoutComponentRequestedLogoutCurrentUser} from './actions';
import ThemeContext from '../../contexts/ThemeContext';
import {ThemeColorScheme} from '../../themes';

const Layout: React.FunctionComponent<Props> = (
    {
        authenticationIsInDevelopment,
        currentRoute,
        currentThemeColorBlind,
        currentThemeColorScheme,
        notification,
        userProfile,
        onChangeCurrentRoute,
        onRequestedLogout,
        children
    }) => {
    return <React.Fragment>
        <ThemeContext.Provider value={{
            currentColorSchemeIsDark: currentThemeColorScheme === ThemeColorScheme.DARK,
            currentColorSchemeIsSuitableForColorBlindness: currentThemeColorBlind,
            themeColorScheme: currentThemeColorScheme
        }}>
            <NavigationBar
                authenticationIsInDevelopment={authenticationIsInDevelopment}
                currentRoute={currentRoute}
                notification={notification}
                userProfile={userProfile}
                changeCurrentRoute={onChangeCurrentRoute}
                onRequestedLogout={onRequestedLogout}
            />

            {children}

            <ToastContainer
                closeOnClick={false}
                draggable={false}
            />
        </ThemeContext.Provider>
    </React.Fragment>;
};

const mapStateToProps = (state: RootState): StateProps => ({
    authenticationIsInDevelopment: state.keycloakAuthentication.isInDevelopment,
    currentRoute: state.router.location.pathname,
    currentThemeColorBlind: state.userSettings.theme.colorBlind,
    currentThemeColorScheme: state.userSettings.theme.colorScheme,
    notification: state.notification.currentNotification,
    userProfile: state.keycloakAuthentication.userProfile
});

const mapDispatchToProps = (dispatch: Dispatch<LayoutActionTypes>): DispatchProps => ({
    onChangeCurrentRoute: (route: string) => dispatch(changeCurrentRoute(route)),
    onRequestedLogout: () => dispatch(layoutComponentRequestedLogoutCurrentUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
