import {ActionType, createAction} from 'typesafe-actions';
import {DataSource} from '../../../../interfaces/DataSource';
import {MapLayer} from '../../../../interfaces/MapLayer';

export const viewerApiServiceReceivedLayerConfiguration = createAction(
    'VIEWER_API_SERVICE_RECEIVED_LAYER_CONFIGURATION',
    (action) => (layerConfigurations: Array<MapLayer>, dataSourceConfigurations: Array < DataSource >) => action({layerConfigurations, dataSourceConfigurations})
);

export const viewerApiServiceReceivingLayerConfigurationUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_LAYER_CONFIGURATION_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type LayerConfigurationDataActionTypes = ActionType<typeof viewerApiServiceReceivedLayerConfiguration> |
    ActionType<typeof viewerApiServiceReceivingLayerConfigurationUnexpectedError>;
