import {Props} from './Props';
import React from 'react';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {translateSituationType} from '../../../../../../services/Utilities';

export const DialogIncidentManagement: React.FunctionComponent<Props> = (props) => {
    return <>
        <table>
            <tbody>
                {props.showRecordIds && <tr>
                    <th>ID</th>
                    <td>{props.properties.id}</td>
                </tr>}
                <tr>
                    <th>Type</th>
                    <td>{translateSituationType(props.properties.type)}</td>
                </tr>
                <tr>
                    <th>Start tijd</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.creationTime}/>
                    </td>
                </tr>
                <tr>
                    <th>Laatste update tijd</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.lastUpdatedTime}/>
                    </td>
                </tr>
                <tr>
                    <th>Kans op gebeurtenis</th>
                    <td>
                        {props.properties.levelOfPersistence}%
                    </td>
                </tr>

                <tr>
                    <th>Incident Beveiligd</th>
                    <td>
                        {props.properties.isProtected ? 'Ja' : 'Nee'}
                    </td>
                </tr>
                <tr>
                    <th>Incident bekend bij VC</th>
                    <td>
                        {props.properties.isKnownByTmc ? 'Ja' : 'Nee'}
                    </td>
                </tr>
            </tbody>
        </table>
    </>;
};
