import {GeoJsonPropertiesSituation} from '../../interfaces/GeoJsonSources/GeoJsonPropertiesSituation';

export function mapToIconName(featureProperties: GeoJsonPropertiesSituation): string {
    switch (featureProperties.type) {
        case 'Accident':
            return 'ndw-im-accident-red-25';
        case 'VehicleObstruction':
            return vehicleObstructionIcon(featureProperties);
        case 'AbnormalTraffic' :
            return 'ndw-abnormaltraffic-25';
        case 'GeneralNetworkManagement' :
            return generalNetworkManagementIcon(featureProperties);
        case 'MaintenanceWorks':
        case 'ConstructionWorks':
            return 'ndw-roadworks-25';
        case 'PublicEvent' :
            return 'ndw-publicevent-25';
        case 'ReroutingManagement' :
            return 'ndw-rerouting-25';
        case 'RoadOrCarriagewayOrLaneManagement' :
            return 'ndw-gnmanagement-25';
        case 'SpeedManagement' :
            return 'ndw-speedmanagement-25';
        default:
            return 'ndw-other-25';
    }
}

function vehicleObstructionIcon(featureProperties: GeoJsonPropertiesSituation) {
    switch (featureProperties.subType) {
        case 'emergencyVehicle':
            return 'ndw-emergency-25';
        default:
            return 'ndw-vehicleobstruction-25';
    }
}

function generalNetworkManagementIcon(featureProperties: GeoJsonPropertiesSituation) {
    switch (featureProperties.subType) {
        case 'bridgeSwingInOperation':
            return 'bridgeswing';
        case 'trafficBeingManuallyDirected':
            return 'roadworks';
        default:
            return 'ndw-other-25';
    }
}