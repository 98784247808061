import {ActionType, createAction} from 'typesafe-actions';
import {HistoricalSummedDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedHistoricalSummedData = createAction(
    'VIEWER_API_SERVICE_RECEIVED_HISTORICAL_SUMMED_DATA',
    (action) => (data: HistoricalSummedDTO) => action({data})
);

export const viewerApiServiceReceivingHistoricalSummedDataFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_HISTORICAL_SUMMED_DATA_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingHistoricalSummedDataFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_HISTORICAL_SUMMED_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type HistoricalSummedDataDataActionTypes = ActionType<typeof viewerApiServiceReceivedHistoricalSummedData> |
    ActionType<typeof viewerApiServiceReceivingHistoricalSummedDataFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceReceivingHistoricalSummedDataFailedWithUnexpectedError>;
