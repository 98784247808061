import {StyleSheet} from 'aphrodite/no-important';

export const colorBlindnessStyles = StyleSheet.create({
    paneHeader: {
        backgroundColor: '#ff7f00'
    }
});

export const styles = StyleSheet.create({
    sidebarContentPaneInactive: {
        boxSizing: 'border-box',
        display: 'none',
        left: 0,
        right: 0,
        transition: 'display 500ms'
    },
    sidebarContentPaneActive: {
        display: 'initial'
    },
    paneContent: {
        height: 'calc(100% - 60px)',
        overflowY: 'auto',
        padding: '10px 20px'
    },
    paneHeader: {
        backgroundColor: '#F8981D',
        color: 'white',
        fontSize: '14.4pt',
        fontWeight: 'bold',
        height: '40px',
        paddingInline: '20px',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
    }
});
