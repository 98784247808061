export enum RoadOperatorType {
    STATE = 'State',
    MUNICIPALITY = 'Municipality',
    PROVINCE = 'Province',
    OTHER = 'Other',
    WATER_AUTHORITY = 'WaterAuthority'
}

export interface RoadOperator {
    id: string;
    name: string;
    code: string;
    type: RoadOperatorType;
}
