import {ActionType, createAction} from 'typesafe-actions';
import {BridgeHistoryDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedBridgeHistory = createAction(
    'VIEWER_API_SERVICE_RECEIVED_BRIDGE_HISTORY',
    (action) =>
        (risIndex: string, vild: string, bridgeHistoryData: BridgeHistoryDTO) =>
            action({risIndex, vild, bridgeHistoryData})
);

export const viewerApiServiceReceivingBridgeHistoryFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_BRIDGE_HISTORY_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingBridgeHistoryFailedWithUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_BRIDGE_HISTORY_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type LoadBridgeHistoryActionTypes = ActionType<typeof viewerApiServiceReceivedBridgeHistory> |
    ActionType<typeof viewerApiServiceReceivingBridgeHistoryFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceReceivingBridgeHistoryFailedWithUnexpectedError>;
