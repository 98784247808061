import React from 'react';
import {Props} from './Props';
import ButtonIcon from '../../../../../ButtonIcon';

const UserMenu: React.FunctionComponent<Props> = ({authenticationIsInDevelopment, userProfile, onRequestedLogout}) => {

    const userFullName = (): string => {

        if (userProfile) {
            return `${userProfile.firstName} ${userProfile.lastName}`;
        }

        if (authenticationIsInDevelopment) {
            return 'Test gebruiker';
        }

        return '';
    };

    const handleClickedOnLogout = () => {
        // eslint-disable-next-line no-alert
        if (!window.confirm('Weet je zeker dat je wilt uitloggen')) {
            return;
        }

        onRequestedLogout();
    };

    return (
        <>
            <span>Welkom, {userFullName()}</span>
            <ButtonIcon
                iconClassName="fas fa-sign-out-alt"
                onClick={handleClickedOnLogout}
                aria-label="Uitloggen"
                title="Uitloggen"
                addHoverStyle
            />
        </>
    );

};

export default UserMenu;
