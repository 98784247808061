import {MapLayer as MapLayerInterface} from '../interfaces/MapLayer';
import {Feature, Geometry} from 'geojson';

export interface BaseOverviewItem {
    id: string;
    latitude: number;
    longitude: number;
}

export interface BaseGeoJsonItem {
    id: string;
    icon: string;
}

class MapLayerModel {

    getLayerIsActive(layerId: string, layers: MapLayerInterface[]): boolean {
        for (const layer of layers) {
            if (layer.id === layerId && layer.isActive) {
                return true;
            }
        }

        return false;
    }

    convertGeoJsonFeatureToOverviewItem<GeoJsonFeatureProperties extends BaseGeoJsonItem, OverviewDTO extends BaseOverviewItem>
    (geoJsonFeature: Feature<Geometry, GeoJsonFeatureProperties>): OverviewDTO {
        let latitude: number | null = null;
        let longitude: number | null = null;

        if (geoJsonFeature.geometry) {
            if (geoJsonFeature.geometry.type === 'Point') {
                latitude = geoJsonFeature.geometry.coordinates[0];
                longitude = geoJsonFeature.geometry.coordinates[1];
            } else if (geoJsonFeature.geometry.type === 'LineString') {
                latitude = geoJsonFeature.geometry.coordinates[0][0];
                longitude = geoJsonFeature.geometry.coordinates[0][1];
            }
        }

        let resultObject: { latitude: number; id: string; longitude: number } = {
            id: geoJsonFeature.properties.id,
            latitude: 0,
            longitude: 0
        };

        if (latitude) {
            resultObject.latitude = latitude;
        }
        if (longitude) {
            resultObject.longitude = longitude;
        }

        resultObject = {
            ...resultObject,
            ...geoJsonFeature.properties
        };

        // @ts-ignore
        return resultObject;
    }
}

export default MapLayerModel;
