import React, {MouseEvent, useContext} from 'react';
import {Props} from './Props';
import {css} from 'aphrodite/no-important';
import {colorBlindnessStyles, styles} from './styles';
import ThemeContext from '../../contexts/ThemeContext';

const BooleanToggle: React.FunctionComponent<Props> = ({active, title, changeStatus}) => {
    const themeContext = useContext(ThemeContext);
    const userClicked = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        changeStatus(!active);
    };

    const buttonIcon = (): string => {
        if (active) {
            return 'fas fa-check';
        } else {
            return 'fas fa-dot-circle';
        }
    };

    return <button
        type="button"
        className={css(
            styles.buttonContainer,
            themeContext.currentColorSchemeIsSuitableForColorBlindness && colorBlindnessStyles.buttonContainer,
            active && styles.buttonContainerActive,
            active && themeContext.currentColorSchemeIsSuitableForColorBlindness && colorBlindnessStyles.buttonContainerActive
        )}
        onClick={userClicked}
        aria-label={active ? `Verberg ${title}` : `Toon ${title}`}
    >
        <span className={css(styles.icon) + ' ' + buttonIcon()}/>
        <span>{title}</span>
    </button>;
};

export default BooleanToggle;
