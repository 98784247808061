import {createAction} from 'typesafe-actions';
import {OverviewAllTable, OverviewTableSource} from '../../../../interfaces/OverviewAllTable';

export const setNewVisibleOverviewAllTable = createAction(
    'SET_NEW_VISIBLE_OVERVIEW_ALL_TABLE',
    (action) => (newTable: OverviewAllTable | null) => action({newTable})
);

export const mapSceneOverviewTableChangeOverviewTableSource = createAction(
    'MAP_SCENE_OVERVIEW_TABLE_CHANGE_OVERVIEW_TABLE_SOURCE',
    (action) => (newOverviewTableSource: OverviewTableSource) => action({newOverviewTableSource})
);

export const updateOverviewTableHeight = createAction(
    'UPDATE_OVERVIEW_TABLE_HEIGHT',
    (action) => (newHeight: number) => action({newHeight})
);
