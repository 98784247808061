import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import MessageAgeInMinutes from '../../../../../../components/MessageAgeInMinutes';

class DialogRoadWorkPlanned extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Versie</th>
                        <td>{properties.version}</td>
                    </tr>
                    <tr>
                        <th>Bron</th>
                        <td>{properties.source}</td>
                    </tr>
                    <tr>
                        <th>Soort</th>
                        <td>{properties.type}</td>
                    </tr>
                    <tr>
                        <th>SRTI type</th>
                        <td>{properties.subType}</td>
                    </tr>
                    <tr>
                        <th>Operator action status</th>
                        <td>{properties.operatorActionStatus}</td>
                    </tr>
                    <tr>
                        <th>Probability of occurrence</th>
                        <td>{properties.probabilityOfOccurrence}</td>
                    </tr>
                    <tr>
                        <th>Aangemaakt op</th>
                        <td>
                            <DateTimeFormatted
                                dateTime={properties.creationTime}
                            />
                            <br/>
                            <MessageAgeInMinutes
                                time={properties.creationTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Laatste aanpassing op</th>
                        <td>
                            <DateTimeFormatted
                                dateTime={properties.versionTime}
                            />
                            <br/>
                            <MessageAgeInMinutes
                                time={properties.versionTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Begin tijd</th>
                        <td>
                            <DateTimeFormatted
                                dateTime={properties.overallStartTime}
                            />
                            <br/>
                            <MessageAgeInMinutes
                                time={properties.overallStartTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Eind tijd</th>
                        <td>
                            <DateTimeFormatted
                                dateTime={properties.overallEndTime}
                            />
                            <br/>
                            <MessageAgeInMinutes
                                time={properties.overallEndTime}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>;
    }
}

export default DialogRoadWorkPlanned;
