import React, {useMemo} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';

export const DialogFd: React.FunctionComponent<Props> = ({properties, showRecordIds}) => {

    const abnormalTrafficTypeFormatted = useMemo(
        () => properties.abnormalTrafficType === 'STATIONARY_TRAFFIC' ? 'Stilstaand verkeer' : 'Langzaam rijdend verkeer',
        [properties.abnormalTrafficType]
    );

    const delayTimeInSecondsFormatted = useMemo(
        () => {
            if (properties.delayTimeInSecond < 60) {
                return `${properties.delayTimeInSecond} seconden`;
            }

            const delayTimeInMinutes = Math.round(properties.delayTimeInSecond / 60);

            if (delayTimeInMinutes === 1) {
                return '1 minuut';
            }

            return `${delayTimeInMinutes} minuten`;
        },
        [properties.delayTimeInSecond]
    );

    return <>
        <table>
            <tbody>
                {showRecordIds && <tr>
                    <th>ID</th>
                    <td>{properties.id}</td>
                </tr>}
                <tr>
                    <th>Filelengte</th>
                    <td>{(Number(properties.queueLength) / 1000).toFixed(1)}km</td>
                </tr>
                {showRecordIds && <tr>
                    <th>Filelengte (ruw)</th>
                    <td>{properties.queueLength}</td>
                </tr>}
                <tr>
                    <th>Vertragingstijd</th>
                    <td>{delayTimeInSecondsFormatted}</td>
                </tr>
                {showRecordIds && <tr>
                    <th>Vertragingstijd (ruw)</th>
                    <td>{properties.delayTimeInSecond}</td>
                </tr>}
                <tr>
                    <th>Rijbaan</th>
                    <td>{properties.headDvkDescription}</td>
                </tr>
                <tr>
                    <th>Kop</th>
                    <td>{properties.headLocation}</td>
                </tr>
                <tr>
                    <th>Staart</th>
                    <td>{properties.tailLocation}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{abnormalTrafficTypeFormatted}</td>
                </tr>
                {showRecordIds && <tr>
                    <th>Aanmaak tijd</th>
                    <td><DateTimeFormatted dateTime={properties.creationTime}/></td>
                </tr>}
                {showRecordIds && <tr>
                    <th>Starttijd</th>
                    <td><DateTimeFormatted dateTime={properties.overallStartTime}/></td>
                </tr>}
                {showRecordIds && <tr>
                    <th>Versie tijd</th>
                    <td><DateTimeFormatted dateTime={properties.versionTime}/></td>
                </tr>}
                {showRecordIds && <tr>
                    <th>Versie</th>
                    <td>{properties.version}</td>
                </tr>}
            </tbody>
        </table>
    </>;
};
