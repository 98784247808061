import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    showMoreButton: {
        textAlign: 'center',
        cursor: 'pointer',
        height: '22px',
        fontWeight: 'bolder'
    },
    showContent: {
        maxHeight: '600px',
        overflow: 'auto',
        transition: 'max-height 0.3s ease-out'
    },
    hideContent: {
        maxHeight: '0',
        overflow: 'auto',
        transition: 'max-height 0.3s ease-out'
    },
    subTitle: {
        fontSize: '0.8em'
    },
    description: {
        fontWeight: 'bolder'
    }
});

export default styles;
