import {StyleSheet} from 'aphrodite/no-important';
import {NAV_BAR_HEIGHT} from '../../styles';

const styles = StyleSheet.create({
    closeAllDialogsContainer: {
        left: 50,
        position: 'absolute',
        top: 65,
        zIndex: 50
    },
    containerMapViewPart: {
        height: '100%',
        position: 'relative',
        width: '100%'
    },
    dataIsOutdated: {
        backgroundColor: 'rgba(255, 255, 255, .8)',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        color: '#f58231',
        padding: 10,
        position: 'absolute',
        right: 80,
        top: 0
    },
    mapSceneContainer: {
        height: `calc(100% - ${NAV_BAR_HEIGHT + (2 * 8)}px)`,
        position: 'relative',
        width: '100%'
    }
});

export default styles;
