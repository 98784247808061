import React, {useContext} from 'react';
import {Props} from './Props';
import {css} from 'aphrodite';
import {colorBlindnessStyles, styles} from './styles';
import ThemeContext from '../../../../../../../../contexts/ThemeContext';
import {formatDateTimeWithDayOfWeek, formatTime} from '../../../../../../../../utils/DateUtil';
import {enrichedDescription} from '../../../../../../../../services/SituationFeatureService';

const TrafficMessageRow: React.FunctionComponent<Props> = ({
    clickAvailable,
    highlight,
    situationFeature,
    onClickedOnSituationFeature
}) => {
    const themeContext = useContext(ThemeContext);

    const startDateTime = new Date(situationFeature.properties.overallStartTime);
    const dateString = formatDateTimeWithDayOfWeek(startDateTime);
    const timeString = formatTime(startDateTime, false);

    return <div
        className={css(
            styles.rowContainer,
            clickAvailable && styles.rowContainerClickAvailable,
            highlight && styles.highlightedRow,
            highlight && themeContext.currentColorSchemeIsSuitableForColorBlindness && colorBlindnessStyles.highlightedRow
        )}
        onClick={() => onClickedOnSituationFeature()}
    >
        <div className={css(styles.titleRow)}>{situationFeature.properties.locationDescription}</div>
        <div className={css(styles.timestamp)}>Sinds {dateString} om {timeString} uur</div>
        <div className={css(styles.extraDetails)}>{enrichedDescription(situationFeature.properties)}</div>
    </div>;
};

export default TrafficMessageRow;
