import React, {useContext} from 'react';
import {css} from 'aphrodite/no-important';
import {Props} from './Props';
import {colorBlindnessStyles, styles} from './styles';
import ThemeContext from '../../../../../../contexts/ThemeContext';
import ButtonIcon from '../../../../../../components/ButtonIcon';

const SidebarPane: React.FunctionComponent<Props> = ({identifier, visible, children, title, onSidebarClose}) => {
    const themeContext = useContext(ThemeContext);

    return <section
        id={identifier}
        className={css(styles.sidebarContentPaneInactive, visible && styles.sidebarContentPaneActive)}
    >
        <div className={css(
            styles.paneHeader,
            themeContext.currentColorSchemeIsSuitableForColorBlindness && colorBlindnessStyles.paneHeader
        )}>
            <ButtonIcon
                iconClassName="fas fa-caret-right"
                onClick={onSidebarClose}
                aria-expanded={visible}
                aria-controls={identifier}
                aria-label={visible ? 'Verberg' : 'Toon'}
            />
            <span>{title}</span>
        </div>

        <div className={css(styles.paneContent)}>
            {children}
        </div>
    </section>;
};

export default SidebarPane;
