import {StyleSheet} from 'aphrodite';

export const colorBlindnessStyles = StyleSheet.create({
    buttonContainerActive: {
        backgroundColor: '#ff7f00'
    },
    buttonContainer: {
        backgroundColor: '#434348',
        color: 'white'
    }
});

export const styles = StyleSheet.create({
    buttonContainer: {
        'display': 'block',
        'border': 'none',
        'textAlign': 'start',
        'width': '100%',
        ':active': {
            backgroundColor: 'rgb(210, 210, 210)',
            boxShadow: 'inset 0 0 5px 1px rgba(68,68,68,0.6)',
            color: 'white'
        },
        'backgroundColor': '#efefef',
        'borderRadius': 4,
        'color': '#b3b3b3',
        'cursor': 'pointer',
        'marginBottom': 5,
        'marginTop': 5,
        'maxWidth': '250px',
        'padding': 10,
        'transition': 'background-color 0.25s, color 0.25s, box-shadow 0.25s',
        'userSelect': 'none'
    },
    buttonContainerActive: {
        ':active': {
            backgroundColor: '#c77000',
            color: 'white'
        },
        'backgroundColor': '#F8981D',
        'color': 'white'
    },
    icon: {
        transition: 'content 0.25s',
        width: 25
    }
});

export default styles;
