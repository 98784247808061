import {createAction} from 'typesafe-actions';
import {MapView} from '../../../../interfaces/Mapbox';
import {MapFeature} from '../../../../interfaces/MapFeature';

export const disableMapLayer = createAction(
    'DISABLE_MAP_LAYER',
    (action) => (layerID: string) => action({layerID})
);

export const enableMapLayer = createAction(
    'ENABLE_MAP_LAYER',
    (action) => (layerID: string) => action({layerID})
);

export const showPopupForFeatures = createAction(
    'SHOW_POPUP_FOR_FEATURES',
    (action) => (clickedFeatures: MapFeature[], pointX: number, pointY: number) => action({
        clickedFeatures,
        pointX,
        pointY
    })
);

export const showPreviewPopupForFeatures = createAction(
    'SHOW_PREVIEW_POPUP_FOR_FEATURES',
    (action) => (clickedFeatures: MapFeature[], pointX: number, pointY: number) => action({
        clickedFeatures,
        pointX,
        pointY
    })
);

export const hidePreviewPopup = createAction(
    'HIDE_PREVIEW_POPUP',
    (action) => () => action({
    })
);

export const updateMapIsReadyStatus = createAction(
    'UPDATE_MAP_IS_READY_STATUS',
    (action) => (status: boolean) => action({status})
);

export const updateMapViewport = createAction(
    'UPDATE_MAP_VIEWPORT',
    (action) => (newViewport: MapView) => action({newViewport})
);
