import {Feature} from 'geojson';

export enum ViewerWebsocketMessageType {
    UPDATE = 'UPDATE',
    UPDATE_EVENT = 'UpdateEvent',
    STREAMING_UPDATE_EVENT = 'StreamingUpdateEvent',
    RESET_EVENT = 'ResetEvent',
    STREAMING_RESET_EVENT = 'StreamingResetEvent',
    HEARTBEAT = 'HEARTBEAT'
}

interface ViewerWebsocketBaseMessage {
    type: ViewerWebsocketMessageType;
}

export interface ViewerWebsocketUpdateMessage<UpdateFeature extends Feature> extends ViewerWebsocketBaseMessage {
    type: ViewerWebsocketMessageType.STREAMING_UPDATE_EVENT | ViewerWebsocketMessageType.UPDATE | ViewerWebsocketMessageType.UPDATE_EVENT | ViewerWebsocketMessageType.RESET_EVENT | ViewerWebsocketMessageType.STREAMING_RESET_EVENT;
    timestamp: number;
    created: UpdateFeature[];
    updated: UpdateFeature[];
    removed: string[];
}

export interface ViewerWebsocketResetEvent extends ViewerWebsocketBaseMessage {
    type: ViewerWebsocketMessageType.STREAMING_RESET_EVENT;
    timestamp: number;
}

export interface ViewerWebsocketHeartbeat extends ViewerWebsocketBaseMessage {
    type: ViewerWebsocketMessageType.HEARTBEAT;
}

export type VIEWER_WEBSOCKET_MESSAGE = ViewerWebsocketUpdateMessage<Feature> | ViewerWebsocketHeartbeat;
