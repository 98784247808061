import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    geoCoderWrapper: {
        left: 50,
        position: 'absolute',
        top: 10,
        zIndex: 50
    },
    mapContainer: {
        height: '100%',
        width: '100%'
    }
});

export default styles;
