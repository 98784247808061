import {OverviewAllTable, OverviewAllTables, OverviewTableSourceType} from './interfaces/OverviewAllTable';
import {WazeAlertItemStatus} from './interfaces/WazeAlertItemStatus';
import {convertWazeTypeAndSubtypeToTranslatedEventType, translateSituationType} from './services/Utilities';
import {
    TrafficInformationSource
} from './scenes/MapScene/components/Sidebar/components/TrafficMessagesPane/TrafficInformation';
import {SituationTypeFilter} from './scenes/MapScene/reducers/filterAndSearchReducer';
import {RoadOperator, RoadOperatorType} from './interfaces/RoadOperator';
import {keycloakService} from '@ndw/react-keycloak-authentication';

export const KEYCLOAK_CONFIG: NonNullable<typeof keycloakService.keycloakConfig> = {
    realm: 'ndw',
    url: 'https://iam.ndw.nu/auth',
    clientId: 'viewer'
};

export const overviewAllTables = (): OverviewAllTable[] => {
    const tables: OverviewAllTable[] = [
        {
            id: OverviewAllTables.SituationOnda,
            name: 'Bergingen',
            sources: [
                {
                    name: 'Bergingen',
                    mapLayers: ['onda'],
                    dataSource: 'situationV2',
                    sourceType: OverviewTableSourceType.SituationOnda
                }
            ]
        },
        {
            id: OverviewAllTables.SituationTrafficMessages,
            name: 'Verkeersberichten',
            sources: [
                {
                    mapLayers: ['trafficMessageOtis', 'trafficMessageFlisvis'],
                    dataSource: 'situationV2',
                    name: 'Verkeersberichten',
                    sourceType: OverviewTableSourceType.SituationTrafficMessages
                }
            ]
        },
        {
            id: OverviewAllTables.SituationBridge,
            name: 'Bruggen',
            sources: [
                {
                    mapLayers: ['bridge'],
                    dataSource: 'situationV2',
                    name: 'Bruggen',
                    sourceType: OverviewTableSourceType.SituationBridge
                }
            ]
        },
        {
            id: OverviewAllTables.SituationRoadWorkActual,
            name: 'Wegwerkzaamheden',
            sources: [
                {
                    mapLayers: ['roadWorkActual'],
                    dataSource: 'situationV2',
                    name: 'Wegwerkzaamheden',
                    sourceType: OverviewTableSourceType.SituationRoadWorkActual
                }
            ]
        }
    ];

    const overviewTableIncidents: OverviewAllTable = {
        id: OverviewAllTables.INCIDENTS,
        name: 'Incidenten',
        sources: [
            {
                mapLayers: ['wazeAlerts'],
                dataSource: 'wazeAlertIncident',
                name: 'Waze incidenten',
                sourceType: OverviewTableSourceType.WazeAlertIncident
            },
            {
                mapLayers: ['wazeAlertsOther'],
                dataSource: 'waze',
                name: 'Waze overigen',
                sourceType: OverviewTableSourceType.WazeAlertOther
            }
        ]
    };

    tables.push(overviewTableIncidents);

    return tables;
};
const createSituationTypeFilter = (situationTypeValue: string, situationType: string = situationTypeValue): SituationTypeFilter => {
    return {label: translateSituationType(situationType), value: situationTypeValue};
};
export const otisSituationTypeFilter: SituationTypeFilter[] = [
    createSituationTypeFilter('AbnormalTraffic'),
    createSituationTypeFilter('Accident'),
    createSituationTypeFilter('AnimalPresenceObstruction'),
    createSituationTypeFilter('EnvironmentalObstruction;GeneralObstruction;VehicleObstruction', 'EnvironmentalObstruction'),
    createSituationTypeFilter('RoadsideAssistance'),
    createSituationTypeFilter('RoadOrCarriagewayOrLaneManagement'),
    createSituationTypeFilter('SpeedManagement'),
    createSituationTypeFilter('PublicEvent'),
    createSituationTypeFilter('AuthorityOperation'),
    createSituationTypeFilter('ConstructionWorks;MaintenanceWorks', 'ConstructionWorks'),
    createSituationTypeFilter('NonWeatherRelatedRoadConditions'),
    createSituationTypeFilter('WeatherRelatedRoadConditions'),
    createSituationTypeFilter('ReroutingManagement'),
    createSituationTypeFilter('DisturbanceActivity'),
    createSituationTypeFilter('GeneralInstructionOrMessageToRoadUsers'),
    createSituationTypeFilter('RoadOperatorServiceDisruption'),
    createSituationTypeFilter('RoadsideServiceDisruption'),
    createSituationTypeFilter('TransitInformation'),
    createSituationTypeFilter('EquipmentOrSystemFault'),
    createSituationTypeFilter('InfrastructureDamageObstruction'),
    createSituationTypeFilter('PoorEnvironmentConditions'),
    createSituationTypeFilter('SignSetting'),
    createSituationTypeFilter('GeneralNetworkManagement'),
    createSituationTypeFilter('WinterDrivingManagement'),
    createSituationTypeFilter('CarParks')
];


export const wazeTypesOptions: WazeAlertItemStatus[] = [
    {
        icon: '/assets/legend-icons/ndw-waze-other-new-25.svg',
        name: convertWazeTypeAndSubtypeToTranslatedEventType('', 'ACCIDENT_MAJOR'),
        subType: 'ACCIDENT_MAJOR',
        type: 'ACCIDENT'
    },
    {
        icon: '/assets/legend-icons/ndw-waze-accident-new-25.svg',
        name: convertWazeTypeAndSubtypeToTranslatedEventType('', 'ACCIDENT_MINOR'),
        subType: 'ACCIDENT_MINOR',
        type: 'ACCIDENT'
    },
    {
        icon: '/assets/legend-icons/ndw-waze-accident-new-25.svg',
        name: convertWazeTypeAndSubtypeToTranslatedEventType('', 'HAZARD_ON_ROAD'),
        subType: 'HAZARD_ON_ROAD',
        type: 'ACCIDENT'
    },
    {
        icon: '/assets/legend-icons/ndw-waze-accident-new-25.svg',
        name: convertWazeTypeAndSubtypeToTranslatedEventType('', 'HAZARD_ON_ROAD_CAR_STOPPED'),
        subType: 'HAZARD_ON_ROAD_CAR_STOPPED',
        type: 'ACCIDENT'
    },
    {
        icon: '/assets/legend-icons/ndw-waze-accident-new-25.svg',
        name: convertWazeTypeAndSubtypeToTranslatedEventType('', 'HAZARD_ON_ROAD_OBJECT'),
        subType: 'HAZARD_ON_ROAD_OBJECT',
        type: 'ACCIDENT'
    },
    {
        icon: '/assets/legend-icons/ndw-waze-accident-new-25.svg',
        name: convertWazeTypeAndSubtypeToTranslatedEventType('', 'HAZARD_ON_SHOULDER_CAR_STOPPED'),
        subType: 'HAZARD_ON_SHOULDER_CAR_STOPPED',
        type: 'ACCIDENT'
    }
];

export const trafficInformationResources: TrafficInformationSource[] = [
    {
        dataSource: 'situationV2',
        mapLayer: 'trafficMessageOtis',
        dataType: 'situationTrafficMessageOtis'
    },
    {
        dataSource: 'situationV2',
        mapLayer: 'trafficMessageFlisvis',
        dataType: 'situationTrafficMessageFlisvis'
    }
];


export enum FrontOfficeEventStatus {
    startAction = 'START_ACTION',
    selfAction = 'SELF_ACTION',
    noAction = 'NO_ACTION',
    knowMessage = 'KNOW_MESSAGE',
    none = 'NONE'
}

export const noFeaturesVisibleToastId = 'NO_FEATURES_VISIBLE_TOAST_ID';

export const roadOperatorTypeLabels = {
    [RoadOperatorType.STATE]: 'Rijk',
    [RoadOperatorType.MUNICIPALITY]: 'Gemeente',
    [RoadOperatorType.PROVINCE]: 'Provincie',
    [RoadOperatorType.OTHER]: 'Overig',
    [RoadOperatorType.WATER_AUTHORITY]: 'Waterschap'
};

export const roadOperators: Readonly<RoadOperator[]> = [
    {
        id: 'Municipality_1680',
        name: 'Aa en Hunze',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1680'
    },
    {
        id: 'Municipality_738',
        name: 'Aalburg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '738'
    },
    {
        id: 'Municipality_358',
        name: 'Aalsmeer',
        type: RoadOperatorType.MUNICIPALITY,
        code: '358'
    },
    {
        id: 'Municipality_197',
        name: 'Aalten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '197'
    },
    {
        id: 'Municipality_59',
        name: 'Achtkarspelen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '59'
    },
    {
        id: 'Municipality_2',
        name: 'Aduard',
        type: RoadOperatorType.MUNICIPALITY,
        code: '2'
    },
    {
        id: 'Municipality_482',
        name: 'Alblasserdam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '482'
    },
    {
        id: 'Municipality_613',
        name: 'Albrandswaard',
        type: RoadOperatorType.MUNICIPALITY,
        code: '613'
    },
    {
        id: 'Municipality_361',
        name: 'Alkmaar',
        type: RoadOperatorType.MUNICIPALITY,
        code: '361'
    },
    {
        id: 'Municipality_141',
        name: 'Almelo',
        type: RoadOperatorType.MUNICIPALITY,
        code: '141'
    },
    {
        id: 'Municipality_34',
        name: 'Almere',
        type: RoadOperatorType.MUNICIPALITY,
        code: '34'
    },
    {
        id: 'Municipality_484',
        name: 'Alphen aan den Rijn',
        type: RoadOperatorType.MUNICIPALITY,
        code: '484'
    },
    {
        id: 'Municipality_1723',
        name: 'Alphen-Chaam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1723'
    },
    {
        id: 'Municipality_1959',
        name: 'Altena',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1959'
    },
    {
        id: 'Municipality_60',
        name: 'Ameland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '60'
    },
    {
        id: 'Municipality_307',
        name: 'Amersfoort',
        type: RoadOperatorType.MUNICIPALITY,
        code: '307'
    },
    {
        id: 'Municipality_362',
        name: 'Amstelveen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '362'
    },
    {
        id: 'Municipality_363',
        name: 'Amsterdam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '363'
    },
    {
        id: 'Municipality_200',
        name: 'Apeldoorn',
        type: RoadOperatorType.MUNICIPALITY,
        code: '200'
    },
    {
        id: 'Municipality_3',
        name: 'Appingedam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '3'
    },
    {
        id: 'Municipality_202',
        name: 'Arnhem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '202'
    },
    {
        id: 'Municipality_106',
        name: 'Assen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '106'
    },
    {
        id: 'Municipality_743',
        name: 'Asten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '743'
    },
    {
        id: 'Municipality_744',
        name: 'Baarle-Nassau',
        type: RoadOperatorType.MUNICIPALITY,
        code: '744'
    },
    {
        id: 'Municipality_308',
        name: 'Baarn',
        type: RoadOperatorType.MUNICIPALITY,
        code: '308'
    },
    {
        id: 'Municipality_489',
        name: 'Barendrecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '489'
    },
    {
        id: 'Municipality_203',
        name: 'Barneveld',
        type: RoadOperatorType.MUNICIPALITY,
        code: '203'
    },
    {
        id: 'Municipality_5',
        name: 'Bedum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '5'
    },
    {
        id: 'Municipality_888',
        name: 'Beek',
        type: RoadOperatorType.MUNICIPALITY,
        code: '888'
    },
    {
        id: 'Municipality_746',
        name: 'Beek en Donk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '746'
    },
    {
        id: 'Municipality_1954',
        name: 'Beekdaelen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1954'
    },
    {
        id: 'Municipality_889',
        name: 'Beesel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '889'
    },
    {
        id: 'Municipality_1945',
        name: 'Berg en Dal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1945'
    },
    {
        id: 'Municipality_1724',
        name: 'Bergeijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1724'
    },
    {
        id: 'Municipality_893',
        name: 'Bergen (L.)',
        type: RoadOperatorType.MUNICIPALITY,
        code: '893'
    },
    {
        id: 'Municipality_373',
        name: 'Bergen (NH.)',
        type: RoadOperatorType.MUNICIPALITY,
        code: '373'
    },
    {
        id: 'Municipality_748',
        name: 'Bergen op Zoom',
        type: RoadOperatorType.MUNICIPALITY,
        code: '748'
    },
    {
        id: 'Municipality_1859',
        name: 'Berkelland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1859'
    },
    {
        id: 'Municipality_1721',
        name: 'Bernheze',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1721'
    },
    {
        id: 'Municipality_753',
        name: 'Best',
        type: RoadOperatorType.MUNICIPALITY,
        code: '753'
    },
    {
        id: 'Municipality_209',
        name: 'Beuningen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '209'
    },
    {
        id: 'Municipality_375',
        name: 'Beverwijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '375'
    },
    {
        id: 'Municipality_585',
        name: 'Binnenmaas',
        type: RoadOperatorType.MUNICIPALITY,
        code: '585'
    },
    {
        id: 'Municipality_1728',
        name: 'Bladel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1728'
    },
    {
        id: 'Municipality_754',
        name: 'Bladel en Netersel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '754'
    },
    {
        id: 'Municipality_376',
        name: 'Blaricum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '376'
    },
    {
        id: 'Municipality_377',
        name: 'Bloemendaal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '377'
    },
    {
        id: 'Municipality_1901',
        name: 'Bodegraven-Reeuwijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1901'
    },
    {
        id: 'Municipality_755',
        name: 'Boekel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '755'
    },
    {
        id: 'Municipality_1681',
        name: 'Borger-Odoorn',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1681'
    },
    {
        id: 'Municipality_147',
        name: 'Borne',
        type: RoadOperatorType.MUNICIPALITY,
        code: '147'
    },
    {
        id: 'Municipality_654',
        name: 'Borsele',
        type: RoadOperatorType.MUNICIPALITY,
        code: '654'
    },
    {
        id: 'Municipality_757',
        name: 'Boxtel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '757'
    },
    {
        id: 'Municipality_758',
        name: 'Breda',
        type: RoadOperatorType.MUNICIPALITY,
        code: '758'
    },
    {
        id: 'Municipality_380',
        name: 'Broek In Waterland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '380'
    },
    {
        id: 'Municipality_1876',
        name: 'Bronckhorst',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1876'
    },
    {
        id: 'Municipality_213',
        name: 'Brummen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '213'
    },
    {
        id: 'Municipality_899',
        name: 'Brunssum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '899'
    },
    {
        id: 'Municipality_312',
        name: 'Bunnik',
        type: RoadOperatorType.MUNICIPALITY,
        code: '312'
    },
    {
        id: 'Municipality_313',
        name: 'Bunschoten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '313'
    },
    {
        id: 'Municipality_214',
        name: 'Buren',
        type: RoadOperatorType.MUNICIPALITY,
        code: '214'
    },
    {
        id: 'Municipality_502',
        name: 'Capelle aan den IJssel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '502'
    },
    {
        id: 'Municipality_383',
        name: 'Castricum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '383'
    },
    {
        id: 'Municipality_109',
        name: 'Coevorden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '109'
    },
    {
        id: 'Municipality_1706',
        name: 'Cranendonck',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1706'
    },
    {
        id: 'Municipality_1684',
        name: 'Cuijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1684'
    },
    {
        id: 'Municipality_216',
        name: 'Culemborg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '216'
    },
    {
        id: 'Municipality_148',
        name: 'Dalfsen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '148'
    },
    {
        id: 'Municipality_1891',
        name: 'Dantumadiel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1891'
    },
    {
        id: 'Municipality_310',
        name: 'De Bilt',
        type: RoadOperatorType.MUNICIPALITY,
        code: '310'
    },
    {
        id: 'Municipality_1940',
        name: 'De Fryske Marren',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1940'
    },
    {
        id: 'Municipality_736',
        name: 'De Ronde Venen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '736'
    },
    {
        id: 'Municipality_1690',
        name: 'De Wolden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1690'
    },
    {
        id: 'Municipality_503',
        name: 'Delft',
        type: RoadOperatorType.MUNICIPALITY,
        code: '503'
    },
    {
        id: 'Municipality_400',
        name: 'Den Helder',
        type: RoadOperatorType.MUNICIPALITY,
        code: '400'
    },
    {
        id: 'Municipality_762',
        name: 'Deurne',
        type: RoadOperatorType.MUNICIPALITY,
        code: '762'
    },
    {
        id: 'Municipality_150',
        name: 'Deventer',
        type: RoadOperatorType.MUNICIPALITY,
        code: '150'
    },
    {
        id: 'Municipality_384',
        name: 'Diemen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '384'
    },
    {
        id: 'Municipality_1980',
        name: 'Dijk en Waard',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1980'
    },
    {
        id: 'Municipality_1774',
        name: 'Dinkelland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1774'
    },
    {
        id: 'Municipality_221',
        name: 'Doesburg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '221'
    },
    {
        id: 'Municipality_222',
        name: 'Doetinchem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '222'
    },
    {
        id: 'Municipality_766',
        name: 'Dongen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '766'
    },
    {
        id: 'Municipality_58',
        name: 'Dongeradeel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '58'
    },
    {
        id: 'Municipality_505',
        name: 'Dordrecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '505'
    },
    {
        id: 'Municipality_498',
        name: 'Drechterland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '498'
    },
    {
        id: 'Province_DR',
        name: 'Drenthe',
        type: RoadOperatorType.PROVINCE,
        code: 'DR'
    },
    {
        id: 'Municipality_1719',
        name: 'Drimmelen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1719'
    },
    {
        id: 'Municipality_303',
        name: 'Dronten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '303'
    },
    {
        id: 'Municipality_225',
        name: 'Druten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '225'
    },
    {
        id: 'Municipality_226',
        name: 'Duiven',
        type: RoadOperatorType.MUNICIPALITY,
        code: '226'
    },
    {
        id: 'Municipality_1711',
        name: 'Echt-Susteren',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1711'
    },
    {
        id: 'Municipality_385',
        name: 'Edam-Volendam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '385'
    },
    {
        id: 'Municipality_228',
        name: 'Ede',
        type: RoadOperatorType.MUNICIPALITY,
        code: '228'
    },
    {
        id: 'Municipality_317',
        name: 'Eemnes',
        type: RoadOperatorType.MUNICIPALITY,
        code: '317'
    },
    {
        id: 'Municipality_1979',
        name: 'Eemsdelta',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1979'
    },
    {
        id: 'Municipality_1651',
        name: 'Eemsmond',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1651'
    },
    {
        id: 'Municipality_770',
        name: 'Eersel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '770'
    },
    {
        id: 'Municipality_1903',
        name: 'Eijsden-Margraten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1903'
    },
    {
        id: 'Municipality_772',
        name: 'Eindhoven',
        type: RoadOperatorType.MUNICIPALITY,
        code: '772'
    },
    {
        id: 'Municipality_230',
        name: 'Elburg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '230'
    },
    {
        id: 'Municipality_114',
        name: 'Emmen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '114'
    },
    {
        id: 'Municipality_388',
        name: 'Enkhuizen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '388'
    },
    {
        id: 'Municipality_153',
        name: 'Enschede',
        type: RoadOperatorType.MUNICIPALITY,
        code: '153'
    },
    {
        id: 'Municipality_232',
        name: 'Epe',
        type: RoadOperatorType.MUNICIPALITY,
        code: '232'
    },
    {
        id: 'Municipality_233',
        name: 'Ermelo',
        type: RoadOperatorType.MUNICIPALITY,
        code: '233'
    },
    {
        id: 'Municipality_777',
        name: 'Etten-Leur',
        type: RoadOperatorType.MUNICIPALITY,
        code: '777'
    },
    {
        id: 'Municipality_1722',
        name: 'Ferwerderadiel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1722'
    },
    {
        id: 'Province_FL',
        name: 'Flevoland',
        type: RoadOperatorType.PROVINCE,
        code: 'FL'
    },
    {
        id: 'Province_FR',
        name: 'Friesland',
        type: RoadOperatorType.PROVINCE,
        code: 'FR'
    },
    {
        id: 'Municipality_779',
        name: 'Geertruidenberg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '779'
    },
    {
        id: 'Province_GL',
        name: 'Gelderland',
        type: RoadOperatorType.PROVINCE,
        code: 'GL'
    },
    {
        id: 'Municipality_236',
        name: 'Geldermalsen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '236'
    },
    {
        id: 'Municipality_1771',
        name: 'Geldrop-Mierlo',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1771'
    },
    {
        id: 'Municipality_1652',
        name: 'Gemert-Bakel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1652'
    },
    {
        id: 'Municipality_907',
        name: 'Gennep',
        type: RoadOperatorType.MUNICIPALITY,
        code: '907'
    },
    {
        id: 'Municipality_784',
        name: 'Gilze en Rijen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '784'
    },
    {
        id: 'Municipality_1924',
        name: 'Goeree-Overflakkee',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1924'
    },
    {
        id: 'Municipality_664',
        name: 'Goes',
        type: RoadOperatorType.MUNICIPALITY,
        code: '664'
    },
    {
        id: 'Municipality_785',
        name: 'Goirle',
        type: RoadOperatorType.MUNICIPALITY,
        code: '785'
    },
    {
        id: 'Municipality_1942',
        name: 'Gooise Meren',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1942'
    },
    {
        id: 'Municipality_512',
        name: 'Gorinchem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '512'
    },
    {
        id: 'Municipality_513',
        name: 'Gouda',
        type: RoadOperatorType.MUNICIPALITY,
        code: '513'
    },
    {
        id: 'Municipality_786',
        name: 'Grave',
        type: RoadOperatorType.MUNICIPALITY,
        code: '786'
    },
    {
        id: 'Municipality_14',
        name: 'Groningen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '14'
    },
    {
        id: 'Province_GR',
        name: 'Groningen',
        type: RoadOperatorType.PROVINCE,
        code: 'GR'
    },
    {
        id: 'Municipality_1729',
        name: 'Gulpen-Wittem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1729'
    },
    {
        id: 'Municipality_158',
        name: 'Haaksbergen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '158'
    },
    {
        id: 'Municipality_392',
        name: 'Haarlem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '392'
    },
    {
        id: 'Municipality_394',
        name: 'Haarlemmermeer',
        type: RoadOperatorType.MUNICIPALITY,
        code: '394'
    },
    {
        id: 'Municipality_1655',
        name: 'Halderberge',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1655'
    },
    {
        id: 'Municipality_160',
        name: 'Hardenberg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '160'
    },
    {
        id: 'Municipality_243',
        name: 'Harderwijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '243'
    },
    {
        id: 'Municipality_523',
        name: 'Hardinxveld-Giessendam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '523'
    },
    {
        id: 'Municipality_17',
        name: 'Haren',
        type: RoadOperatorType.MUNICIPALITY,
        code: '17'
    },
    {
        id: 'Municipality_72',
        name: 'Harlingen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '72'
    },
    {
        id: 'Municipality_244',
        name: 'Hattem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '244'
    },
    {
        id: 'Other_HR',
        name: 'Havenbedrijf Rotterdam',
        type: RoadOperatorType.OTHER,
        code: 'HR'
    },
    {
        id: 'Municipality_396',
        name: 'Heemskerk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '396'
    },
    {
        id: 'Municipality_397',
        name: 'Heemstede',
        type: RoadOperatorType.MUNICIPALITY,
        code: '397'
    },
    {
        id: 'Municipality_246',
        name: 'Heerde',
        type: RoadOperatorType.MUNICIPALITY,
        code: '246'
    },
    {
        id: 'Municipality_74',
        name: 'Heerenveen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '74'
    },
    {
        id: 'Municipality_917',
        name: 'Heerlen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '917'
    },
    {
        id: 'Municipality_793',
        name: 'Heeze',
        type: RoadOperatorType.MUNICIPALITY,
        code: '793'
    },
    {
        id: 'Municipality_1658',
        name: 'Heeze-Leende',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1658'
    },
    {
        id: 'Municipality_399',
        name: 'Heiloo',
        type: RoadOperatorType.MUNICIPALITY,
        code: '399'
    },
    {
        id: 'Municipality_163',
        name: 'Hellendoorn',
        type: RoadOperatorType.MUNICIPALITY,
        code: '163'
    },
    {
        id: 'Municipality_530',
        name: 'Hellevoetsluis',
        type: RoadOperatorType.MUNICIPALITY,
        code: '530'
    },
    {
        id: 'Municipality_794',
        name: 'Helmond',
        type: RoadOperatorType.MUNICIPALITY,
        code: '794'
    },
    {
        id: 'Municipality_531',
        name: 'Hendrik-Ido-Ambacht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '531'
    },
    {
        id: 'Municipality_164',
        name: 'Hengelo OV',
        type: RoadOperatorType.MUNICIPALITY,
        code: '164'
    },
    {
        id: 'Municipality_1966',
        name: 'Het Hogeland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1966'
    },
    {
        id: 'State_Rijk',
        name: 'Het Rijk',
        type: RoadOperatorType.STATE,
        code: 'Rijk'
    },
    {
        id: 'Municipality_252',
        name: 'Heumen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '252'
    },
    {
        id: 'Municipality_797',
        name: 'Heusden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '797'
    },
    {
        id: 'WaterAuthority_WS21',
        name: 'HHS Hollands Noorderkwartier',
        type: RoadOperatorType.WATER_AUTHORITY,
        code: 'WS21'
    },
    {
        id: 'WaterAuthority_WS28',
        name: 'HHS Schieland en de Krimpenerwaard',
        type: RoadOperatorType.WATER_AUTHORITY,
        code: 'WS28'
    },
    {
        id: 'Municipality_534',
        name: 'Hillegom',
        type: RoadOperatorType.MUNICIPALITY,
        code: '534'
    },
    {
        id: 'Municipality_798',
        name: 'Hilvarenbeek',
        type: RoadOperatorType.MUNICIPALITY,
        code: '798'
    },
    {
        id: 'Municipality_402',
        name: 'Hilversum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '402'
    },
    {
        id: 'Municipality_1963',
        name: 'Hoeksche Waard',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1963'
    },
    {
        id: 'Municipality_1735',
        name: 'Hof van Twente',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1735'
    },
    {
        id: 'Municipality_1911',
        name: 'Hollands Kroon',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1911'
    },
    {
        id: 'Municipality_118',
        name: 'Hoogeveen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '118'
    },
    {
        id: 'Municipality_405',
        name: 'Hoorn',
        type: RoadOperatorType.MUNICIPALITY,
        code: '405'
    },
    {
        id: 'Municipality_1507',
        name: 'Horst aan de Maas',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1507'
    },
    {
        id: 'Municipality_321',
        name: 'Houten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '321'
    },
    {
        id: 'Municipality_406',
        name: 'Huizen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '406'
    },
    {
        id: 'Municipality_677',
        name: 'Hulst',
        type: RoadOperatorType.MUNICIPALITY,
        code: '677'
    },
    {
        id: 'Municipality_353',
        name: 'IJsselstein',
        type: RoadOperatorType.MUNICIPALITY,
        code: '353'
    },
    {
        id: 'Municipality_1884',
        name: 'Kaag en Braassem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1884'
    },
    {
        id: 'Municipality_166',
        name: 'Kampen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '166'
    },
    {
        id: 'Municipality_678',
        name: 'Kapelle',
        type: RoadOperatorType.MUNICIPALITY,
        code: '678'
    },
    {
        id: 'Municipality_537',
        name: 'Katwijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '537'
    },
    {
        id: 'Municipality_928',
        name: 'Kerkrade',
        type: RoadOperatorType.MUNICIPALITY,
        code: '928'
    },
    {
        id: 'Municipality_1598',
        name: 'Koggenland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1598'
    },
    {
        id: 'Municipality_79',
        name: 'Kollumerland ca',
        type: RoadOperatorType.MUNICIPALITY,
        code: '79'
    },
    {
        id: 'Municipality_542',
        name: 'Krimpen aan den IJssel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '542'
    },
    {
        id: 'Municipality_1931',
        name: 'Krimpenerwaard',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1931'
    },
    {
        id: 'Municipality_1659',
        name: 'Laarbeek',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1659'
    },
    {
        id: 'Municipality_1982',
        name: 'Land van Cuijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1982'
    },
    {
        id: 'Municipality_1685',
        name: 'Landerd',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1685'
    },
    {
        id: 'Municipality_882',
        name: 'Landgraaf',
        type: RoadOperatorType.MUNICIPALITY,
        code: '882'
    },
    {
        id: 'Municipality_415',
        name: 'Landsmeer',
        type: RoadOperatorType.MUNICIPALITY,
        code: '415'
    },
    {
        id: 'Municipality_325',
        name: 'Langbroek',
        type: RoadOperatorType.MUNICIPALITY,
        code: '325'
    },
    {
        id: 'Municipality_416',
        name: 'Langedijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '416'
    },
    {
        id: 'Municipality_1621',
        name: 'Lansingerland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1621'
    },
    {
        id: 'Municipality_417',
        name: 'Laren',
        type: RoadOperatorType.MUNICIPALITY,
        code: '417'
    },
    {
        id: 'Municipality_545',
        name: 'Leerdam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '545'
    },
    {
        id: 'Municipality_80',
        name: 'Leeuwarden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '80'
    },
    {
        id: 'Municipality_546',
        name: 'Leiden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '546'
    },
    {
        id: 'Municipality_547',
        name: 'Leiderdorp',
        type: RoadOperatorType.MUNICIPALITY,
        code: '547'
    },
    {
        id: 'Municipality_1916',
        name: 'Leidschendam-Voorburg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1916'
    },
    {
        id: 'Municipality_995',
        name: 'Lelystad',
        type: RoadOperatorType.MUNICIPALITY,
        code: '995'
    },
    {
        id: 'Municipality_1640',
        name: 'Leudal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1640'
    },
    {
        id: 'Municipality_327',
        name: 'Leusden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '327'
    },
    {
        id: 'Province_LB',
        name: 'Limburg',
        type: RoadOperatorType.PROVINCE,
        code: 'LB'
    },
    {
        id: 'Municipality_1705',
        name: 'Lingewaard',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1705'
    },
    {
        id: 'Municipality_553',
        name: 'Lisse',
        type: RoadOperatorType.MUNICIPALITY,
        code: '553'
    },
    {
        id: 'Municipality_262',
        name: 'Lochem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '262'
    },
    {
        id: 'Municipality_809',
        name: 'Loon op Zand',
        type: RoadOperatorType.MUNICIPALITY,
        code: '809'
    },
    {
        id: 'Municipality_331',
        name: 'Lopik',
        type: RoadOperatorType.MUNICIPALITY,
        code: '331'
    },
    {
        id: 'Municipality_24',
        name: 'Loppersum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '24'
    },
    {
        id: 'Municipality_168',
        name: 'Losser',
        type: RoadOperatorType.MUNICIPALITY,
        code: '168'
    },
    {
        id: 'Municipality_263',
        name: 'Maasdriel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '263'
    },
    {
        id: 'Municipality_1641',
        name: 'Maasgouw',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1641'
    },
    {
        id: 'Municipality_1991',
        name: 'Maashorst',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1991'
    },
    {
        id: 'Municipality_556',
        name: 'Maassluis',
        type: RoadOperatorType.MUNICIPALITY,
        code: '556'
    },
    {
        id: 'Municipality_935',
        name: 'Maastricht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '935'
    },
    {
        id: 'Municipality_25',
        name: 'Marum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '25'
    },
    {
        id: 'Municipality_420',
        name: 'Medemblik',
        type: RoadOperatorType.MUNICIPALITY,
        code: '420'
    },
    {
        id: 'Other_MI',
        name: 'Meer instanties',
        type: RoadOperatorType.OTHER,
        code: 'MI'
    },
    {
        id: 'Municipality_938',
        name: 'Meerssen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '938'
    },
    {
        id: 'Municipality_1948',
        name: 'Meierijstad',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1948'
    },
    {
        id: 'Municipality_119',
        name: 'Meppel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '119'
    },
    {
        id: 'Municipality_687',
        name: 'Middelburg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '687'
    },
    {
        id: 'Municipality_1842',
        name: 'Midden-Delfland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1842'
    },
    {
        id: 'Municipality_1731',
        name: 'Midden-Drenthe',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1731'
    },
    {
        id: 'Municipality_1952',
        name: 'Midden-Groningen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1952'
    },
    {
        id: 'State_136',
        name: 'MN District Noord',
        type: RoadOperatorType.STATE,
        code: '136'
    },
    {
        id: 'State_137',
        name: 'MN District Zuid',
        type: RoadOperatorType.STATE,
        code: '137'
    },
    {
        id: 'Municipality_1709',
        name: 'Moerdijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1709'
    },
    {
        id: 'Municipality_1978',
        name: 'Molenlanden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1978'
    },
    {
        id: 'Municipality_1955',
        name: 'Montferland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1955'
    },
    {
        id: 'Municipality_335',
        name: 'Montfoort',
        type: RoadOperatorType.MUNICIPALITY,
        code: '335'
    },
    {
        id: 'Municipality_944',
        name: 'Mook en Middelaar',
        type: RoadOperatorType.MUNICIPALITY,
        code: '944'
    },
    {
        id: 'Municipality_1740',
        name: 'Neder-Betuwe',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1740'
    },
    {
        id: 'Municipality_946',
        name: 'Nederweert',
        type: RoadOperatorType.MUNICIPALITY,
        code: '946'
    },
    {
        id: 'Municipality_356',
        name: 'Nieuwegein',
        type: RoadOperatorType.MUNICIPALITY,
        code: '356'
    },
    {
        id: 'Municipality_569',
        name: 'Nieuwkoop',
        type: RoadOperatorType.MUNICIPALITY,
        code: '569'
    },
    {
        id: 'Municipality_267',
        name: 'Nijkerk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '267'
    },
    {
        id: 'Municipality_268',
        name: 'Nijmegen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '268'
    },
    {
        id: 'Municipality_1930',
        name: 'Nissewaard',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1930'
    },
    {
        id: 'State_100',
        name: 'NN District Oost',
        type: RoadOperatorType.STATE,
        code: '100'
    },
    {
        id: 'State_199',
        name: 'NN District West',
        type: RoadOperatorType.STATE,
        code: '199'
    },
    {
        id: 'Municipality_1970',
        name: 'Noardeast-Fryslan',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1970'
    },
    {
        id: 'Municipality_1695',
        name: 'Noord-Beveland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1695'
    },
    {
        id: 'Province_NB',
        name: 'Noord-Brabant',
        type: RoadOperatorType.PROVINCE,
        code: 'NB'
    },
    {
        id: 'Province_NH',
        name: 'Noord-Holland',
        type: RoadOperatorType.PROVINCE,
        code: 'NH'
    },
    {
        id: 'State_712',
        name: 'Noord-Nederland District Oost',
        type: RoadOperatorType.STATE,
        code: '712'
    },
    {
        id: 'Municipality_1699',
        name: 'Noordenveld',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1699'
    },
    {
        id: 'Municipality_171',
        name: 'Noordoostpolder',
        type: RoadOperatorType.MUNICIPALITY,
        code: '171'
    },
    {
        id: 'Municipality_575',
        name: 'Noordwijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '575'
    },
    {
        id: 'Municipality_576',
        name: 'Noordwijkerhout',
        type: RoadOperatorType.MUNICIPALITY,
        code: '576'
    },
    {
        id: 'Other_NSP',
        name: 'North Sea Port',
        type: RoadOperatorType.OTHER,
        code: 'NSP'
    },
    {
        id: 'Municipality_820',
        name: 'Nuenen, Gerwen en Nederw',
        type: RoadOperatorType.MUNICIPALITY,
        code: '820'
    },
    {
        id: 'Municipality_302',
        name: 'Nunspeet',
        type: RoadOperatorType.MUNICIPALITY,
        code: '302'
    },
    {
        id: 'Municipality_951',
        name: 'Nuth',
        type: RoadOperatorType.MUNICIPALITY,
        code: '951'
    },
    {
        id: 'Other_WST',
        name: 'NV Westerscheldetunnel',
        type: RoadOperatorType.OTHER,
        code: 'WST'
    },
    {
        id: 'Municipality_579',
        name: 'Oegstgeest',
        type: RoadOperatorType.MUNICIPALITY,
        code: '579'
    },
    {
        id: 'Municipality_823',
        name: 'Oirschot',
        type: RoadOperatorType.MUNICIPALITY,
        code: '823'
    },
    {
        id: 'Municipality_824',
        name: 'Oisterwijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '824'
    },
    {
        id: 'Municipality_1895',
        name: 'Oldambt',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1895'
    },
    {
        id: 'Municipality_269',
        name: 'Oldebroek',
        type: RoadOperatorType.MUNICIPALITY,
        code: '269'
    },
    {
        id: 'Municipality_173',
        name: 'Oldenzaal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '173'
    },
    {
        id: 'Municipality_1773',
        name: 'Olst-Wijhe',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1773'
    },
    {
        id: 'Municipality_175',
        name: 'Ommen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '175'
    },
    {
        id: 'State_160',
        name: 'ON District Noord',
        type: RoadOperatorType.STATE,
        code: '160'
    },
    {
        id: 'State_159',
        name: 'ON District Oost',
        type: RoadOperatorType.STATE,
        code: '159'
    },
    {
        id: 'State_161',
        name: 'ON District Zuid',
        type: RoadOperatorType.STATE,
        code: '161'
    },
    {
        id: 'Municipality_1586',
        name: 'Oost Gelre',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1586'
    },
    {
        id: 'Municipality_826',
        name: 'Oosterhout',
        type: RoadOperatorType.MUNICIPALITY,
        code: '826'
    },
    {
        id: 'Municipality_85',
        name: 'Ooststellingwerf',
        type: RoadOperatorType.MUNICIPALITY,
        code: '85'
    },
    {
        id: 'Municipality_431',
        name: 'Oostzaan',
        type: RoadOperatorType.MUNICIPALITY,
        code: '431'
    },
    {
        id: 'Municipality_432',
        name: 'Opmeer',
        type: RoadOperatorType.MUNICIPALITY,
        code: '432'
    },
    {
        id: 'Municipality_86',
        name: 'Opsterland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '86'
    },
    {
        id: 'Municipality_828',
        name: 'Oss',
        type: RoadOperatorType.MUNICIPALITY,
        code: '828'
    },
    {
        id: 'Municipality_829',
        name: 'Ossendrecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '829'
    },
    {
        id: 'Municipality_584',
        name: 'Oud-Beijerland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '584'
    },
    {
        id: 'Municipality_1509',
        name: 'Oude IJsselstreek',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1509'
    },
    {
        id: 'Municipality_437',
        name: 'Ouder-Amstel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '437'
    },
    {
        id: 'Municipality_589',
        name: 'Oudewater',
        type: RoadOperatorType.MUNICIPALITY,
        code: '589'
    },
    {
        id: 'Municipality_1734',
        name: 'Overbetuwe',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1734'
    },
    {
        id: 'Other_OI',
        name: 'Overige instanties',
        type: RoadOperatorType.OTHER,
        code: 'OI'
    },
    {
        id: 'Province_OV',
        name: 'Overijssel',
        type: RoadOperatorType.PROVINCE,
        code: 'OV'
    },
    {
        id: 'Municipality_590',
        name: 'Papendrecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '590'
    },
    {
        id: 'Municipality_1894',
        name: 'Peel en Maas',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1894'
    },
    {
        id: 'Municipality_765',
        name: 'Pekela',
        type: RoadOperatorType.MUNICIPALITY,
        code: '765'
    },
    {
        id: 'Municipality_1926',
        name: 'Pijnacker-Nootdorp',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1926'
    },
    {
        id: 'Municipality_439',
        name: 'Purmerend',
        type: RoadOperatorType.MUNICIPALITY,
        code: '439'
    },
    {
        id: 'Municipality_273',
        name: 'Putten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '273'
    },
    {
        id: 'Other_PWN',
        name: 'PWN Waterleidingbedr Noord-Holland',
        type: RoadOperatorType.OTHER,
        code: 'PWN'
    },
    {
        id: 'Municipality_177',
        name: 'Raalte',
        type: RoadOperatorType.MUNICIPALITY,
        code: '177'
    },
    {
        id: 'Municipality_703',
        name: 'Reimerswaal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '703'
    },
    {
        id: 'Municipality_274',
        name: 'Renkum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '274'
    },
    {
        id: 'Municipality_339',
        name: 'Renswoude',
        type: RoadOperatorType.MUNICIPALITY,
        code: '339'
    },
    {
        id: 'Municipality_1667',
        name: 'Reusel-De Mierden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1667'
    },
    {
        id: 'Municipality_275',
        name: 'Rheden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '275'
    },
    {
        id: 'Municipality_340',
        name: 'Rhenen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '340'
    },
    {
        id: 'Municipality_597',
        name: 'Ridderkerk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '597'
    },
    {
        id: 'Municipality_1742',
        name: 'Rijssen-Holten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1742'
    },
    {
        id: 'Municipality_603',
        name: 'Rijswijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '603'
    },
    {
        id: 'Municipality_1669',
        name: 'Roerdalen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1669'
    },
    {
        id: 'Municipality_957',
        name: 'Roermond',
        type: RoadOperatorType.MUNICIPALITY,
        code: '957'
    },
    {
        id: 'Municipality_1674',
        name: 'Roosendaal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1674'
    },
    {
        id: 'Municipality_599',
        name: 'Rotterdam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '599'
    },
    {
        id: 'Municipality_277',
        name: 'Rozendaal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '277'
    },
    {
        id: 'Municipality_840',
        name: 'Rucphen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '840'
    },
    {
        id: 'Municipality_518',
        name: '\'s-Gravenhage',
        type: RoadOperatorType.MUNICIPALITY,
        code: '518'
    },
    {
        id: 'Municipality_796',
        name: '\'s-Hertogenbosch',
        type: RoadOperatorType.MUNICIPALITY,
        code: '796'
    },
    {
        id: 'Municipality_441',
        name: 'Schagen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '441'
    },
    {
        id: 'Municipality_279',
        name: 'Scherpenzeel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '279'
    },
    {
        id: 'Municipality_606',
        name: 'Schiedam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '606'
    },
    {
        id: 'Municipality_88',
        name: 'Schiermonnikoog',
        type: RoadOperatorType.MUNICIPALITY,
        code: '88'
    },
    {
        id: 'Municipality_962',
        name: 'Schinnen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '962'
    },
    {
        id: 'Municipality_1676',
        name: 'Schouwen-Duiveland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1676'
    },
    {
        id: 'Municipality_965',
        name: 'Simpelveld',
        type: RoadOperatorType.MUNICIPALITY,
        code: '965'
    },
    {
        id: 'Municipality_1702',
        name: 'Sint Anthonis',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1702'
    },
    {
        id: 'Municipality_845',
        name: 'Sint-Michielsgestel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '845'
    },
    {
        id: 'Municipality_1883',
        name: 'Sittard-Geleen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1883'
    },
    {
        id: 'Municipality_610',
        name: 'Sliedrecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '610'
    },
    {
        id: 'Municipality_1714',
        name: 'Sluis Z',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1714'
    },
    {
        id: 'Municipality_90',
        name: 'Smallingerland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '90'
    },
    {
        id: 'Municipality_342',
        name: 'Soest',
        type: RoadOperatorType.MUNICIPALITY,
        code: '342'
    },
    {
        id: 'Municipality_847',
        name: 'Someren',
        type: RoadOperatorType.MUNICIPALITY,
        code: '847'
    },
    {
        id: 'Municipality_848',
        name: 'Son en Breugel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '848'
    },
    {
        id: 'Other_NS',
        name: 'Spoorwegen',
        type: RoadOperatorType.OTHER,
        code: 'NS'
    },
    {
        id: 'Other_SBB',
        name: 'Staatsbosbeheer',
        type: RoadOperatorType.OTHER,
        code: 'SBB'
    },
    {
        id: 'Municipality_37',
        name: 'Stadskanaal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '37'
    },
    {
        id: 'Municipality_180',
        name: 'Staphorst',
        type: RoadOperatorType.MUNICIPALITY,
        code: '180'
    },
    {
        id: 'Municipality_532',
        name: 'Stede Broec',
        type: RoadOperatorType.MUNICIPALITY,
        code: '532'
    },
    {
        id: 'Municipality_851',
        name: 'Steenbergen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '851'
    },
    {
        id: 'Municipality_1708',
        name: 'Steenwijkerland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1708'
    },
    {
        id: 'Municipality_971',
        name: 'Stein',
        type: RoadOperatorType.MUNICIPALITY,
        code: '971'
    },
    {
        id: 'Municipality_1904',
        name: 'Stichtse Vecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1904'
    },
    {
        id: 'Municipality_617',
        name: 'Strijen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '617'
    },
    {
        id: 'Municipality_1900',
        name: 'Sudwest-Fryslan',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1900'
    },
    {
        id: 'Municipality_9',
        name: 'Ten Boer',
        type: RoadOperatorType.MUNICIPALITY,
        code: '9'
    },
    {
        id: 'Municipality_715',
        name: 'Terneuzen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '715'
    },
    {
        id: 'Municipality_93',
        name: 'Terschelling',
        type: RoadOperatorType.MUNICIPALITY,
        code: '93'
    },
    {
        id: 'Municipality_448',
        name: 'Texel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '448'
    },
    {
        id: 'Municipality_1525',
        name: 'Teylingen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1525'
    },
    {
        id: 'Municipality_716',
        name: 'Tholen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '716'
    },
    {
        id: 'Municipality_281',
        name: 'Tiel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '281'
    },
    {
        id: 'Municipality_855',
        name: 'Tilburg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '855'
    },
    {
        id: 'Municipality_183',
        name: 'Tubbergen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '183'
    },
    {
        id: 'Municipality_1700',
        name: 'Twenterand',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1700'
    },
    {
        id: 'Municipality_1730',
        name: 'Tynaarlo',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1730'
    },
    {
        id: 'Municipality_737',
        name: 'Tytsjerksteradiel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '737'
    },
    {
        id: 'Municipality_857',
        name: 'Udenhout',
        type: RoadOperatorType.MUNICIPALITY,
        code: '857'
    },
    {
        id: 'Municipality_450',
        name: 'Uitgeest',
        type: RoadOperatorType.MUNICIPALITY,
        code: '450'
    },
    {
        id: 'Municipality_451',
        name: 'Uithoorn',
        type: RoadOperatorType.MUNICIPALITY,
        code: '451'
    },
    {
        id: 'Municipality_184',
        name: 'Urk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '184'
    },
    {
        id: 'Municipality_344',
        name: 'Utrecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '344'
    },
    {
        id: 'Province_UT',
        name: 'Utrecht',
        type: RoadOperatorType.PROVINCE,
        code: 'UT'
    },
    {
        id: 'Municipality_1581',
        name: 'Utrechtse Heuvelrug',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1581'
    },
    {
        id: 'Municipality_981',
        name: 'Vaals',
        type: RoadOperatorType.MUNICIPALITY,
        code: '981'
    },
    {
        id: 'Municipality_994',
        name: 'Valkenburg aan de Geul',
        type: RoadOperatorType.MUNICIPALITY,
        code: '994'
    },
    {
        id: 'Municipality_858',
        name: 'Valkenswaard',
        type: RoadOperatorType.MUNICIPALITY,
        code: '858'
    },
    {
        id: 'Municipality_47',
        name: 'Veendam',
        type: RoadOperatorType.MUNICIPALITY,
        code: '47'
    },
    {
        id: 'Municipality_345',
        name: 'Veenendaal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '345'
    },
    {
        id: 'Municipality_717',
        name: 'Veere',
        type: RoadOperatorType.MUNICIPALITY,
        code: '717'
    },
    {
        id: 'Municipality_861',
        name: 'Veldhoven',
        type: RoadOperatorType.MUNICIPALITY,
        code: '861'
    },
    {
        id: 'Municipality_453',
        name: 'Velsen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '453'
    },
    {
        id: 'Municipality_983',
        name: 'Venlo',
        type: RoadOperatorType.MUNICIPALITY,
        code: '983'
    },
    {
        id: 'Municipality_984',
        name: 'Venray',
        type: RoadOperatorType.MUNICIPALITY,
        code: '984'
    },
    {
        id: 'Municipality_620',
        name: 'Vianen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '620'
    },
    {
        id: 'Municipality_1961',
        name: 'Vijfheerenlanden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1961'
    },
    {
        id: 'Municipality_346',
        name: 'Vinkeveen ca',
        type: RoadOperatorType.MUNICIPALITY,
        code: '346'
    },
    {
        id: 'Municipality_622',
        name: 'Vlaardingen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '622'
    },
    {
        id: 'Municipality_96',
        name: 'Vlieland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '96'
    },
    {
        id: 'Municipality_718',
        name: 'Vlissingen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '718'
    },
    {
        id: 'Municipality_986',
        name: 'Voerendaal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '986'
    },
    {
        id: 'Municipality_1992',
        name: 'Voorne aan Zee',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1992'
    },
    {
        id: 'Municipality_626',
        name: 'Voorschoten',
        type: RoadOperatorType.MUNICIPALITY,
        code: '626'
    },
    {
        id: 'Municipality_285',
        name: 'Voorst',
        type: RoadOperatorType.MUNICIPALITY,
        code: '285'
    },
    {
        id: 'Municipality_865',
        name: 'Vught',
        type: RoadOperatorType.MUNICIPALITY,
        code: '865'
    },
    {
        id: 'Municipality_1949',
        name: 'Waadhoeke',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1949'
    },
    {
        id: 'Municipality_866',
        name: 'Waalre',
        type: RoadOperatorType.MUNICIPALITY,
        code: '866'
    },
    {
        id: 'Municipality_867',
        name: 'Waalwijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '867'
    },
    {
        id: 'Municipality_627',
        name: 'Waddinxveen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '627'
    },
    {
        id: 'Municipality_289',
        name: 'Wageningen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '289'
    },
    {
        id: 'Municipality_629',
        name: 'Wassenaar',
        type: RoadOperatorType.MUNICIPALITY,
        code: '629'
    },
    {
        id: 'Municipality_852',
        name: 'Waterland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '852'
    },
    {
        id: 'WaterAuthority_WS14',
        name: 'Waterschap De Hollandse Delta',
        type: RoadOperatorType.WATER_AUTHORITY,
        code: 'WS14'
    },
    {
        id: 'WaterAuthority_WS17',
        name: 'Waterschap Noorderzijlvest',
        type: RoadOperatorType.WATER_AUTHORITY,
        code: 'WS17'
    },
    {
        id: 'WaterAuthority_WS24',
        name: 'Waterschap Rivierenland',
        type: RoadOperatorType.WATER_AUTHORITY,
        code: 'WS24'
    },
    {
        id: 'WaterAuthority_WS15',
        name: 'Waterschap Scheldestromen',
        type: RoadOperatorType.WATER_AUTHORITY,
        code: 'WS15'
    },
    {
        id: 'WaterAuthority_WS26',
        name: 'Waterschap Vallei en Veluwe',
        type: RoadOperatorType.WATER_AUTHORITY,
        code: 'WS26'
    },
    {
        id: 'WaterAuthority_WS03',
        name: 'Waterschap West Friesland',
        type: RoadOperatorType.WATER_AUTHORITY,
        code: 'WS03'
    },
    {
        id: 'Municipality_988',
        name: 'Weert',
        type: RoadOperatorType.MUNICIPALITY,
        code: '988'
    },
    {
        id: 'Municipality_457',
        name: 'Weesp',
        type: RoadOperatorType.MUNICIPALITY,
        code: '457'
    },
    {
        id: 'Municipality_1960',
        name: 'West Betuwe',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1960'
    },
    {
        id: 'Municipality_668',
        name: 'West Maas en Waal',
        type: RoadOperatorType.MUNICIPALITY,
        code: '668'
    },
    {
        id: 'Municipality_558',
        name: 'Wester-Koggenland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '558'
    },
    {
        id: 'Municipality_1969',
        name: 'Westerkwartier',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1969'
    },
    {
        id: 'Municipality_1701',
        name: 'Westerveld',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1701'
    },
    {
        id: 'Municipality_293',
        name: 'Westervoort',
        type: RoadOperatorType.MUNICIPALITY,
        code: '293'
    },
    {
        id: 'Municipality_1950',
        name: 'Westerwolde',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1950'
    },
    {
        id: 'Municipality_1783',
        name: 'Westland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1783'
    },
    {
        id: 'Municipality_98',
        name: 'Weststellingwerf',
        type: RoadOperatorType.MUNICIPALITY,
        code: '98'
    },
    {
        id: 'Municipality_189',
        name: 'Wierden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '189'
    },
    {
        id: 'Municipality_296',
        name: 'Wijchen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '296'
    },
    {
        id: 'Municipality_1696',
        name: 'Wijdemeren',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1696'
    },
    {
        id: 'Municipality_352',
        name: 'Wijk bij Duurstede',
        type: RoadOperatorType.MUNICIPALITY,
        code: '352'
    },
    {
        id: 'Municipality_53',
        name: 'Winsum',
        type: RoadOperatorType.MUNICIPALITY,
        code: '53'
    },
    {
        id: 'Municipality_294',
        name: 'Winterswijk',
        type: RoadOperatorType.MUNICIPALITY,
        code: '294'
    },
    {
        id: 'State_187',
        name: 'WNN District Noord',
        type: RoadOperatorType.STATE,
        code: '187'
    },
    {
        id: 'State_186',
        name: 'WNN District Zuid',
        type: RoadOperatorType.STATE,
        code: '186'
    },
    {
        id: 'State_112',
        name: 'WNZ District Noord',
        type: RoadOperatorType.STATE,
        code: '112'
    },
    {
        id: 'State_113',
        name: 'WNZ District Zuid',
        type: RoadOperatorType.STATE,
        code: '113'
    },
    {
        id: 'Municipality_873',
        name: 'Woensdrecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '873'
    },
    {
        id: 'Municipality_632',
        name: 'Woerden',
        type: RoadOperatorType.MUNICIPALITY,
        code: '632'
    },
    {
        id: 'Municipality_880',
        name: 'Wormerland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '880'
    },
    {
        id: 'Municipality_351',
        name: 'Woudenberg',
        type: RoadOperatorType.MUNICIPALITY,
        code: '351'
    },
    {
        id: 'Municipality_874',
        name: 'Woudrichem',
        type: RoadOperatorType.MUNICIPALITY,
        code: '874'
    },
    {
        id: 'Municipality_479',
        name: 'Zaanstad',
        type: RoadOperatorType.MUNICIPALITY,
        code: '479'
    },
    {
        id: 'Municipality_297',
        name: 'Zaltbommel',
        type: RoadOperatorType.MUNICIPALITY,
        code: '297'
    },
    {
        id: 'Municipality_473',
        name: 'Zandvoort',
        type: RoadOperatorType.MUNICIPALITY,
        code: '473'
    },
    {
        id: 'State_143',
        name: 'ZD District Noord',
        type: RoadOperatorType.STATE,
        code: '143'
    },
    {
        id: 'State_144',
        name: 'ZD District Zuid',
        type: RoadOperatorType.STATE,
        code: '144'
    },
    {
        id: 'Municipality_707',
        name: 'Zederik',
        type: RoadOperatorType.MUNICIPALITY,
        code: '707'
    },
    {
        id: 'State_508',
        name: 'Zee en Delta District Zuid',
        type: RoadOperatorType.STATE,
        code: '508'
    },
    {
        id: 'Province_ZL',
        name: 'Zeeland',
        type: RoadOperatorType.PROVINCE,
        code: 'ZL'
    },
    {
        id: 'Municipality_50',
        name: 'Zeewolde',
        type: RoadOperatorType.MUNICIPALITY,
        code: '50'
    },
    {
        id: 'Municipality_355',
        name: 'Zeist',
        type: RoadOperatorType.MUNICIPALITY,
        code: '355'
    },
    {
        id: 'Municipality_299',
        name: 'Zevenaar',
        type: RoadOperatorType.MUNICIPALITY,
        code: '299'
    },
    {
        id: 'State_194',
        name: 'ZN District Midden',
        type: RoadOperatorType.STATE,
        code: '194'
    },
    {
        id: 'State_193',
        name: 'ZN District West',
        type: RoadOperatorType.STATE,
        code: '193'
    },
    {
        id: 'State_195',
        name: 'ZN District Zuid-Oost',
        type: RoadOperatorType.STATE,
        code: '195'
    },
    {
        id: 'Municipality_637',
        name: 'Zoetermeer',
        type: RoadOperatorType.MUNICIPALITY,
        code: '637'
    },
    {
        id: 'Municipality_638',
        name: 'Zoeterwoude',
        type: RoadOperatorType.MUNICIPALITY,
        code: '638'
    },
    {
        id: 'Province_ZH',
        name: 'Zuid-Holland',
        type: RoadOperatorType.PROVINCE,
        code: 'ZH'
    },
    {
        id: 'Municipality_56',
        name: 'Zuidhorn',
        type: RoadOperatorType.MUNICIPALITY,
        code: '56'
    },
    {
        id: 'Municipality_1892',
        name: 'Zuidplas',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1892'
    },
    {
        id: 'Municipality_879',
        name: 'Zundert',
        type: RoadOperatorType.MUNICIPALITY,
        code: '879'
    },
    {
        id: 'Municipality_301',
        name: 'Zutphen',
        type: RoadOperatorType.MUNICIPALITY,
        code: '301'
    },
    {
        id: 'Municipality_1896',
        name: 'Zwartewaterland',
        type: RoadOperatorType.MUNICIPALITY,
        code: '1896'
    },
    {
        id: 'Municipality_642',
        name: 'Zwijndrecht',
        type: RoadOperatorType.MUNICIPALITY,
        code: '642'
    },
    {
        id: 'Municipality_193',
        name: 'Zwolle',
        type: RoadOperatorType.MUNICIPALITY,
        code: '193'
    }
];
