import React, {ReactElement} from 'react';
import {Props, TabBarItem} from './Props';
import {css} from 'aphrodite';
import styles from './styles';

class TabBar<T> extends React.Component<Props<T>> {

    render(): ReactElement {
        const props: Readonly<Props<T>> = this.props;
        const {activeId, tabBarItems, onClickedOnTab} = props;

        return <React.Fragment>
            <ul className={css(styles.listItemContainer)}>
                {tabBarItems.map((item: TabBarItem<T>, index: number) => {
                    const active = activeId === item.id;

                    return <li
                        key={index}
                        className={css(styles.listItem, active && styles.listItemActive)}
                        onClick={() => onClickedOnTab(item.id)}
                    >
                        <span className={css(styles.listItemContent)}>{item.name}</span>
                    </li>;
                })}
            </ul>
        </React.Fragment>;
    }
}

export default TabBar;
