export enum FlowSpeedTab {
    flow = 'FLOW',
    speed = 'SPEED'
}

export interface FlowSpeedLaneItem {
    flow: number;
    speed: number;
}

export interface FlowSpeedLane {
    [key: string]: {
        [key: string]: FlowSpeedLaneItem
    };
}

export interface FlowSpeedLaneTableItem {
    name: string;
    laneData: {
        [key: string]: {
            color: string;
            value: number
        }
    };
}

export interface FlowSpeedLaneTableData {
    flow: FlowSpeedLaneTableItem[];
    speed: FlowSpeedLaneTableItem[];
    lanes: number;
}

export interface State {
    currentTab: FlowSpeedTab;
    graphColors: string[];
    laneTable: FlowSpeedLaneTableData;
    lanes: FlowSpeedLane;
    tabTimestamp: number;
}
