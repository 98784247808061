import {Reducer} from 'redux';
import {
    MapSceneFilterAndSearchReducerActionTypes,
    selectedNewTrafficCenter,
    setNewDfineFilterStatusOnlyRvmNetwork,
    setNewFdVerifiedTrafficJamsFilterStatus,
    setNewPrefixFilterForLayer,
    setNewWazeAlertFilterStatusSet,
    setNewWazeAlertNdwKnownFilterStatus,
    setSituationTypeFilter,
    storeNewSearchResult,
    storeNewSearchValue
} from '../actions/reducers/filterAndSearch';
import UrlService from '../../../services/UrlService';
import {SearchResults} from '../../../interfaces/SearchFeature';
import {getType} from 'typesafe-actions';
import {otisSituationTypeFilter} from '../../../constants';

export interface PrefixLayerFilter {
    mapLayerIDs: string[];
    prefixes: string[];
}

export interface PrefixLayerFilters {
    flowSpeed: PrefixLayerFilter;
    travelTimeFcd: PrefixLayerFilter;
}

export interface MapFilters {
    currentTrafficCenter: string | null;
    filterTimeStamp: number;
    prefixFilter: PrefixLayerFilters;
    wazeAlertNdwKnown: boolean;
    wazeAlertStatusSet: boolean;
    dFineOnlyRvmNetwork: boolean;
    fdVerifiedTrafficJams: boolean;
    situationTypeFilterOptions: SituationTypeFilter[];
    situationTypeFilters: SituationTypeFilter[];
}

export interface SituationTypeFilter {
    label: string;
    value: string
}

export interface MapSceneFilterAndSearchReducerState {
    currentSearchValue: string;
    mapFilters: MapFilters;
    searchResults: SearchResults | null;
}

const currentTrafficCenter = UrlService.getInstance().getStringValueFromUrl('trafficCenter', '');
const initialState: MapSceneFilterAndSearchReducerState = {
    currentSearchValue: '',
    mapFilters: {
        currentTrafficCenter: currentTrafficCenter.length > 0 ? currentTrafficCenter : null,
        dFineOnlyRvmNetwork: UrlService.getInstance().getBooleanValueFromUrl('mapFilterDfineRvmNetworkOnly', true),
        filterTimeStamp: Date.now(),
        prefixFilter: UrlService.getInstance().getObjectValueFromUrl('prefixFilters', {
            flowSpeed: {
                mapLayerIDs: ['flowSpeedArrow', 'flowSpeedCircle'],
                prefixes: []
            },
            travelTimeFcd: {
                mapLayerIDs: ['travelTimesFcdLabel', 'travelTimesFcdLine'],
                prefixes: []
            }
        }),
        wazeAlertNdwKnown: UrlService.getInstance().getBooleanValueFromUrl('mapFilterWazeAlertNdwKnown', false),
        wazeAlertStatusSet: UrlService.getInstance().getBooleanValueFromUrl('mapFilterWazeAlertStatusSet', false),
        fdVerifiedTrafficJams: UrlService.getInstance().getBooleanValueFromUrl('mapFilterFdVerifiedTrafficJams', true),
        situationTypeFilters: [],
        situationTypeFilterOptions: otisSituationTypeFilter
    },
    searchResults: null
};

const filterAndSearchReducer: Reducer<MapSceneFilterAndSearchReducerState, MapSceneFilterAndSearchReducerActionTypes> =
    (state: MapSceneFilterAndSearchReducerState = initialState, action: MapSceneFilterAndSearchReducerActionTypes): MapSceneFilterAndSearchReducerState => {
        switch (action.type) {
            case getType(selectedNewTrafficCenter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        currentTrafficCenter: action.payload.newTrafficCenter ? action.payload.newTrafficCenter.key : null,
                        filterTimeStamp: Date.now()
                    }
                };
            case getType(storeNewSearchValue):
                return {
                    ...state,
                    currentSearchValue: action.payload.searchValue
                };
            case getType(setNewDfineFilterStatusOnlyRvmNetwork):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        dFineOnlyRvmNetwork: action.payload.newStatus,
                        filterTimeStamp: Date.now()
                    }
                };
            case getType(setNewWazeAlertNdwKnownFilterStatus):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        wazeAlertNdwKnown: action.payload.newStatus
                    }
                };
            case getType(setNewWazeAlertFilterStatusSet):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        wazeAlertStatusSet: action.payload.newStatus
                    }
                };
            case getType(storeNewSearchResult):
                return {
                    ...state,
                    searchResults: action.payload.searchResults
                };
            case getType(setNewPrefixFilterForLayer):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        prefixFilter: {
                            ...state.mapFilters.prefixFilter,
                            [action.payload.layerId]: {
                                ...state.mapFilters.prefixFilter[action.payload.layerId],
                                prefixes: action.payload.prefixes
                            }
                        }
                    }
                };
            case getType(setNewFdVerifiedTrafficJamsFilterStatus):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        fdVerifiedTrafficJams: action.payload.newStatus
                    }
                };
            case getType(setSituationTypeFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        situationTypeFilters: action.payload.situationTypeFilters
                    }
                };
            default:
                return {
                    ...state
                };
        }
    };

export default filterAndSearchReducer;
